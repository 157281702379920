import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid';
import { ScatterPlot, QueryStats, Balance, Diversity2, AllInclusive, ThumbUp, Psychology } from '@mui/icons-material';
import { Link } from 'react-router-dom'
import { CTA } from './Homepage_v2';


function InformationPageTemplate({ Heading, Subheading, Lead, Content, QuoteText, QuoteAuthor, QuoteURL, QuoteReference, CTAHeading1,CTAHeading2,CTALead }) {
  return (
    <div className="relative isolate overflow-hidden bg-white py-24 sm:py-32">

      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className={QuoteText ? "mx-auto max-w-2xl lg:mx-0" : "mx-auto max-w-2xl" }>
          <p className="text-lg font-semibold leading-8 tracking-tight text-accent-600">{Subheading}</p>
          <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{Heading}</h1>
          <p className="mt-6 text-xl leading-8 text-gray-700">
            {Lead}
          </p>
        </div>
        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:mt-10 lg:max-w-none lg:grid-cols-12">
          {
            QuoteText
            ? <div className="relative lg:order-last lg:col-span-5">
            <figure className="border-l border-brand-600 pl-8">
              <blockquote className="text-xl font-semibold leading-8 tracking-tight text-gray-900">
                <p>
                  {QuoteText}
                </p>
              </blockquote>
              <figcaption className="mt-8 flex gap-x-4">
                <div className="text-sm leading-6">
                  <div className="font-semibold text-gray-900">{QuoteAuthor}</div>
                  <div className="text-gray-600"><Link to={QuoteURL}>{QuoteReference}</Link></div>
                </div>
              </figcaption>
            </figure>
          </div>
            : ""
          }

          <div className={QuoteText ? "lg:col-span-7 " : "lg:col-span-12 mx-auto "+"max-w-xl text-base leading-7 text-gray-700 lg:col-span-7"}>
            {Content.map((item, index) => {
              switch (item.type) {
                case 'paragraph':
                  return <p key={index} className={item.className}>{item.text}</p>;
                case 'list':
                  return (
                    <ul key={index} role={item.role} className={item.className}>
                      {item.items.map((listItem, listItemIndex) => {

                        return <li key={listItemIndex} className="flex gap-x-3">
                          {/* Replace Icon component with actual implementation */}
                          <listItem.icon className="mt-1 h-5 w-5 flex-none text-brand-600" aria-hidden="true" />
                          <span>
                            <strong className="font-semibold text-gray-900">{listItem.title}</strong> {listItem.description}
                          </span>
                        </li>
                      }

                      )}
                    </ul>
                  );
                case 'heading':
                  return <h2 key={index} className={item.className}>{item.text}</h2>;
                default:
                  return null;
              }
            })}
          </div>
        </div>
      </div>
      <CTA Heading1={CTAHeading1} Heading2={CTAHeading2} Lead={CTALead}/>
    </div>
  )
}

const general = {
  intro: {
    heading: `Closing Skill Gap with Data`,
    subheading: `About GoSkilling`,
    lead: `GoSkilling is a pioneering platform designed to transform the way organizations assess, evaluate, and identify talent. With a commitment to revolutionizing talent acquisition and development, GoSkilling leverages cutting-edge technology to provide businesses and jobseekers with powerful tools and insights.`
  },
  cta: {
    heading1:'Discover Your Skill Level Now',
    heading2:'Compare with Your Peers',
    lead:"The skill gap is widening, and the future holds new opportunities. Stay ahead by understanding your strengths and using them as your foundation! Discover and compare your skills today."
  },
  content: [
    {
      type: 'heading',
      level: 2,
      className: 'mt-16 text-2xl font-bold tracking-tight text-gray-900',
      text: "Our Mission"
    },
    {
      type: 'paragraph',
      className: 'mt-8',
      text: "At GoSkilling, our mission is clear: to empower employers and jobseekers with data-driven, objective assessments. We aim to bridge the gap between potential candidates and employers, making the hiring process more efficient, equitable, and accurate. Our platform is designed to provide employers with a deeper understanding of candidate capabilities and help jobseekers showcase their skills and qualifications effectively."
    },
    {
      type: 'heading',
      level: 2,
      className: 'mt-16 text-2xl font-bold tracking-tight text-gray-900',
      text: "Key Features"
    },
    {
      type: 'list',
      role: 'list',
      className: 'mt-8 max-w-xl space-y-8 text-gray-600',
      items: [
        {
          icon: Psychology,
          title: 'AI-Powered Skill Evaluations:',
          description: "GoSkilling utilizes artificial intelligence to offer precise and unbiased skill assessments, providing employers with a comprehensive view of candidates' capabilities."
        },
        {
          icon: AllInclusive,
          title: 'Peer Assessments',
          description: 'Our platform facilitates peer reviews, allowing organizations to gain insights into candidates from diverse perspectives, ensuring a well-rounded evaluation.'
        },
        {
          icon: Diversity2,
          title: 'Diversity, Equity, and Inclusion (DEI)',
          description: 'GoSkilling is committed to promoting DEI in the workplace. Our tools assist organizations in identifying diverse talent, fostering inclusive hiring practices, and aligning with DEI goals.'
        }
      ]
    },
    {
      type: 'heading',
      level: 2,
      className: 'mt-16 text-2xl font-bold tracking-tight text-gray-900',
      text: "Why choosing GoSkilling?"
    },
    {
      type: 'paragraph',
      className: 'mt-8',
      text: "Whether you're an employer looking to make data-driven hiring decisions or a jobseeker seeking to stand out in a competitive job market, GoSkilling is your partner for success. Our platform empowers both sides of the hiring equation, bringing precision, fairness, and efficiency to the talent assessment process. Join us today to unlock the potential of your workforce or career journey with GoSkilling's innovative solutions."
    }
  ]
}

const accuracy = {
  intro: {
    heading: `Unlock Data-Driven Talent Insights with GoSkilling`,
    subheading: `Accurate Insights`,
    lead: `GoSkilling's AI-driven platform provides precise skill ratings, offering a deeper understanding of candidate capabilities. Enhance your recruitment with data-backed talent insights.`
  },
  quote: {
    url: 'https://www.mckinsey.com/capabilities/mckinsey-digital/our-insights/tech-forward/closing-the-tech-talent-gap-adopting-the-right-mindset',
    reference: 'McKinsey, 2023',
    author: 'Sandra Durth, Anna Lena Kalinna, and Henning Soller',
    text: '"To sustainably address the growing tech gap, [...] companies will need to rethink talent management and hiring, invest in broad upskilling, and lay the foundation for skill-based talent management."'
  },
  cta: {
    heading1:'Accurate AI-Supported Skill Evaluations',
    heading2:'Elevate Your Talent Assessment',
    lead:'Ready to revolutionize your skill assessments? Join GoSkilling today and harness AI precision for unbiased, accurate evaluations. Elevate your hiring decisions now!'
  },
  content: [
    {
      type: 'paragraph',
      className: '',
      text: "GoSkilling's AI platform delivers precise skill ratings for deep candidate insights. Elevate your recruitment with data-backed talent assessment. In today's competitive market, the right tools are crucial. Our AI assesses and rates skills accurately, moving beyond traditional methods. Access data-backed insights to identify top candidates efficiently."
    },
    {
      type: 'heading',
      level: 2,
      className: 'mt-16 text-2xl font-bold tracking-tight text-gray-900',
      text: "Why Choose GoSkilling for Talent Insights?"
    },    
    {
      type: 'paragraph',
      className: 'text-base leading-7 text-gray-700 mt-8',
      text: "In the data-driven age, GoSkilling empowers informed recruitment. Our platform offers precise skill assessments, helping you identify top talent confidently. With an ever-evolving job market, you need adaptable strategies. GoSkilling streamlines hiring and offers data-backed decisions, setting you on a path to success. Choose GoSkilling for unparalleled talent insights."
    },
    {
      type: 'heading',
      level: 2,
      className: 'mt-16 text-2xl font-bold tracking-tight text-gray-900',
      text: "Your Success with GoSkilling"
    },    
    {
      type: 'paragraph',
      className: 'text-base leading-7 text-gray-700 mt-8',
      text: "Unlock your future workforce's potential with GoSkilling's insights. Our AI enhances recruitment efficiency. Make data-backed decisions for top talent. Choose GoSkilling for success."
    }
  ]
}

const assessments = {
  intro: {
    heading: `Quantitying Abilities beyond your CV`,
    subheading: `Objective Assessments from Peers`,
    lead: `Utilize the power of quantitative peer reviews for a more objective and comprehensive view of candidates’ skills. GoSkilling’s innovative approach brings fairness and accuracy to the forefront of talent assessment.`
  },
  quote: {
    url: 'https://rdcu.be/dwx5a',
    reference: 'Nature, 2017',
    author: 'Prof. Dr. Benjamin List',
    text: '“Confidential feedback from many interacting reviewers can help [...] make better, quicker decisions”'
  },
  cta: {
    heading1:'Comprehensive Peer Assessments',
    heading2:'Empower Hiring with Peer Insights',
    lead:'Looking for a holistic talent perspective? Get started with GoSkilling and embrace peer assessments for a well-rounded view of candidates. Make informed hiring choices today!'
  },
  content: [
    {
      type: 'paragraph',
      className: '',
      text: "Harness quantitative peer reviews for a fair, comprehensive view of candidates' skills. GoSkilling's innovative approach prioritizes fairness and accuracy in talent assessment."
    },
    {
      type: 'list',
      role: 'list',
      className: 'mt-8 max-w-xl space-y-8 text-gray-600',
      items: [
        {
          icon: ScatterPlot,
          title: 'Quantitative Peer Reviews:',
          description: 'Tap into objective assessments from peers.'
        },
        {
          icon: QueryStats,
          title: 'Comprehensive Skill Evaluation: ',
          description: `Gain a holistic view of candidates' capabilities.`
        },
        {
          icon: Balance,
          title: 'Fairness and Accuracy:',
          description: 'GoSkilling ensures equitable talent assessment.'
        }
      ]
    },

  ]
}

const diversity = {
  intro: {
    heading: `Diversity unleashes a Business' Potential`,
    subheading: `Promote Diversity in Hiring`,
    lead: `GoSkilling is committed to fostering diversity and inclusion in the workplace. Our platform helps you identify diverse talent, ensuring your hiring practices align with DEI goals.`
  },
  quote: {
    url: 'https://www.mckinsey.com/featured-insights/diversity-and-inclusion/diversity-wins-how-inclusion-matters',
    reference: 'McKinsey, 2020',
    author: 'Sundiatu Dixon-Fyle, Kevin Dolan, Dame Vivian Hunt, and Sara Prince',
    text: '"The most diverse companies are now more likely than ever to outperform less diverse peers on profitability."'
  },
  cta: {
    heading1:'Diversity, Equity, and Inclusion Matters',
    heading2:'Foster Inclusivity with GoSkilling',
    lead:'Committed to diversity and inclusion? Sign up with GoSkilling and align your hiring practices with DEI goals. Make your workplace more inclusive today!'
  },
  content: [
    {
      type: 'paragraph',
      className: '',
      text: "GoSkilling is unwavering in its commitment to fostering diversity and inclusion within the workplace. We understand that a diverse workforce leads to innovation and stronger teams. Our platform is designed to assist you in identifying and embracing diverse talent, ensuring that your hiring practices align seamlessly with your Diversity, Equity, and Inclusion (DEI) objectives."
    },
    {
      type: 'heading',
      level: 2,
      className: 'mt-16 text-2xl font-bold tracking-tight text-gray-900',
      text: "Why Choose GoSkilling for DEI Initiatives?"
    },
    {
      type: 'list',
      role: 'list',
      className: 'mt-8 max-w-xl space-y-8 text-gray-600',
      items: [
        {
          icon: Diversity2,
          title: 'Diverse Talent Pool:',
          description: 'Access a broader spectrum of candidates representing various backgrounds and experiences.'
        },
        {
          icon: AllInclusive,
          title: 'Inclusive Hiring Practices:',
          description: 'GoSkilling enables you to establish fair and equitable recruitment processes.'
        },
        {
          icon: ThumbUp,
          title: 'Alignment with DEI Goals:',
          description: 'Our platform actively contributes to creating a workplace that celebrates diversity and promotes equality and integration.'
        }
      ]
    },
    {
      type: 'paragraph',
      className: 'mt-8',
      text: "With GoSkilling, you're not just hiring talent; you're building a workforce that mirrors the rich tapestry of our global society while reinforcing your commitment to DEI values."
    }
  ]
}

export function AccurateInsights() {
  return <InformationPageTemplate 
            Heading={accuracy.intro.heading} 
            Subheading={accuracy.intro.subheading} 
            Lead={accuracy.intro.lead} 
            Content={accuracy.content} 
            QuoteAuthor={accuracy.quote.author} 
            QuoteReference={accuracy.quote.reference} 
            QuoteText={accuracy.quote.text} 
            QuoteURL={accuracy.quote.url}
            CTAHeading1={accuracy.cta.heading1}
            CTAHeading2={accuracy.cta.heading2}
            CTALead={accuracy.cta.lead}
          />
}

export function PeerAssessments() {
  return <InformationPageTemplate 
            Heading={assessments.intro.heading} 
            Subheading={assessments.intro.subheading} 
            Lead={assessments.intro.lead} 
            Content={assessments.content} 
            QuoteAuthor={assessments.quote.author} 
            QuoteReference={assessments.quote.reference} 
            QuoteText={assessments.quote.text} 
            QuoteURL={assessments.quote.url}
            CTAHeading1={assessments.cta.heading1}
            CTAHeading2={assessments.cta.heading2}
            CTALead={assessments.cta.lead}
          />
}

export function Diversity() {
  return <InformationPageTemplate 
            Heading={diversity.intro.heading} 
            Subheading={diversity.intro.subheading} 
            Lead={diversity.intro.lead} 
            Content={diversity.content} 
            QuoteAuthor={diversity.quote.author} 
            QuoteReference={diversity.quote.reference} 
            QuoteText={diversity.quote.text} 
            QuoteURL={diversity.quote.url}
            CTAHeading1={diversity.cta.heading1}
            CTAHeading2={diversity.cta.heading2}
            CTALead={diversity.cta.lead}
          />
}

export function About() {
  return <InformationPageTemplate
  Heading={general.intro.heading} 
  Subheading={general.intro.subheading} 
  Lead={general.intro.lead} 
  Content={general.content} 
  CTAHeading1={general.cta.heading1}
  CTAHeading2={general.cta.heading2}
  CTALead={general.cta.lead}
/>
}