import { Fragment, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { ChevronLeftIcon, ChevronRightIcon, BriefcaseIcon, MapPinIcon, CurrencyDollarIcon, CalendarIcon } from '@heroicons/react/20/solid';
import { useForm } from "react-hook-form";
import { EmptyStateSimple } from "./ui/emptyState";
const recruiterJobs = [{
    id: 1,
    title: "Product Manager",
    description_point1: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    description_point2: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    description_point3: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    created_at: "2023-02-10",
    updated_at: "2023-09-10",
    applicants: 12,
    shortlisted: 2,
    status: "Active",
    min_salary: 20000,
    max_salary: 40000,
    salaryCurrency: 'USD',
    visaSponsoring: 'No',
    locationCountry: 'United States',
    locationState: 'California',
    locationCity: 'Mountain View',
    remoteJob: 'Hybrid',
    jobType: 'Full-Time',
    requirements: [{
        skill_id: 23,
        min_score: 30,
        name: "Java",
        category: "Programming Language",
        requirement_created: "2023-02-10",
        requirement_updated: "2023-09-09"
    }],
    applications: [
        { status: 'Invited to Apply', recruiterCanReact: false, candidateCanReact: true, systemText: 'A recruiter has extended an invitation to the user to apply for a job.', description: 'You invited the candidate to apply. If accepted, they will be shortlisted.', candidates: [] },
        {
            status: 'Submitted', recruiterCanReact: true, candidateCanReact: true, systemText: 'The application has been received.', description: 'A canddiate has submitted their application.', candidates: {
                id: 23,
                status: "Submitted",
                applicant_name: "Alpha Bravo Charlie Delta",
                skills: [{
                    skill_id: 23,
                    score: 43,
                    name: "Java",
                    category: "Programming Language",
                    score_updated: "2023-02-10"
                }],

            }
        },
        {
            status: 'Shortlisted', recruiterCanReact: true, candidateCanReact: true, systemText: 'The applicant has passed the initial screening.', description: 'You have shortlisted a candidate.', candidates: {
                id: 25,
                status: "Shortlisted",
                applicant_name: "Radar Zebra X-Ray Hotel",
                skills: [{
                    skill_id: 23,
                    score: 43,
                    name: "Java",
                    category: "Programming Language",
                    score_updated: "2023-02-10"
                }],

            }
        },
        { status: 'Interview Invite', recruiterCanReact: false, candidateCanReact: false, systemText: 'The applicant is invited for an interview.', description: 'You have invited the candidate to an interview. We have sent emails to both of you so that you can plan an interview.', candidates: [] },
        { status: 'Rejected', recruiterCanReact: false, candidateCanReact: false, systemText: 'The application has not been selected.', description: 'You have rejected the candidate', candidates: [] },
        { status: 'Withdrawn', recruiterCanReact: false, candidateCanReact: false, systemText: 'The applicant has withdrawn the application.', description: 'The applicant has withdrawn the application.', candidates: [] }
    ],
    company: [{
        company_id: 5,
        name: "LinkedIn",
        url: "linkedin.com",
        urlname: "linkedincom",
        logo: ""
    }]
}, {
    id: 2,
    title: "GoLang Developer",
    description_point1: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    description_point2: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    description_point3: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    created_at: "2023-02-10",
    updated_at: "2023-09-10",
    applicants: 12,
    shortlisted: 2,
    status: "Active",
    min_salary: 20000,
    max_salary: 40000,
    salaryCurrency: 'USD',
    visaSponsoring: 'No',
    locationCountry: 'United States',
    locationState: 'California',
    locationCity: 'Mountain View',
    remoteJob: 'Hybrid',
    jobType: 'Full-Time',
    requirements: [{
        skill_id: 23,
        min_score: 30,
        name: "Java",
        category: "Programming Language",
        requirement_created: "2023-02-10",
        requirement_updated: "2023-09-09"
    }],
    applications: [
        {
            status: 'Invited to Apply', recruiterCanReact: false, candidateCanReact: true, systemText: 'A recruiter has extended an invitation to the user to apply for a job.', description: 'You invited the candidate to apply. If accepted, they will be shortlisted.', candidates: [{
                id: 25,
                status: 'Invited to Apply',
                applicant_name: "Radar Zebra X-Ray Hotel",
                skills: [{
                    skill_id: 23,
                    score: 43,
                    name: "Java",
                    category: "Programming Language",
                    score_updated: "2023-02-10"
                }],

            }]
        },
        {
            status: 'Submitted', recruiterCanReact: true, candidateCanReact: true, systemText: 'The application has been received.', description: 'A canddiate has submitted their application.', candidates: [{
                id: 23,
                status: "Submitted",
                applicant_name: "Alpha Bravo Charlie Delta",
                skills: [{
                    skill_id: 23,
                    score: 43,
                    name: "Java",
                    category: "Programming Language",
                    score_updated: "2023-02-10"
                }],

            }]
        },
        {
            status: 'Shortlisted', recruiterCanReact: true, candidateCanReact: true, systemText: 'The applicant has passed the initial screening.', description: 'You have shortlisted a candidate.', candidates: [{
                id: 25,
                status: "Shortlisted",
                applicant_name: "Radar Zebra X-Ray Hotel",
                skills: [{
                    skill_id: 23,
                    score: 43,
                    name: "Java",
                    category: "Programming Language",
                    score_updated: "2023-02-10"
                }],

            }]
        },
        { status: 'Interview Invite', recruiterCanReact: false, candidateCanReact: false, systemText: 'The applicant is invited for an interview.', description: 'You have invited the candidate to an interview. We have sent emails to both of you so that you can plan an interview.', candidates: [] },
        { status: 'Rejected', recruiterCanReact: false, candidateCanReact: false, systemText: 'The application has not been selected.', description: 'You have rejected the candidate', candidates: [] },
        { status: 'Withdrawn', recruiterCanReact: false, candidateCanReact: false, systemText: 'The applicant has withdrawn the application.', description: 'The applicant has withdrawn the application.', candidates: [] }
    ],
    company: [{
        company_id: 5,
        name: "Meta",
        url: "meta.com",
        urlname: "metacom",
        logo: ""
    }]
}, {
    id: 3,
    title: "C++ Developer",
    description_point1: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    description_point2: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    description_point3: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    created_at: "2023-02-10",
    updated_at: "2023-09-10",
    applicants: 12,
    shortlisted: 2,
    status: "Active",
    min_salary: 20000,
    max_salary: 40000,
    salaryCurrency: 'USD',
    visaSponsoring: 'No',
    locationCountry: 'United States',
    locationState: 'California',
    locationCity: 'Mountain View',
    remoteJob: 'Hybrid',
    jobType: 'Full-Time',
    requirements: [{
        skill_id: 23,
        min_score: 30,
        name: "Java",
        category: "Programming Language",
        requirement_created: "2023-02-10",
        requirement_updated: "2023-09-09"
    }],
    applications: [
        { status: 'Invited to Apply', recruiterCanReact: false, candidateCanReact: true, systemText: 'A recruiter has extended an invitation to the user to apply for a job.', description: 'You invited the candidate to apply. If accepted, they will be shortlisted.', candidates: [] },
        {
            status: 'Submitted', recruiterCanReact: true, candidateCanReact: true, systemText: 'The application has been received.', description: 'A canddiate has submitted their application.', candidates: {
                id: 23,
                status: "Submitted",
                applicant_name: "Alpha Bravo Charlie Delta",
                skills: [{
                    skill_id: 23,
                    score: 43,
                    name: "Java",
                    category: "Programming Language",
                    score_updated: "2023-02-10"
                }],

            }
        },
        {
            status: 'Shortlisted', recruiterCanReact: true, candidateCanReact: true, systemText: 'The applicant has passed the initial screening.', description: 'You have shortlisted a candidate.', candidates: {
                id: 25,
                status: "Shortlisted",
                applicant_name: "Radar Zebra X-Ray Hotel",
                skills: [{
                    skill_id: 23,
                    score: 43,
                    name: "Java",
                    category: "Programming Language",
                    score_updated: "2023-02-10"
                }],

            }
        },
        { status: 'Interview Invite', recruiterCanReact: false, candidateCanReact: false, systemText: 'The applicant is invited for an interview.', description: 'You have invited the candidate to an interview. We have sent emails to both of you so that you can plan an interview.', candidates: [] },
        { status: 'Rejected', recruiterCanReact: false, candidateCanReact: false, systemText: 'The application has not been selected.', description: 'You have rejected the candidate', candidates: [] },
        { status: 'Withdrawn', recruiterCanReact: false, candidateCanReact: false, systemText: 'The applicant has withdrawn the application.', description: 'The applicant has withdrawn the application.', candidates: [] }
    ],
    company: [{
        company_id: 5,
        name: "Google",
        url: "google.com",
        urlname: "googlecom",
        logo: ""
    }]
}, {
    id: 4,
    title: "DevOps Engineer",
    description_point1: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    description_point2: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    description_point3: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    created_at: "2023-02-10",
    updated_at: "2023-09-10",
    applicants: 12,
    shortlisted: 2,
    status: "Active",
    min_salary: 20000,
    max_salary: 40000,
    salaryCurrency: 'USD',
    visaSponsoring: 'No',
    locationCountry: 'United States',
    locationState: 'California',
    locationCity: 'Mountain View',
    remoteJob: 'Hybrid',
    jobType: 'Full-Time',
    requirements: [{
        skill_id: 23,
        min_score: 30,
        name: "Java",
        category: "Programming Language",
        requirement_created: "2023-02-10",
        requirement_updated: "2023-09-09"
    }],
    applications: [
        { status: 'Invited to Apply', recruiterCanReact: false, candidateCanReact: true, systemText: 'A recruiter has extended an invitation to the user to apply for a job.', description: 'You invited the candidate to apply. If accepted, they will be shortlisted.', candidates: [] },
        {
            status: 'Submitted', recruiterCanReact: true, candidateCanReact: true, systemText: 'The application has been received.', description: 'A canddiate has submitted their application.', candidates: {
                id: 23,
                status: "Submitted",
                applicant_name: "Alpha Bravo Charlie Delta",
                skills: [{
                    skill_id: 23,
                    score: 43,
                    name: "Java",
                    category: "Programming Language",
                    score_updated: "2023-02-10"
                }],

            }
        },
        {
            status: 'Shortlisted', recruiterCanReact: true, candidateCanReact: true, systemText: 'The applicant has passed the initial screening.', description: 'You have shortlisted a candidate.', candidates: {
                id: 25,
                status: "Shortlisted",
                applicant_name: "Radar Zebra X-Ray Hotel",
                skills: [{
                    skill_id: 23,
                    score: 43,
                    name: "Java",
                    category: "Programming Language",
                    score_updated: "2023-02-10"
                }],

            }
        },
        { status: 'Interview Invite', recruiterCanReact: false, candidateCanReact: false, systemText: 'The applicant is invited for an interview.', description: 'You have invited the candidate to an interview. We have sent emails to both of you so that you can plan an interview.', candidates: [] },
        { status: 'Rejected', recruiterCanReact: false, candidateCanReact: false, systemText: 'The application has not been selected.', description: 'You have rejected the candidate', candidates: [] },
        { status: 'Withdrawn', recruiterCanReact: false, candidateCanReact: false, systemText: 'The applicant has withdrawn the application.', description: 'The applicant has withdrawn the application.', candidates: [] }
    ],
    company: [{
        company_id: 5,
        name: "Microsoft",
        url: "microsoft.com",
        urlname: "microsoftcom",
        logo: ""
    }]
}]

export function RecruiterJobs() {
    return <div>
        <div className="relative flex flex-col gap-y-6 p-4 bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Jobs</h1>
                    <p className="mt-2 text-sm text-gray-700">
                        All your active, paused, and drafted jobs.
                    </p>
                </div>
                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    <button
                        type="button"
                        className="block rounded-md bg-brand-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-brand-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600"
                    >
                        Create Job
                    </button>
                </div>
            </div>
            <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                        Name
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Title
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Status
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Dates
                                    </th>
                                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                                        <span className="sr-only">Edit</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                                {recruiterJobs.map((job, index) => (
                                    <JobElement job={job} key={index} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

function JobElement({ job }) {
    return <tr key={job.id}>
        <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:py-2">
            <div className="flex items-center">
                <div className="h-11 w-11 flex-shrink-0">
                    <img className="h-11 w-11 rounded-full" src={job.image} alt="" />
                </div>
                <div className="ml-4">
                    <div className="font-medium text-gray-900">{job.title}</div>
                    <div className="mt-1 text-gray-500">{job.company[0].name}</div>
                </div>
            </div>
        </td>
        <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
            <div className="text-gray-900">{job.applications.length} Applicants</div>
            <div className="mt-1 text-gray-500">{job.applications.filter((obj) => obj.status === "Shortlisted").length} Shortlisted</div>
        </td>
        <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
            <span className={
                job.status == "Draft"
                    ? "inline-flex items-center rounded-md bg-brand-50 px-2 py-1 text-xs font-medium text-brand-700 ring-1 ring-inset ring-brand-600/20"
                    : job.status == "Active"
                        ? "inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20"
                        : "inline-flex items-center rounded-md bg-accent-50 px-2 py-1 text-xs font-medium text-accent-700 ring-1 ring-inset ring-accent-600/20"
            }>
                {job.status}
            </span>
        </td>
        <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
            <div className="text-gray-900">{job.created_at} created</div>
            <div className="mt-1 text-gray-500">{job.updated_at} updated</div>
        </td>
        <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-2">
            <Link to={"/recruiter/jobs/job/" + job.id} className="text-brand-600 hover:text-brand-900">
                Edit<span className="sr-only">, {job.name}</span>
            </Link>
        </td>
    </tr>
}

export function RecruiterViewJob() {
    let { job } = useParams();
    const [showJobEditor, setShowJobEditor] = useState(false);
    const [showJobPreview, setShowJobPreview] = useState(false);

    const jobData = recruiterJobs.find(o => o.id == job);

    const changeTab = (tab) => {
        if(tab){
            if(tab=="editor"){
                setShowJobPreview(false);
                setShowJobEditor(true);
            } else {
                setShowJobEditor(false);
                setShowJobPreview(true);
            }
        } else {
            setShowJobEditor(false);
            setShowJobPreview(false);
        }
    }

    //const jobData = recruiterJobs[job];
    return (
        <div className="bg-white rounded-lg p-6 shadow-lg w-full">
            <div >
                <div>
                    <nav className="sm:hidden" aria-label="Back">
                        <a href="#" className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700">
                            <ChevronLeftIcon className="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                            Back
                        </a>
                    </nav>
                    <nav className="hidden sm:flex" aria-label="Breadcrumb">
                        <ol role="list" className="flex items-center space-x-4">
                            <li>
                                <div className="flex">
                                    <Link to={"/recruiter/jobs"} className="text-sm font-medium text-gray-500 hover:text-gray-700">
                                        Jobs
                                    </Link>
                                </div>
                            </li>
                            <li>
                                <div className="flex items-center">
                                    <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <Link to={"/recruiter/jobs/job/" + job} className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                                        {jobData.title}
                                    </Link>
                                </div>
                            </li>
                        </ol>
                    </nav>
                </div>
                <div className="mt-2 md:flex md:items-center md:justify-between">
                    <div className="min-w-0 flex-1">
                        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                            {jobData.title}
                        </h2>
                        <p className="text-sm my-2 font-medium text-gray-500">
                            Hiring for{' '}
                            <Link to="#" className="text-secondary-500">
                                {jobData.company[0].name}
                            </Link>{' '}
                        </p>
                        <div className="mb-3 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
                            <div className="mt-2 flex items-center text-sm text-gray-500">
                                <BriefcaseIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                {jobData.jobType}
                            </div>
                            <div className="mt-2 flex items-center text-sm text-gray-500">
                                <MapPinIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                {jobData.locationCity + ", " + jobData.locationState + ", " + jobData.locationCountry + " " + jobData.remoteJob}
                            </div>
                            <div className="mt-2 flex items-center text-sm text-gray-500">
                                <CurrencyDollarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                {jobData.salaryCurrency + " " + jobData.min_salary} &ndash; {jobData.salaryCurrency + " " + jobData.max_salary}
                            </div>
                            <div className="mt-2 flex items-center text-sm text-gray-500">
                                <CalendarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                Closing on January 9, 2020
                            </div>
                        </div>
                    </div>
                    <div className="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
                        <button
                            type="button"
                            onClick={() => changeTab(showJobEditor ? "" : "editor")}
                            className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        >
                            Edit
                        </button>
                        <button
                            type="button"
                            onClick={() => changeTab(showJobPreview ? "" : "preview")}
                            className="ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        >
                            Preview
                        </button>
                        <button
                            type="button"
                            className="ml-3 inline-flex items-center rounded-md bg-brand-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600"
                        >
                            Publish
                        </button>
                    </div>
                </div>
            </div>

            <div>
                { showJobPreview && <>
                    <h5 className="text-md font-semibold">About the Company</h5>
                    <p className="mb-4">{jobData.description_point1}</p>

                    <h5 className="text-md font-semibold">About the Job</h5>
                    <p className="mb-4">{jobData.description_point2}</p>
                    <h5 className="text-md font-semibold">Requirements</h5>
                    <table className="my-3">
                        <tbody>
                            {jobData.requirements.map((skill, index) =>
                                <tr key={index} className={"border-b border-gray-200 hover:bg-blue-50"} colSpan="5">
                                    <td className="p-2">
                                        <div className="font-bold text-primary-500">{skill.name}</div>
                                        <div className="text-xs text-secondary-500 mt-1 flex items-center">
                                            <span>{skill.category}</span>
                                            <span className="mx-1">•</span>
                                        </div>
                                    </td>
                                    <td className="p-2">
                                        <div className="font-bold">{skill.min_score ? skill.min_score : '-'}</div>
                                        <div className="text-xs text-secondary-500 mt-1">Score</div>
                                    </td>

                                </tr>
                            )}
                        </tbody>

                    </table>
                    <h5 className="text-md font-semibold">Duties</h5>
                    <p className="mb-4">{jobData.description_point3}</p>                
                </>

                }
            </div>

            <div>
                {showJobEditor && <JobEditor job_id={job}/>}
                {!showJobEditor && !showJobPreview &&
                    <table className="min-w-full">
                        <tbody>
                            {jobData.applications.map((status) => (
                                <Fragment key={status.status}>
                                    <tr className="border-t border-gray-200">
                                        <th
                                            colSpan={5}
                                            scope="colgroup"
                                            className="bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                                        >
                                            {status.status}
                                        </th>
                                    </tr>
                                    <tr>
                                        {status.candidates && status.candidates.length > 0 ? (
                                            status.candidates.map((candidate, index) => (
                                                <div key={index}>
                                                    <CandidateData person={candidate} personIdx={index} recruiterReaction={status.recruiterCanReact} />
                                                </div>
                                            ))
                                        ) : (
                                            <td colSpan={5} py-1><EmptyStateSimple /></td>
                                        )}

                                    </tr>
                                </Fragment>
                            ))}
                        </tbody>
                    </table>}

            </div>
        </div>
    )
}

function JobEditor({job_id}) {
    return <div>
        <EditJobDetails />
        <EditJobSkills job_identifier={job_id} />

    </div>
}

function EditJobDetails() {
    const { register, handleSubmit, setValue } = useForm();
    const onNameSubmit = () => {
        console.log("ah oh");
    }
    return <div>

        Job Details
        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
            <div>
                <h2 className="text-base font-semibold leading-7 text-gray-900">Job Profile</h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">
                    This information will be displayed publicly so be careful what you share.
                </p>
            </div>

            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                <div className="sm:col-span-4">
                    <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                        Job Title
                    </label>
                    <div className="mt-2">
                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <input
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                type="text"
                                id="title"
                                name="title"
                                {...register("title")}
                            />
                        </div>
                    </div>
                    <label htmlFor="description_point1" className="block text-sm font-medium leading-6 text-gray-900">
                        About the hiring Company
                    </label>
                    <div className="mt-2">
                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <textarea
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                type="text"
                                id="description_point1"
                                name="description_point1"
                                rows={3}
                                {...register("description_point1")}
                            />
                        </div>
                    </div>
                    <label htmlFor="description_point2" className="block text-sm font-medium leading-6 text-gray-900">
                        About the Job
                    </label>
                    <div className="mt-2">
                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <textarea
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                type="text"
                                id="description_point2"
                                name="description_point2"
                                rows={3}
                                {...register("description_point2")}
                            />
                        </div>
                    </div>
                    <label htmlFor="description_point3" className="block text-sm font-medium leading-6 text-gray-900">
                        About the Requirements
                    </label>
                    <div className="mt-2">
                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <textarea
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                type="text"
                                id="description_point3"
                                name="description_point3"
                                rows={3}
                                {...register("description_point3")}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
            <div>
                <h2 className="text-base font-semibold leading-7 text-gray-900">Job Details</h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">
                    These information are necessary to match you with the right candidates and/or to meet legal requirements.
                </p>
            </div>

            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                <div className="sm:col-span-4">
                    <label htmlFor="min_salary" className="block text-sm font-medium leading-6 text-gray-900">
                        Minimum Salary Range
                    </label>
                    <div className="mt-2">
                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <input
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                type="number"
                                id="min_salary"
                                name="min_salary"
                                {...register("min_salary")}
                            />
                        </div>
                    </div>
                    <label htmlFor="max_salary" className="block text-sm font-medium leading-6 text-gray-900">
                        Maximum Salary Range
                    </label>
                    <div className="mt-2">
                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <input
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                type="number"
                                id="max_salary"
                                name="max_salary"
                                {...register("max_salary")}
                            />
                        </div>
                    </div>
                    <label htmlFor="salaryCurrency" className="block text-sm font-medium leading-6 text-gray-700">
                        Salary Currency
                    </label>
                    <select
                        id="salaryCurrency"
                        name="salaryCurrency"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6"
                        {...register("salaryCurrency")}
                    >
                        <option value="USD">US-Dollar</option>
                        <option value="CDA">Canadian Dollar</option>
                        <option value="EUR">Euro</option>
                        <option value="CHF">Swiss Francs</option>
                        <option value="GBP">British Pound</option>
                    </select>
                    <label htmlFor="visaSponsoring" className="block text-sm font-medium leading-6 text-gray-700">
                        Visa Sponsoring
                    </label>
                    <select
                        id="visaSponsoring"
                        name="visaSponsoring"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6"
                        {...register("visaSponsoring")}
                    >
                        <option value="Y">Yes</option>
                        <option value="N">No</option>
                    </select>
                    <label htmlFor="jobType" className="block text-sm font-medium leading-6 text-gray-700">
                        Job Type
                    </label>
                    <select
                        id="jobType"
                        name="jobType"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6"
                        {...register("jobType")}
                    >
                        <option value="Full-Time">Full-Time</option>
                        <option value="Part-Time">Part-Time</option>
                        <option value="Accomodation">Accomodation</option>
                        <option value="Internship">Internship</option>
                        <option value="Contract">Contract</option>
                    </select>
                    <label htmlFor="remoteJob" className="block text-sm font-medium leading-6 text-gray-700">
                        Remote option
                    </label>
                    <select
                        id="remoteJob"
                        name="remoteJob"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6"
                        {...register("remoteJob")}
                    >
                        <option value="Fully Remote">Fully Remote</option>
                        <option value="No Remote">No Remote</option>
                        <option value="Hybrid">Hybrid</option>
                    </select>
                    <label htmlFor="locationCountry" className="block text-sm font-medium leading-6 text-gray-700">
                        Country
                    </label>
                    <select
                        id="locationCountry"
                        name="locationCountry"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6"
                        {...register("locationCountry")}
                    >
                        <option value="Fully Remote">Fully Remote</option>
                        <option value="No Remote">No Remote</option>
                        <option value="Hybrid">Hybrid</option>
                    </select>
                    <label htmlFor="locationState" className="block text-sm font-medium leading-6 text-gray-700">
                        State/Province
                    </label>
                    <select
                        id="locationState"
                        name="locationState"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6"
                        {...register("locationState")}
                    >
                        <option value="Fully Remote">Fully Remote</option>
                        <option value="No Remote">No Remote</option>
                        <option value="Hybrid">Hybrid</option>
                    </select>
                    <label htmlFor="locationCity" className="block text-sm font-medium leading-6 text-gray-900">
                        City
                    </label>
                    <div className="mt-2">
                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <input
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                type="text"
                                id="locationCity"
                                name="locationCity"
                                {...register("locationCity")}
                            />
                        </div>
                    </div>


                </div>
            </div>
        </div>
        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
            <div>
                <h2 className="text-base font-semibold leading-7 text-gray-900">Hiring Company</h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">
                    The company you are hiring for can be a reason for potential candidates to apply for the job.
                </p>
            </div>

            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                <div className="sm:col-span-4">
                    <label htmlFor="company_url" className="block text-sm font-medium leading-6 text-gray-900">
                        Company URL
                    </label>
                    <div className="mt-2">
                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <input
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                type="text"
                                id="company_url"
                                name="company_url"
                                {...register("company_url")}
                            />
                        </div>
                    </div>
                    <label htmlFor="url" className="block text-sm font-medium leading-6 text-gray-900">
                        Company URL
                    </label>
                    <div className="mt-2">
                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <input
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                type="text"
                                id="url"
                                name="url"
                                {...register("url")}
                            />
                        </div>
                    </div>
                    <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                        Company Name
                    </label>
                    <div className="mt-2">
                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <input
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                type="text"
                                id="name"
                                name="name"
                                {...register("name")}
                            />
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
            <div>
                <h2 className="text-base font-semibold leading-7 text-gray-900">Hiring Company</h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">
                    The company you are hiring for can be a reason for potential candidates to apply for the job.
                </p>
            </div>

            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                <div className="sm:col-span-4">
                    <label htmlFor="company_url" className="block text-sm font-medium leading-6 text-gray-900">
                        Company URL
                    </label>
                    <div className="mt-2">
                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <input
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                type="text"
                                id="company_url"
                                name="company_url"
                                {...register("company_url")}
                            />
                        </div>
                    </div>
                    <label htmlFor="url" className="block text-sm font-medium leading-6 text-gray-900">
                        Company URL
                    </label>
                    <div className="mt-2">
                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <input
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                type="text"
                                id="url"
                                name="url"
                                {...register("url")}
                            />
                        </div>
                    </div>
                    <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                        Company Name
                    </label>
                    <div className="mt-2">
                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <input
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                type="text"
                                id="name"
                                name="name"
                                {...register("name")}
                            />
                        </div>
                    </div>

                </div>
            </div>
        </div>



        <form onSubmit={handleSubmit(onNameSubmit)}>
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-3">
                    <label htmlFor="firstName" className="block text-sm font-medium leading-6 text-gray-700">
                        First Name
                    </label>
                    <input
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6"
                        type="text"
                        id="firstName"
                        name="firstName"
                        {...register("firstName")}
                    />

                </div>
                <div className="sm:col-span-3">
                    <label htmlFor="first_name" className="block text-sm font-medium leading-6 text-gray-700">
                        Last Name
                    </label>
                    <input
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6"
                        type="text"
                        id="lastName"
                        name="lastName"
                        {...register("lastName")}
                    />

                </div>
            </div>
            <button type="submit" className="flex w-full mx-auto mt-4 mb-2 items-center justify-center rounded-md disabled:bg-brand-100 disabled:text-gray-800 bg-secondary-100 border-gray-700  px-3 py-2 text-sm font-semibold text-gray-50 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-secondary-200 hover:text-white focus-visible:outline-offset-0">Save</button>


        </form>
    </div>
}

function EditJobSkills({job_identifier}) {
    const { register, handleSubmit, setValue } = useForm();
    const onNameSubmit = () => {
        console.log("ah oh");
    }

    const jobData = recruiterJobs.find(o => o.id == job_identifier);
    const skills = jobData.requirements;
    return <div>
              <ul>
                
        {skills.map((skill, index) => (
          <li key={index} className="flex items-center justify-between mb-2">
            <span className="text-lg">{skill.name}</span>

            <input
              type="number"
              min="0"
              max="5"
              value={skill.min_score}
              //onChange={(e) => handleScoreChange(index, e)}
              className="border rounded w-16 text-center"
            />

            <button
              //onClick={() => handleRemoveSkill(index)}
              className="bg-red-500 text-white rounded px-2 ml-2"
            >
              Remove
            </button>
          </li>
        ))}
      </ul>
        Job Skills
        <form onSubmit={handleSubmit(onNameSubmit)}>
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-3">
                    <label htmlFor="firstName" className="block text-sm font-medium leading-6 text-gray-700">
                        First Name
                    </label>
                    <input
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6"
                        type="text"
                        id="firstName"
                        name="firstName"
                        {...register("firstName")}
                    />

                </div>
                <div className="sm:col-span-3">
                    <label htmlFor="first_name" className="block text-sm font-medium leading-6 text-gray-700">
                        Last Name
                    </label>
                    <input
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6"
                        type="text"
                        id="lastName"
                        name="lastName"
                        {...register("lastName")}
                    />

                </div>
            </div>
            <button type="submit" className="flex w-full mx-auto mt-4 mb-2 items-center justify-center rounded-md disabled:bg-brand-100 disabled:text-gray-800 bg-secondary-100 border-gray-700  px-3 py-2 text-sm font-semibold text-gray-50 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-secondary-200 hover:text-white focus-visible:outline-offset-0">Save</button>


        </form>
    </div>
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


function CandidateData({ person, personIdx, recruiterReaction }) {
    const [showSkills, setShowSkills] = useState(false);
    const { register, handleSubmit, setValue } = useForm();

    useEffect(() => {
        if (person) {
            setValue('applicationStatus', person.status);
        }
    }, [person, setValue]);

    return <>
        <Fragment
            key={person.applicant_name}
        //className={classNames(personIdx === 0 ? 'border-gray-300' : 'border-gray-200', 'border-t')}
        >
            <td colSpan={1} className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                {person.applicant_name}
            </td>
            <td colSpan={1} className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                <label htmlFor="applicationstatus" className="block text-sm font-medium leading-6 text-gray-700">
                    Update Status
                </label>
                <select
                    id="applicationstatus"
                    name="applicationstatus"
                    disabled={!recruiterReaction}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6"
                    {...register("applicationstatus")}
                >
                    <option value="Shortlist">Shortlist</option>
                    <option value="Interview Invite">Interview Invite</option>
                    <option value="Reject">Reject</option>

                </select>
                <button type="button" onClick={() => setShowSkills(!showSkills)} className="text-indigo-600 hover:text-indigo-900">
                    Show Skills<span className="sr-only">, {person.name}</span>
                </button>
            </td>
        </Fragment>


        {showSkills
            ? person.skills.map((skill, index) =>
                <tr key={index} className={"border-b border-gray-200 hover:bg-blue-50"} colSpan="5">
                    <td className="p-2">
                        <div className="font-bold text-primary-500">{skill.name}</div>
                        <div className="text-xs text-secondary-500 mt-1 flex items-center">
                            <span>{skill.category}</span>
                            <span className="mx-1">•</span>
                        </div>
                    </td>
                    <td className="p-2">
                        <div className="font-bold">{skill.score ? skill.score : '-'}</div>
                        <div className="text-xs text-secondary-500 mt-1">Score</div>
                    </td>

                    <td className="p-2">
                        <div className="font-bold">{skill.scoring_change ? skill.scoring_change >= 0 ? '+' : '' : '<>'}{skill.scoring_change}</div>
                        <div className="text-xs text-secondary-500 mt-1">Change</div>
                    </td>

                </tr>
            )
            : ""}
    </>
}