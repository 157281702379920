import { useState } from 'react'
import { ChevronRightIcon, XCircleIcon, CheckCircleIcon, ExclamationCircleIcon, XMarkIcon } from '@heroicons/react/20/solid'
import { checkEmailEnding, checkCompanyURL } from '../services/apiCalls';
import { set, useForm } from 'react-hook-form';
import { removeExperience, saveNewExperience, changeJobStatus, changeMailReceiver} from '../redux/actions/loginActions';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { EmptyStateSimple } from './ui/emptyState';
import { useDocumentTitle } from '../services/titleChanger';
const people = [
    {
        name: 'LinkedIn',
        email: 'christian.kramp@gmail.com',
        role: 'Co-Founder / CEO',
        logo:
            'https://media.licdn.com/dms/image/C560BAQHaVYd13rRz3A/company-logo_200_200/0/1638831589865?e=1703116800&v=beta&t=_NAWiusBL46ZSEF0yAW5kZb5sIRuE_j-_dlzyjuIQ68',
        href: '#',
        lastSeen: '3h ago',
        lastSeenDateTime: '2023-01-23T13:23Z',
    },
    {
        name: 'Goldman Sachs',
        email: 'krampc@goldmansachs.com',
        role: 'Co-Founder / CTO',
        logo:
            'https://media.licdn.com/dms/image/C4E0BAQHm5bYK6emQSg/company-logo_200_200/0/1595518030728?e=1703116800&v=beta&t=zQ_KZceMvyfGPI9CLB1gOHhc9h2jjvwLRm01Nn9obQs',
        href: '#',
        lastSeen: '3h ago',
        lastSeenDateTime: '2023-01-23T13:23Z',
    },
    {
        name: 'Quantic School of Business & Technology',
        email: 'krampeb78@quantic.edu',
        role: 'Executive MBA',
        logo:
            'https://media.licdn.com/dms/image/D4E0BAQGedH52d3Budg/company-logo_100_100/0/1681347819259?e=1703116800&v=beta&t=5-dSHN8BNAtd-w8H9lDhaO61Aeix7zaAJmpUnfPraQE',
        href: '#',
        lastSeen: null,
    },

]

const defaultValues = {
    email: "",
    url: "",
    name: ""
}

export default function WorkEmails() {
    const [showEmailEditor, setShowEmailEditor] = useState(false);
    const [emailExists, setEmailExists] = useState(false); // Dummy state for demonstration
    const [urlExists, setUrlExists] = useState(false);
    const [emailChecked, setEmailChecked] = useState(false);
    const [urlChecked, setUrlChecked] = useState(false);
    const [company, setCompany] = useState(false);
    const [formReady, setFormReady] = useState(false);
    const toggleEmailEditor = () => {
        setShowEmailEditor(!showEmailEditor);
    }
    const dispatch = useDispatch();
    const { register, handleSubmit, watch, reset, formState: { errors, isValid }, } = useForm({ defaultValues });
    const userExperiences = useSelector((state) => state.authReducer.userProfile.experiences);
    const storedWorkEmails = useSelector((state) => state.authReducer.userProfile.workEmails);

    function getSecondPart(str) {
        return str.split('@')[1];
    }
    const checkHasNoEmail = watch("hasNoEmail", false); // watch the value of checkbox
    const checkHasNoURL = watch("hasNoURL", false); // watch the value of checkbox
    useDocumentTitle('Work Emails / Experiences | GoSkilling');
    const resetStates = () => {
        setEmailChecked(false);
        setEmailExists(false);
        setUrlChecked(false);
        setUrlExists(false);
        setCompany(false);
        setFormReady(false);
        reset({ defaultValues })
    }

    const validateURL = (url) => {
        const pattern = /^(?:https?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;
        return pattern.test(url) || "Invalid URL format";
    };

    // Mock function to check email in database (replace with real API call)
    // const checkEmailInDatabase = async (data) => {
    //     const { role, email, url, name, hasNoEmail } = data;

    //     const email_ending = getSecondPart(email)

    //     if (!emailChecked && !checkHasNoEmail) {
    //         try {
    //             const response = await checkEmailEnding(email_ending);

    //             if (response.status == "success") {
    //                 setCompany(response.data);
    //                 setEmailExists(true);
    //                 setEmailChecked(true);
    //                 setFormReady(true);
    //             } else {
    //                 setEmailExists(false);
    //                 setCompany(false);
    //                 setEmailChecked(true);
    //                 setFormReady(false);
    //             }

    //             const obj = {
    //                 role: role,
    //                 email: email,
    //                 company: company
    //             }
    //         } catch (error) {

    //             setCompany(false);
    //             setEmailExists(false);
    //             setEmailChecked(false);
    //             setFormReady(false);
    //         }
    //     } else if ((emailChecked && !emailExists && !urlChecked) || (checkHasNoEmail && !checkHasNoURL)) {
    //         const responseCompanyURL = await checkCompanyURL(url);
    //         if (responseCompanyURL.status == "success") {

    //             setCompany(responseCompanyURL.data);
    //             setUrlExists(true);
    //             setUrlChecked(true);
    //             setFormReady(true);
    //             const obj = {
    //                 role: role,
    //                 email: email,
    //                 company: company
    //             }

    //             console.log(obj);
    //         } else {
    //             setUrlExists(false);
    //             setCompany(false);
    //             setUrlChecked(true);
    //             setFormReady(false);
    //         }
    //     } else if ((emailChecked && !emailExists && urlChecked && !urlExists) || (checkHasNoEmail && checkHasNoURL)) {
    //         if (name.length > 0) {
    //             setFormReady(true);

    //             const obj = {
    //                 role: role,
    //                 email: email,
    //                 company: {
    //                     name: name,
    //                     url: checkHasNoURL ? "" : url,
    //                     email_ending: checkHasNoEmail ? "" : email_ending
    //                 },
    //             };

    //             dispatch(saveNewExperience(obj,storedWorkEmails));
    //             resetStates();
    //             toggleEmailEditor();
    //         } else {
    //             setFormReady(false);
    //         }
    //     } else if ((emailChecked && emailExists) || (urlChecked && urlExists)) {

    //         setFormReady(true);
    //         const obj = {
    //             role: role,
    //             email: email,
    //             company: company,
    //         };

    //         dispatch(saveNewExperience(obj,storedWorkEmails));
    //         resetStates();
    //         toggleEmailEditor();
    //     }
    // }

    const checkEmail = async (email_ending, role, email) => {
        const response = await checkEmailEnding(email_ending);
        let emailExists = false;
        let company = false;
        
        if (response.status == "success") {
          emailExists = true;
          company = response.data;
        }
      
        return { emailExists, company };
      };
      
      const checkURL = async (url, role, email) => {
        const response = await checkCompanyURL(url);
        let urlExists = false;
        let company = false;
      
        if (response.status == "success") {
          urlExists = true;
          company = response.data;
        }
      
        return { urlExists, company };
      };
      
      const createObject = (role, email, company) => {
        return {
          role,
          email,
          company,
        };
      };

      const checkEmailInDatabase = async (data) => {
        const { role, email, url, name, hasNoEmail } = data;
        const email_ending = getSecondPart(email);
      
        try {
          if (!emailChecked && !checkHasNoEmail) {
            const { emailExists, company } = await checkEmail(email_ending, role, email);
            setCompany(company);
            setEmailExists(emailExists);
            setEmailChecked(true);
            setFormReady(emailExists);
          }
      
          if ((!emailExists && !urlChecked && emailChecked) || (checkHasNoEmail && !checkHasNoURL)) {
            const { urlExists, company } = await checkURL(url, role, email);
            setCompany(company);
            setUrlExists(urlExists);
            setUrlChecked(true);
            setFormReady(urlExists);
          }
      
          if ((!emailExists && urlChecked && !urlExists) || (checkHasNoEmail && checkHasNoURL)) {
            setFormReady(name.length > 0);
            if (name.length > 0) {
              dispatch(saveNewExperience(createObject(role, email, { name, url: checkHasNoURL ? "" : url, email_ending: checkHasNoEmail ? "" : email_ending }), storedWorkEmails));
              resetStates();
              toggleEmailEditor();
            }
          }
      
          if ((emailExists && emailChecked) || (urlChecked && urlExists)) {
            dispatch(saveNewExperience(createObject(role, email, company), storedWorkEmails));
            resetStates();
            toggleEmailEditor();
          }
      
        } catch (error) {
          console.error(error);
          // Handle the error appropriately
          setFormReady(false);
        }
      };
      

    const removeSelectedExperience = async (experienceId) => {
        dispatch(removeExperience(experienceId))
    }

    const deactivateEmails = async (email) => {
        //console.log(email);
        dispatch(changeMailReceiver(email));
    }

    const deactivateJob = async (experienceId) => {
        //console.log(experienceId);
        dispatch(changeJobStatus(experienceId));
    }

    return (
        <>
            <div>
                {showEmailEditor ? (
                    // Your email editor component here
                    <div className="relative flex flex-col gap-y-6 p-4 bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Add Work Email</h2>
                        
                        <form onSubmit={handleSubmit(checkEmailInDatabase)}>
                            <div >

                                <label htmlFor="role">Role</label>
                                <div className='flex  space-x-4'>
                                    <input
                                        id="role"
                                        name="role"
                                        type="text"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6"
                                        {...register("role", {

                                            required: "This is required",
                                        })}
                                    />
                                </div>
                            </div>

                            <div>

                                <div className='flex space-x-4'>
                                    <input
                                        name="hasNoEmail"
                                        value={true}
                                        type="checkbox"
                                        onClick={() => { setEmailChecked(false); setUrlChecked(false); setCompany(false); setFormReady(false); }}
                                        {...register("hasNoEmail")}
                                    /><label htmlFor="hasNoEmail">I have <b>no Work Email</b> for this job</label>
                                </div>
                            </div>


                            {checkHasNoEmail ? "" :
                                <div >
                                    <label htmlFor="email">Work Email</label>
                                    <div className='flex  space-x-4'>
                                        <input
                                            id="email"
                                            name="email"
                                            type="email"
                                            autoComplete="email"
                                            onClick={() => { setEmailChecked(false); setUrlChecked(false); setCompany(false); setFormReady(false); }}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6"
                                            {...register("email", {
                                                required: checkHasNoEmail ? false : "This field is required",
                                                pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                            })}
                                        />
                                        {!emailExists && emailChecked ? <ExclamationCircleIcon className="text-accent-500 w-8 h-8" /> : emailExists && emailChecked ? <CheckCircleIcon className="text-brand-500 w-8 h-8" /> : ""}
                                    </div>
                                </div>
                            }

                            <div className='flex space-x-4'>
                                <input
                                    name="hasNoURL"
                                    value={true}
                                    type="checkbox"
                                    onClick={() => { setEmailChecked(false); setUrlChecked(false); setCompany(false); setFormReady(false); }}
                                    {...register("hasNoURL")}
                                /><label htmlFor="hasNoURL">Company/School/Project has <b>no homepage.</b></label>
                            </div>

                            {!checkHasNoURL && checkHasNoEmail || (!checkHasNoURL && !emailExists && emailChecked) ? (
                                <div>
                                    <label htmlFor="url">URL</label>
                                    <div className='flex space-x-4'>
                                        <input
                                            id="url"
                                            name="url"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6"
                                            placeholder="www.goskilling.com"
                                            type="text"
                                            {...register("url", { required: emailChecked && !emailExists, maxLength: 60, validate: validateURL })}
                                        />
                                        {!urlExists && urlChecked ? <XCircleIcon className="text-accent-500 w-8 h-8" /> : urlExists && urlChecked ? <CheckCircleIcon className="text-brand-500 w-8 h-8" /> : ""}
                                    </div>
                                </div>
                            ) : ""}

                            {checkHasNoURL || (!urlExists && urlChecked) ? (
                                <div>
                                    <label htmlFor="name">Comapny Name</label>
                                    <div className='flex space-x-4'>
                                        <input
                                            id="name"
                                            name="name"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6"
                                            placeholder="GoSkilling"
                                            type="text"
                                            {...register("name", { required: checkHasNoURL || (emailChecked && !emailExists && urlChecked && !urlExists), maxLength: 60 })}
                                        />
                                        {!urlExists && urlChecked ? <XCircleIcon className="text-accent-500 w-8 h-8" /> : urlExists && urlChecked ? <CheckCircleIcon className="text-brand-500 w-8 h-8" /> : ""}
                                    </div>

                                </div>
                            ) : ""}

                            {
                                company ?
                                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 my-2">
                                        <div
                                            key={company.urlname}
                                            className="relative flex items-center space-x-3 rounded-sm border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
                                        >
                                            <div className="flex-shrink-0">
                                                <img className="h-10 w-10 rounded-full" src={company.logo} alt="" />
                                            </div>
                                            <div className="min-w-0 flex-1">
                                                <a href="#" className="focus:outline-none">
                                                    <span className="absolute inset-0" aria-hidden="true" />
                                                    <p className="text-sm font-medium text-gray-900">{company.name}</p>
                                                    <p className="truncate text-sm text-gray-500">{company.url}</p>
                                                </a>
                                            </div>
                                        </div>

                                    </div>
                                    : ""
                            }

                            <button type="submit" disabled={!isValid} className="flex w-full mx-auto mt-4 mb-2 items-center justify-center rounded-md disabled:bg-brand-100 disabled:text-gray-800 bg-secondary-100 border-gray-700  px-3 py-2 text-sm font-semibold text-gray-50 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-secondary-200 hover:text-white focus-visible:outline-offset-0">{formReady ? "Submit" : "Check"}</button>


                            <button className="flex w-full mx-auto mt-4 mb-2 items-center justify-center rounded-md bg-white border-gray-700  px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                                onClick={() => { toggleEmailEditor(); resetStates(); }}
                            >Return</button>

                        </form>

                    </div>
                ) : (
                    <div>


                        <ul
                            role="list"
                            className="divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl"
                        >
                            <button
                                onClick={toggleEmailEditor}
                                className="flex w-1/2 mx-auto mt-4 mb-2 items-center justify-center rounded-md bg-white  px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                            >
                                Add Work Email
                            </button>
                            {userExperiences.length > 0 ? userExperiences.map((experience) => (
                                
                                <li key={experience.role + "-"+experience.name + experience.experience_id+Math.random()} className="relative flex flex-col md:flex-row justify-between gap-x-6 gap-y-4 px-4 py-5 hover:bg-gray-50 sm:px-6">
                                <div className="flex min-w-0 gap-x-4">
                                    <img className="h-12 w-12 flex-none rounded-sm bg-gray-50" src={experience.logo} alt="" />
                                    <div className="min-w-0 flex-auto">
                                        <p className="text-xl font-semibold leading-6 text-gray-900 mb-2">{experience.role}</p>
                                        <p className="text-lg leading-6 text-gray-900 flex items-center">
                                            <a href={experience.href}>
                                                {experience.name}
                                            </a>
                                            {experience.is_verified === "verified" ? (
                                                <span className="ml-2 inline-flex items-center gap-x-1.5 rounded-full bg-emerald-100 px-2 py-1 text-xs font-medium text-emerald-700">
                                                    <span className="h-3 w-3 fill-emerald-500" viewBox="0 0 6 6" aria-hidden="true">
                                                        <CheckCircleIcon />
                                                    </span>
                                                    Verified
                                                </span>
                                            ) : experience.is_verified === "no longer active" ? (
                                                <span className="inline-flex items-center gap-x-1.5 rounded-full bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-700">
                                                    No longer active
                                                </span>
                                            ) : (
                                                <span className="ml-2  inline-flex items-center gap-x-1.5 rounded-full bg-red-100 px-2 py-1 text-xs font-medium text-red-700">
                                                    <span className="h-3 w-3 fill-red-500" viewBox="0 0 6 6" aria-hidden="true">
                                                        <XCircleIcon />
                                                    </span>
                                                    Unverified
                                                </span>
                                            )}
                                        </p>
                                        <p className="mt-1 flex text-xs leading-5 text-gray-700">
                                            <a href={`mailto:${experience.email}`} className="relative truncate hover:underline">
                                                {experience.email}
                                            </a>
                                        </p>
                                    </div>
                                </div>
                                <div className="flex flex-col md:flex-row shrink-0 items-center gap-x-4 gap-y-2 md:gap-y-0 z-10">
                                    {experience.allow_email ? (
                                        <button type="button" className="border border-gray-300 px-2 py-1 hover:bg-slate-300 hover:border-gray-500 inline-flex items-center z-10" onClick={() => deactivateEmails(experience.email)}>
                                            <XMarkIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />No Emails
                                        </button>
                                    ) : ""}
                                    {experience.is_active !== 0 ? (
                                        <button type="button" className="border border-gray-300 px-2 py-1 hover:bg-slate-300 hover:border-gray-500 inline-flex items-center z-10" onClick={() => deactivateJob(experience.experience_id)}>
                                            <XMarkIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />Deactivate Job
                                        </button>
                                    ) : ""}
                                    <button type="button" className="border border-gray-300 px-2 py-1 hover:bg-slate-300 hover:border-gray-500 inline-flex items-center z-10" onClick={() => removeSelectedExperience(experience.experience_id)}>
                                        <XMarkIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />Remove
                                    </button>
                                </div>
                            </li>
                            

                            )) : <EmptyStateSimple />}
                        </ul>

                    </div>
                )}
            </div>
            <ToastContainer />
        </>
    )
}
