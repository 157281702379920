import { useEffect } from "react";
import { useSelector,useDispatch } from "react-redux";
import { DataCard, InfoListCard } from "./ui/cards";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { EmptyStateSimple } from "./ui/emptyState";
import { submitInvitation } from "../services/apiCalls";
import { toast, ToastContainer } from 'react-toastify';
import { getCommunities } from "../redux/actions/communityActions";
import 'react-toastify/dist/ReactToastify.css';
import {
  LightBulbIcon
} from '@heroicons/react/24/outline';
import { useDocumentTitle } from "../services/titleChanger";


export default function Dashboard() {
  // Access the Redux store state to get the userProfile object
  const userProfile = useSelector((state) => state.authReducer.userProfile);
  useDocumentTitle('Dashboard | GoSkilling');
  // Extract userSkills array and its length
  const userSkills = userProfile ? userProfile.userSkills : [];
  const workEmails = userProfile ? userProfile.workEmails : [];
  const experiences = userProfile ? userProfile.experiences : [];
  const connections = userProfile ? userProfile.connections : [];
  const numUserSkills = userSkills.length;
  const numWorkEmails = workEmails.length;
  const numExperiences = experiences.length;
  const numConnections = connections.length;

  return (
    <div>
      <div>
        {/* <h3 className="text-base font-semibold leading-6 text-gray-900">Last 30 days</h3> */}
        <dl className="mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-slate-50 h-full shadow md:grid-cols-4 md:divide-x md:divide-y-0">
          <DataCard value={numConnections} name={"Connections"} changeType={"decrease"} previousStat={0} change={0} />
          <DataCard value={numUserSkills} name={"Skills"} changeType={"increase"} previousStat={0} change={0} />
          <DataCard value={numWorkEmails} name={"Work Emails"} changeType={"increase"} previousStat={0} change={0} />
          <DataCard value={numExperiences} name={"Experiences"} changeType={"increase"} previousStat={0} change={0} />
        </dl>
      </div>
      <div className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-1 xl:gap-x-8 my-5">
        <Link target="_blank" className="rounded-md w-full bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" to="https://forms.gle/PzCJQPW5PiUVESWj6">Feedback</Link>
      </div>
      <div className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8 my-5">
        <SkillCard info={userSkills} />
        <ExperienceCard info={experiences} />
        <ConnectionCard info={connections} />
        <OnBoarding/>
        <InviteUser />
        <Communities />

      </div>
    </div>
  );
}



function Communities({info}) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCommunities());
  }, [dispatch])

const communities_list = useSelector((state) => state.communityReducer.communities);
const communities = communities_list.slice(0,3);
return <InfoListCard title="Your Best Skills">
<ul role="list" className="divide-y divide-gray-100">
{communities.length > 0 ? communities.map((community) => (
    <li key={community.company_id} className="flex items-center justify-between gap-x-6 py-5">
      <div className="flex min-w-0 gap-x-4">
        <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={community.logo} alt="" />
        <div className="min-w-0 flex-auto">
          <p className="text-sm font-semibold leading-6 text-gray-900">{community.company_name}</p>
          <p className="mt-1 truncate text-xs leading-5 text-gray-500">{community.totalEmployees + " members, " + community.posts.length + " posts"}</p>
        </div>
      </div>
      <Link
        to={"/communities/"+community.company_id}
        className="rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      >
        Go to
      </Link>
    </li>
  )): <EmptyStateSimple/>}
</ul>
<Link
  to="communities"
  className="flex w-full items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
>
  View all
</Link>
</InfoListCard>

}

function SkillCard({ info }) {
  function countOccurrencesByWorkEmail(skills) {
    const counts = {};
  
    skills.forEach((skill) => {
      const skillId = skill.skill_id;
      if (!counts[skillId]) {
        counts[skillId] = {
          count: 0,
          skill_name: skill.skill_name,
          latest_score: skill.latest_score,
        };
      }
      counts[skillId].count += 1;
    });
  
    return counts;
  }
  function sortAndTransform(counts) {
    return Object.entries(counts)
      .sort((a, b) => b[1].latest_score - a[1].latest_score)
      .map(([skillId, data]) => ({
        skill_id: parseInt(skillId),
        ...data
      }));
  }

  const counts = countOccurrencesByWorkEmail(info);
  const skills_tranformed = sortAndTransform(info);
  const skills = skills_tranformed.slice(0,3);

  return <InfoListCard title="Your Best Skills">
    {skills.length > 0 ? skills.map((skill) => (
      <li key={skill.skill_name + skill.created_at} className="py-2 flex flex-wrap">
        <span className="font-medium mr-2">{skill.skill_name}</span>
        <span className="text-gray-500">{skill.latest_score ? skill.latest_score.slice(0,4) : "--"}</span>
      </li>
    )) : <EmptyStateSimple description="You have not added any skills yet." icon={<LightBulbIcon className='mx-auto h-10 w-10 text-gray-400' />} />}

  </InfoListCard>
}

function ExperienceCard({ info }) {
  function countOccurrencesByWorkEmail(experiences) {
    const counts = {};
  
    experiences.forEach((experience) => {
      const workemailId = experience.workemail_id;
      if (!counts[workemailId]) {
        counts[workemailId] = {
          count: 0,
          name: experience.name,
          role: experience.role,
          email: experience.email
        };
      }
      counts[workemailId].count += 1;
    });
  
    return counts;
  }
  function sortAndTransform(counts) {
    return Object.entries(counts)
      .sort((a, b) => b[1].count - a[1].count)
      .map(([workemailId, data]) => ({
        workemail_id: parseInt(workemailId),
        ...data
      }));
  }

  const counts = countOccurrencesByWorkEmail(info);
  const experiences_transformed = sortAndTransform(counts);
  const experiences = experiences_transformed.slice(0,3);
  return <InfoListCard title="Your Most Important Employers">
    {experiences.length > 0 ? experiences.map((experience) => (
      <li key={experience.workemail_id} className="py-2 flex flex-wrap">
        <span className="font-medium mr-2">
          {experience.name && `${experience.name} `}
          ({experience.count && `${experience.count} `+"positions"})
        </span>
      </li>
    )) : <EmptyStateSimple description="You have not added any work emails yet." icon={<LightBulbIcon className='mx-auto h-10 w-10 text-gray-400' />} />}
  </InfoListCard>
}

function ConnectionCard({ info }) {
  const connections = info.slice(0,3);
  return <InfoListCard title="Your Connections">
    {connections.length > 0 ? connections.map((connection) => (
      <li key={connection.first_name + connection.id} className="py-2 flex flex-wrap">
        <span className="font-medium mr-2">{connection.first_name + " " + connection.last_name}</span>
        {/* <span className="text-gray-500">{skill.scoring}</span> */}
      </li>
    )) : <EmptyStateSimple description="You have no connections yet." icon={<LightBulbIcon className='mx-auto h-10 w-10 text-gray-400' />} />}

  </InfoListCard>
}

const invitationDefaultValues = {
  firstName: "",
  lastName: "",
  invitee_email: ""
}

function InviteUser() {
  const submitEmailInvitation = async (data) => {
    //   const submission = {
    //     skillRatings: reviewData,
    //     invitationid: profileData.invitationId,
    //     email: email,
    //     code: code,
    // };

    const response = await submitInvitation(data);
    toast[response.status](response.message, { theme: "colored" });
    reset( invitationDefaultValues );
  }
  const { register, handleSubmit, setValue,reset, formState: { errors, isValid } } = useForm({defaultValues:invitationDefaultValues});
  return (
    <div className="bg-white rounded-lg p-6 shadow-lg w-full">
      <h2 className="text-secondary-500 text-2xl font-semibold mb-4">
        Invite a Peer for Review!
      </h2>
      <p className="text-gray-700 mb-4">
        Your peers can provide invaluable insights into your skills. Invite them to review you now!
      </p>
      <form onSubmit={handleSubmit(submitEmailInvitation)} >
        <label htmlFor="invitee_email" className="block text-sm font-medium text-gray-600">
          Peer's Email
        </label>
        <input
          id="invitee_email"
          name="invitee_email"
          type="email"
          autoComplete="email"
          className="mt-1 p-2 w-full rounded-md border border-gray-300 focus:border-brand-500 focus:ring focus:ring-brand-200"
          {...register("invitee_email", {
            required: "This is required",
            pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          })}
        />
        <label htmlFor="firstName" className="block text-sm font-medium text-gray-600">
          First Name
        </label>
        <input
          id="firstName"
          name="firstName"
          type="text"

          className="mt-1 p-2 w-full rounded-md border border-gray-300 focus:border-brand-500 focus:ring focus:ring-brand-200"
          {...register("firstName", {
            required: "This is required",
            minLength: {
              value: 1,
            }
          })}
        />
        <label htmlFor="lastName" className="block text-sm font-medium text-gray-600">
          Last Name
        </label>
        <input
          id="lastName"
          name="lastName"
          type="text"

          className="mt-1 p-2 w-full rounded-md border border-gray-300 focus:border-brand-500 focus:ring focus:ring-brand-200"
          {...register("lastName", {
            required: "This is required",
            minLength: {
              value: 1,
            }
          })}
        />
        <button
          type="submit"
          disabled={!isValid}
          className="mt-4 bg-brand-500 text-white p-2 rounded hover:bg-brand-600 focus:outline-none focus:border-brand-700 focus:ring focus:ring-brand-200"
        >
          Send Invitation
        </button>
      </form>
    </div>
  );
}

function OnBoarding() {
  const userProfile = useSelector((state) => state.authReducer.userProfile);
  const userSkills = userProfile ? userProfile.userSkills : [];
  const workEmails = userProfile ? userProfile.workEmails : [];
  const experiences = userProfile ? userProfile.experiences : [];
  const connections = userProfile ? userProfile.connections : [];
  if(userSkills && connections && (workEmails || experiences)) {
    return "";
  }

  return (
    <div className="bg-white rounded-lg p-6 shadow-lg w-full">
      <h2 className="text-secondary-500 text-2xl font-semibold mb-4">
        Complete your profile!
      </h2>
      <p className="text-gray-700 mb-4">
        Get the most out of GoSkilling and complete the steps below:
      </p>
      <ol>
        <li className={userSkills ? "block text-md line-through font-medium text-gray-800 py-2" : "block text-md font-medium text-gray-800 py-2"}>
          Add Skills
        </li>
        <li className={(workEmails || experiences) ? "block text-md line-through font-medium text-gray-800 py-2" : "block text-md font-medium text-gray-800 py-2"}>
          Add Work Emails / Experiences
        </li>
        <li className={connections ? "block text-md line-through font-medium text-gray-800 py-2" : "block text-md font-medium text-gray-800 py-2"}>
          Add contacts, ask peers to assess your skills
        </li>
      </ol>
    </div>
  );
}