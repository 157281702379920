import React, { useState, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { getPortfolioPostAsOwner, updatePortfolioArticle, deletePortfolioArticle, postPortfolioArticle } from '../../services/apiCalls';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import Select from 'react-select';
import 'react-quill/dist/quill.snow.css';
export const Structure = ({ children }) => {
    return (
        <div className="py-4">
            {children}
        </div>
    );
};
export default function PortfolioForm() {
    const [blocks, setBlocks] = useState([]);
    const [posts, setPosts] = useState([]);
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [activeBlockId, setActiveBlockId] = useState(null);
    const [postId, setPostId] = useState();
    const [selectedSkills, setSelectedSkills] = useState([]);
    const [availableSkills, setAvailableSkills] = useState([]);
    const { articleid } = useParams();
    const isEditing = articleid !== undefined;
    const generateUniqueId = () => `id_${new Date().getTime()}_${Math.random().toString(36).substr(2, 9)}`;
    const handleActivateBlock = (id) => {
        setActiveBlockId(id); // Set the activeBlockId to the id of the block that was clicked
    };
    const navigate = useNavigate();
    const appSkills = useSelector((state) => state.skillReducer.skills);
    const transformedSkillsArray = appSkills.map(skill => ({
        value: skill.id,  // or skill.urlname, whatever you prefer to use as the value
        label: skill.name,
        category: skill.category
    }));
    const userProfile = useSelector((state) => state.authReducer.userProfile);

    const handleChange = (selectedOptions) => {
        //console.log(selectedOptions)
        if (selectedOptions.length > 5) {
            // If more than 5, alert the user (or handle as preferred) and do not update the state
            //console.log("You can only select 5 skills.");
            return; // Prevent updating the state
        }
        setSelectedSkills(selectedOptions || []);
    };


    useEffect(() => {
        const fetchArticle = async () => {
            if (isEditing) {
                setIsLoading(true);
                setError(null);
                try {
                    const fetchedArticle = await getPortfolioPostAsOwner(articleid);
                    //console.log(fetchedArticle[0])
                    setPosts(fetchedArticle[0].content);
                    setBlocks(fetchedArticle[0].content);
                    setValue('caption', fetchedArticle[0].title);
                    setSelectedSkills(fetchedArticle[0].skills || [])
                    setPostId(articleid);
                    setIsLoading(false);
                    toast.success(fetchedArticle.message, { theme: "colored" });
                } catch (error) {
                    setError(error.message || 'An error occurred');
                    //console.error('Failed to fetch article', error);
                    // Handle error (e.g., navigate to an error page or display a message)
                } finally {
                    setIsLoading(false);
                }
            }
        };

        fetchArticle();
    }, [postId, isEditing]);

    const getDefaultContent = (type) => {
        switch (type) {
            case 'text':
                return { text: 'New text block' };
            case 'image':
                return { src: '', alt: 'New image', caption: '' };
            default:
                return {};
        }
    };


    const addBlock = (type) => {
        const newBlock = { id: generateUniqueId(), type: type, content: getDefaultContent(type) };
        setBlocks([...blocks, newBlock]);
    };

    const updateBlock = (id, newContent) => {
        setBlocks(blocks.map(block => block.id === id ? { ...block, content: newContent } : block));
    };

    const deleteBlock = (id) => {
        setBlocks(blocks.filter(block => block.id !== id));
    };

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {
        if (postId) {
            setPosts(blocks);
            const skills = {};
            const results = await updatePortfolioArticle(data.caption, blocks, selectedSkills, postId);
            toast.success(results.message, { theme: "colored" });
            if (results && results.status === "success") {
                navigate("/portfolio/user/"+userProfile.urlname);
            }
            //console.log(results);
        } else {
            setPosts(blocks);
            //console.log('Submitting:', blocks);
            //console.log(data.caption)
            //console.log(typeof blocks)
            const skills = {};
            const results = await postPortfolioArticle(data.caption, blocks, selectedSkills);
            setPostId(results.data);
            toast.success(results.message, { theme: "colored" });
            if (results && results.status === "success") {
                navigate("/portfolio/user/"+userProfile.urlname);
            }
            //console.log(results)
        }
    };

    const deletePost = async () => {
        const results = await deletePortfolioArticle(postId);
        toast.success(results.message, { theme: "colored" });
        //console.log(results);
    };

    const user_url = useSelector((state) => state.authReducer.userProfile?.urlname);

    return (
        <div className="bg-gray-100 py-6 sm:py-8 lg:py-12">
            <div className="max-w-screen-2xl px-4 md:px-8 mx-auto">
                <form onSubmit={handleSubmit(onSubmit)} className="bg-white shadow rounded-lg p-4 sm:p-6 lg:p-8">
                <p><Link to={"/portfolio/user/"+user_url} className="text-base font-semibold leading-7 text-brand-500 font-underline">Go Back</Link></p>

                    <h2 className="text-2xl lg:text-3xl font-bold mb-4">{isEditing ? "Editing Portfolio Entry" : "New Portfolio Entry"}</h2>

                    <div className="grid grid-cols-1 gap-6 mt-4">
                        <div>
                            <label htmlFor="caption" className="inline-block text-gray-800 text-sm sm:text-base mb-2">Title</label>
                            <input
                                id="caption"
                                name="caption"
                                type="text"
                                autoComplete="caption"
                                className="w-full bg-gray-50 text-gray-800 border focus:border-indigo-500 rounded-lg shadow-sm h-12 px-3"
                                placeholder="Title of your article"
                                {...register("caption", {
                                    required: "Title is required",
                                })}
                            />
                            {errors.caption && <p className="mt-1 text-red-500 text-xs">{errors.caption.message}</p>}
                        </div>
                        {/* Include your block components here, styled as cards */}
                        {blocks.map(block => {
                            const isActive = block.id === activeBlockId;
                            switch (block.type) {
                                case 'text':
                                    return <TextBlock className="py-6 my-6" key={block.id} block={block} isActive={isActive} activateBlock={() => handleActivateBlock(block.id)} updateBlock={updateBlock} deleteBlock={() => deleteBlock(block.id)} />;
                                case 'image':
                                    return <ImageBlock className="py-6 my-6" key={block.id} block={block} isActive={isActive} activateBlock={() => handleActivateBlock(block.id)} updateBlock={updateBlock} deleteBlock={() => deleteBlock(block.id)} />;
                                default:
                                    return null;
                            }
                        })}
                        {/* Add Text/Image Block Buttons */}
                        <div className="flex gap-4">
                            <button type="button" className='py-2 px-4 bg-indigo-600 hover:bg-indigo-700 text-white text-sm font-medium rounded-lg shadow-md' onClick={() => addBlock('text')}>Add Text Block</button>
                            <button type="button" className='py-2 px-4 bg-indigo-600 hover:bg-indigo-700 text-white text-sm font-medium rounded-lg shadow-md' onClick={() => addBlock('image')}>Add Image Block</button>
                        </div>

                        {/* Skills Selector */}
                        <div className="border-t pt-4">
                            <label className="inline-block text-gray-800 text-sm sm:text-base mb-2">Select Skills</label>
                            {appSkills && appSkills.length > 0 && (
                                <Select
                                    name="skills"
                                    options={transformedSkillsArray}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={handleChange}
                                    value={selectedSkills}
                                    isMulti={true}
                                    closeMenuOnSelect={false}
                                    isSearchable={true}
                                    placeholder="Select up to 5 skills..."
                                />
                            )}
                        </div>

                    </div>

                    {/* Action Buttons */}
                    <div className="flex justify-end gap-4 mt-6">
                        <button type="button" className='py-2 px-6 bg-red-500 hover:bg-red-600 text-white text-sm font-medium rounded-lg' onClick={() => deletePost()}>Delete</button>
                        <button type="submit" className='py-2 px-6 bg-green-500 hover:bg-green-600 text-white text-sm font-medium rounded-lg'>{isEditing ? "Update" : "Save"}</button>
                    </div>
                </form>
            </div>
        </div>
    );
}
const modules = {
    toolbar: [
        [{ 'header': [1, 2, false] }], // Custom dropdown with set headings
        ['bold', 'italic', 'underline', 'strike'], // Toggled buttons
        ['blockquote', 'code-block'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }], // Subscript/superscript
        [{ 'indent': '-1' }, { 'indent': '+1' }], // Outdent/Indent
        [{ 'direction': 'rtl' }], // Text direction
        [{ 'size': ['small', false, 'large', 'huge'] }], // Custom dropdown
        ['link', 'image', 'video'], // Links and media
        [{ 'color': [] }, { 'background': [] }], // Dropdown with defaults from theme
        [{ 'font': [] }], // Font family
        [{ 'align': [] }], // Align options
        ['clean'] // Remove formatting button
    ],
};
function TextBlock({ block, updateBlock, deleteBlock, isActive, activateBlock }) {
    // Create a ref for the ReactQuill component
    const quillRef = useRef(null);

    useEffect(() => {
        if (isActive && quillRef.current) {
            // Focus the ReactQuill editor
            quillRef.current.focus();
        }
    }, [isActive]);

    const handleChange = (content) => {
        updateBlock(block.id, { ...block.content, text: content });
    };

    return (
        <div onClick={activateBlock}>
            <div className="flex items-start space-x-4  my-3">
                <div className="min-w-0 flex-1">
                    {isActive ? (
                        <ReactQuill
                            theme="snow"
                            value={block.content.text || ''}
                            onChange={handleChange}
                            modules={modules}
                            ref={(element) => {
                                if (element !== null) {
                                    quillRef.current = element.getEditor(); // Accessing the Quill instance directly
                                }
                            }}
                        />
                    ) : (
                        <div dangerouslySetInnerHTML={{ __html: block.content.text || '' }} />
                    )}
                </div>
                <div className="flex-shrink-0">
                    <button className="border py-2 px-3 w-10 h-10 mx-auto text-red-500 hover:bg-red-200 hover:text-red-700" onClick={deleteBlock}><XMarkIcon /></button>
                </div>
            </div>
        </div>
    );
}

function ImageBlock({ block, updateBlock, deleteBlock, isActive, activateBlock }) {
    const srcInputRef = useRef(null); // Ref for the image source input

    useEffect(() => {
        // When the block becomes active, focus on the src input field
        if (isActive && srcInputRef.current) {
            srcInputRef.current.focus();
        }
    }, [isActive]);

    const handleSrcChange = (event) => {
        updateBlock(block.id, { ...block.content, src: event.target.value });
    };

    const handleAltChange = (event) => {
        updateBlock(block.id, { ...block.content, alt: event.target.value });
    };

    return (
        <div className="flex items-start space-x-4 my-3" onClick={activateBlock}>
            <div className="min-w-0 flex-1">
                {
                    isActive
                        ? <div><input
                            type="text"
                            placeholder="Image URL"
                            value={block.content.src}
                            onChange={handleSrcChange}
                            ref={srcInputRef} // Assign the ref to the input
                        />
                            <input
                                type="text"
                                placeholder="Alt text"
                                value={block.content.alt}
                                onChange={handleAltChange}
                            /></div>
                        : <img src={block.content.src} alt={block.content.alt} style={{ maxWidth: '100%' }} />
                }

            </div>
            <div className="flex-shrink-0">
                <button className="border py-2 px-3 w-10 h-10 mx-auto text-red-500 hover:bg-red-200 hover:text-red-700" onClick={(e) => {
                    e.stopPropagation(); // Prevent activateBlock when clicking delete
                    deleteBlock();
                }}><XMarkIcon /></button>
            </div>
        </div>
    );
}
