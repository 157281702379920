import React, { useReducer, createContext } from 'react';

export const UserXContext = createContext(null);
export const UserDispatchContext = createContext(null);

export const UserProvider = ({ children }) => {
    const [state, dispatch] = useReducer(userReducer, { /* initial state */ });

    return (
        <UserXContext.Provider value={state}>
            <UserDispatchContext.Provider value={dispatch}>
                {children}
            </UserDispatchContext.Provider>
        </UserXContext.Provider>
    );
};

export const userReducer = (state, action) => {
    switch (action.type) {
        case 'SKILL_FETCH_SUCCESS':
            // handle skill fetch success
            return { ...state, skills: action.payload };
        case 'SKILL_FETCH_ERROR':
            // handle skill fetch error
            return { ...state, error: action.payload };
        case 'AUTH_ERROR':
            // handle auth error
            return { ...state, authError: action.payload };
        // Add other cases as needed
        default:
            return state;
    }
};
