import { useState } from "react"
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { submitCodeAndEmail, submitUserReview } from "../services/apiCalls";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDocumentTitle } from "../services/titleChanger";
export default function Review() {
    useDocumentTitle('Review User Skills | GoSkilling');
    const [verified, setVerified] = useState(false);
    //const [reviewable,setReviewable] = useState(false);
    const [profileData, setProfileData] = useState(false);
    const [code, setCode] = useState(false);
    const [email, setEmail] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [submissionStatus, setSubmissionStatus] = useState(false);
    const { register, handleSubmit, setValue } = useForm();

    const { register: registerReview, handleSubmit: registerHandleSubmit, setValue: registerSetValue } = useForm();


    const codeSubmitter = async (code) => {
        const response = await submitCodeAndEmail(code);
        if (response.status === "success") {
            setVerified(true);
            toast.success("Access granted.", { theme: "colored" });
            setProfileData(response.data);
            setCode(code.code);
            setEmail(code.email);
        } else {
            toast.error("Code and/or email incorrect.", { theme: "colored" });
        }
    }

    const submitReview = async (reviewData) => {
        setSubmitted(true);
        const submission = {
            skillRatings: reviewData,
            invitationid: profileData.invitationId,
            email: email,
            code: code,
        };

        const response = await submitUserReview(submission);
        if (response.status == "error") {
            toast.error(response.message, { theme: "colored" });
            setSubmissionStatus(response.status);
        } else if (response.status == "success") {
            toast.success(response.message, { theme: "colored" });
            setSubmissionStatus(response.status);
        } else {
            toast.error("Something went wrong.", { theme: "colored" });
            setSubmissionStatus("error");
        }
    }

    if (!verified) {
        return <div className="relative flex flex-col gap-y-6 p-4 bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
            <form onSubmit={handleSubmit(codeSubmitter)}>
                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm lg:col-span-7 lg:mt-0">
                    <h1 as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Review Panel
                    </h1>
                    <p className="text-sm text-gray-500">
                        Please enter your invitation code and email to access your scorecard and submit your review.
                    </p>

                    <div className="sm:col-span-4">
                        <label htmlFor={"code"} className="block text-sm font-medium leading-6 text-gray-700 mb-2">
                            Code
                        </label>
                        <input
                            type="text"
                            id="code"
                            name="code"
                            className="w-full"
                            {...register("code", {
                                required: "This is required."
                            })}
                        />
                    </div>
                    <div className="sm:col-span-4">
                        <label htmlFor={"email"} className="block text-sm font-medium leading-6 text-gray-700 mb-2">
                            Email
                        </label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            className="w-full"
                            {...register("email", {
                                required: "This is required.",
                            })}
                        />
                    </div>
                    <div className="sm:col-span-3">
                        <button type="submit" className="flex w-full mx-auto mt-4 mb-2 items-center justify-center rounded-md disabled:bg-brand-100 disabled:text-gray-800 bg-secondary-100 border-gray-700  px-3 py-2 text-sm font-semibold text-gray-50 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-secondary-200 hover:text-white focus-visible:outline-offset-0">Submit</button>
                    </div>
                    <p className="mt-10 text-center text-sm text-gray-500">
                        <Link to="/login" className="font-semibold leading-6 text-secondary-600 hover:text-secondary-500">
                            Login
                        </Link>
                        {' '}or{' '}
                        <Link to="/register" className="font-semibold leading-6 text-secondary-600 hover:text-secondary-500">
                            Register
                        </Link>
                    </p>
                </div>
            </form>
        </div>
    }

    return <div className="bg-white">
        <div className="mx-auto max-w-7xl px-6 sm:py-8 lg:px-8 ">
            {!submitted
                ?
                <div className="lg:grid lg:grid-cols-12 lg:gap-8">
                    <div className="lg:col-span-5">
                        Scorecard
                        <div className="min-w-0 flex-1">
                            <h2 className="text-2xl font-bold leading-7 text-accent-500 sm:truncate sm:text-3xl sm:tracking-tight">
                                {profileData && profileData.inviter ? profileData.inviter[0].first_name + " " + profileData.inviter[0].last_name : ""}
                            </h2>
                        </div>

                    </div>
                    <div className="mt-10 lg:col-span-7 lg:mt-0">
                        {profileData
                            ? !profileData.hasReviewedRecently
                                ?
                                <>




                                    <form className="space-y-6" onSubmit={registerHandleSubmit(submitReview)}>
                                        {profileData && profileData.skills ? profileData.skills.map(skill => (
                                            <div className="sm:col-span-3" key={skill.name}>

                                                <label htmlFor={skill.name} className="block text-sm font-medium leading-6 text-gray-700 mb-2">
                                                    {skill.name} | {skill.category}
                                                </label>
                                                <select
                                                    id={skill.name}
                                                    name={skill.name}
                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6"
                                                    {...registerReview(String(skill.id))}
                                                >
                                                    <option value="">Please select an option</option>
                                                    <option value="1">Very Bad</option>
                                                    <option value="2">Bad</option>
                                                    <option value="3">Average</option>
                                                    <option value="4">Good</option>
                                                    <option value="5">Very Good</option>
                                                    <option value="0">Skip</option>
                                                </select>
                                            </div>
                                        ))
                                            : "Loading..."}
                                        <button type="submit" className="flex w-full mx-auto mt-4 mb-2 items-center justify-center rounded-md disabled:bg-brand-100 disabled:text-gray-800 bg-secondary-100 border-gray-700  px-3 py-2 text-sm font-semibold text-gray-50 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-secondary-200 hover:text-white focus-visible:outline-offset-0">Submit</button>

                                    </form>


                                </>
                                : "You have recently reviewed this user. Reviews are possible once a month."
                            : "Loading..."

                        }
                        {
                            profileData && profileData.isRegistered && !profileData.isRegistered
                                ? "Sign up"
                                : ""
                        }

                    </div>
                </div>
                : <div className="text-center">
                    {
                        submissionStatus == "error"
                            ? <h2 className="text-2xl font-bold leading-7 text-accent-500 sm:truncate sm:text-3xl sm:tracking-tight">
                                Submission failed!
                            </h2>
                            : submissionStatus == "success"
                                ? <h2 className="text-2xl font-bold leading-7 text-brand-500 sm:truncate sm:text-3xl sm:tracking-tight">
                                    Submission successful!
                                </h2>
                                : <h2 className="text-2xl font-bold leading-7 text-slate-500 sm:truncate sm:text-3xl sm:tracking-tight">
                                    Loading...
                                </h2>
                    }

                </div>
            }
        </div>
        <ToastContainer />
    </div>
}