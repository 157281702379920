import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const ABTestingComponent = ({ ComponentA, ComponentB }) => {
    const category = useCategory();

    if (category === 'A') {
        return <ComponentA />;
    } else {
        return <ComponentB />;
    }
};

const useCategory = () => {
    const [category, setCategory] = useState(null);
    const id = useSelector((state) => state.authReducer.userProfile.id);
    useEffect(() => {
        if (!id) {
            return;
        }

        if (id % 2 === 0) {
            setCategory('A');
        } else {
            setCategory('B');
        }

    }, []);

    return category;
};

export default ABTestingComponent;
