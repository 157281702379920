// reducer.js
const initialState = {
    communities: [],
    error: null,
};

export default function communityReducer(state = initialState, action) {
    switch (action.type) {
        case 'COMMUNITIES_SUCCESS':
            return {
                ...state,
                communities: action.payload.data,
            };
        case 'COMMUNITIES_ERROR':
            return {
                ...state,
                error: action.payload,
            };
        case 'COMMUNITY_SUCCESS':
            //console.log(action.payload)
            return {
                ...state,
                communities: state.communities.map((community) => {
                    if (community.company_id == action.payload.companyId) {
                        //console.log("data")
                        return {
                            ...community,
                            postEntries: action.payload.data // Assuming 'data' is an array of posts
                        };
                    }
                    return community;
                })
            };

        case 'ADD_POST':
            const { companyId, newPost } = action.payload;
            return {
                ...state,
                communities: state.communities.map(community =>
                    community.company_id === companyId
                        ? { ...community, posts: [...community.posts, newPost] }
                        : community
                ),
            };
        case 'REMOVE_SPACE':
            const newExperiences = state.userProfile.experiences.filter(experience => experience.experience_id !== action.payload);

            return {
                ...state,
                userProfile: {
                    ...state.userProfile,
                    experiences: state.userProfile.experiences.filter(experience => experience.experience_id !== action.payload),
                },
            };

        default:
            return state;
    }
}