import apiCall from "../utilities/api";
const sourceURL = "server";
let root;
if (sourceURL == "localhost") {
  root = "http://localhost:3001/v3";
} else {
  root = "https://goskilling-node-wju6y.ondigitalocean.app/v3";
}
let root4_base = "https://goskilling-node-wju6y.ondigitalocean.app/v4";
let root4_loc = "http://localhost:3001/v4";
let root3_loc = "http://localhost:3001/v3";

export const checkEmailEnding = async (emailEnding) => {
  const token = localStorage.getItem('auth_token');
  const url = root + '/work/workemail';
  const data = { emailEnding };

  try {
    const response = await apiCall('POST', url, data, {
      'Authorization': `Bearer ${token}`,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const checkCompanyURL = async (companyURL) => {
  const token = localStorage.getItem('auth_token');
  const url = root + '/work/companyurl';
  const data = { companyURL };

  try {
    const response = await apiCall('POST', url, data, {
      'Authorization': `Bearer ${token}`,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const submitCodeAndEmail = async (code, email) => {
  const token = localStorage.getItem('auth_token');
  const url = root + '/reviews/reviewdata';
  const data = { code, email };

  try {
    const response = await apiCall('POST', url, data, {
      'Authorization': `Bearer ${token}`,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const submitUserReview = async (data) => {
  const token = localStorage.getItem('auth_token');
  const url = root + '/reviews/review';


  try {
    const response = await apiCall('POST', url, data, {
      'Authorization': `Bearer ${token}`,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const checkQuickEmail = async (email, reviewee) => {
  const url = root + '/auth/quickauth';
  const data = { email: email, revieweeUsername: reviewee };

  try {
    const response = await apiCall('POST', url, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const submitAssessment = async (data) => {
  const token = localStorage.getItem('auth_token');
  const url = root + '/reviews/assessment';


  try {
    const response = await apiCall('POST', url, data, {
      'Authorization': `Bearer ${token}`,
    });
    return response;
  } catch (error) {
    //console.log(error);
  }
};


export const submitInvitation = async (data) => {
  const token = localStorage.getItem('auth_token');
  const url = root + '/invitations/invitations';


  try {
    const response = await apiCall('POST', url, data, {
      'Authorization': `Bearer ${token}`,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const updatePassword = async (data) => {
  const token = localStorage.getItem('auth_token');
  const url = root + '/user/users/me/password';
  //console.log(data)

  try {
    const response = await apiCall('PUT', url, data, {
      'Authorization': `Bearer ${token}`,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const submitNewEmail = async (data) => {
  const token = localStorage.getItem('auth_token');
  const url = root + '/user/users/me/email';
  //console.log(data)

  try {
    const response = await apiCall('POST', url, data, {
      'Authorization': `Bearer ${token}`,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const confirmNewEmail = async (data) => {
  const token = localStorage.getItem('auth_token');
  const url = root + '/user/users/me/confirmemail';
  //console.log(data)

  try {
    const response = await apiCall('PUT', url, data, {
      'Authorization': `Bearer ${token}`,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const confirmAccount = async (data) => {
  const token = localStorage.getItem('auth_token');
  const url = root + '/user/users/me/confirmaccount';
  //console.log(data)

  try {
    const response = await apiCall('PUT', url, data, {
      'Authorization': `Bearer ${token}`,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const verifyWorkEmail = async (data) => {
  const token = localStorage.getItem('auth_token');
  const url = root + '/user/users/me/verifyemail';
  //console.log(data)

  try {
    const response = await apiCall('PUT', url, data, {
      'Authorization': `Bearer ${token}`,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const verifyReview = async (data) => {
  const token = localStorage.getItem('auth_token');
  const url = root + '/reviews/verify';
  //console.log(data)

  try {
    const response = await apiCall('POST', url, data, {
      'Authorization': `Bearer ${token}`,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const resendVerification = async (data) => {
  const token = localStorage.getItem('auth_token');
  const url = root + '/user/users/me/resendemail';
  //console.log(data)

  try {
    const response = await apiCall('POST', url, { "experienceID": data }, {
      'Authorization': `Bearer ${token}`,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const searchBarItems = async (data) => {
  const token = localStorage.getItem('auth_token');
  const url = root + '/data/search';
  //console.log(data)

  try {
    const response = await apiCall('POST', url, { searchTerm: data }, {
      'Authorization': `Bearer ${token}`,
    });
    return response;
  } catch (error) {
    throw error;
  }
};


export const fetchJobs = async (data) => {
  const token = localStorage.getItem('auth_token');
  const url = root + '/jobs/fetchjobs';
  console.log(data)

  try {
    const response = await apiCall('GET', url, null, {
      'Authorization': `Bearer ${token}`,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const fetchJobById = async (data) => {
  const token = localStorage.getItem('auth_token');
  const url = root + '/jobs/gh/' + data;
  //console.log(data)

  try {
    const response = await apiCall('GET', url, {
      'Authorization': `Bearer ${token}`,
    });
    return response.message;
  } catch (error) {
    throw error;
  }
};

export const transferReview = async (data, profileData, router) => {
  const token = localStorage.getItem('auth_token');
  const url = root4_loc + '/gpt/gpt_form';
  //console.log(data)

  try {
    const response = await apiCall('POST', url, { "formData": data, "receiver": profileData, "router": router }, {
      'Authorization': `Bearer ${token}`,
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export const getPortfolioPostsByUser = async (username) => {
  const url = root + '/portfolio/posts/'+username;

  try {
    const response = await apiCall('GET', url, []);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const getPortfolioEntry = async (id) => {
  const url = root + '/portfolio/post/'+id;

  try {
    const response = await apiCall('GET', url, []);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const getPortfolioPostAsOwner = async (id) => {
  const token = localStorage.getItem('auth_token');
  const url = root + '/portfolio/edit/'+id;
  try {
    const response = await apiCall('GET', url, [],{
      'Authorization': `Bearer ${token}`,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const postPortfolioArticle = async (title,content,skills) => {
  const token = localStorage.getItem('auth_token');
  const url = root + '/portfolio/post';
  //console.log(data)

  try {
    const response = await apiCall('POST', url, { "content": content, "title": title, "skills": skills }, {
      'Authorization': `Bearer ${token}`,
    });
    return response;
  } catch (error) {
    throw error;
  }  
}

export const updatePortfolioArticle = async (title,content,skills, id) => {
  const token = localStorage.getItem('auth_token');
  const url = root + '/portfolio/post';
  //console.log(data)

  try {
    const response = await apiCall('PUT', url, { "content": content, "title": title, "skills": skills, "id": id }, {
      'Authorization': `Bearer ${token}`,
    });
    return response;
  } catch (error) {
    throw error;
  }  
}

export const deletePortfolioArticle = async (id) => {
  const token = localStorage.getItem('auth_token');
  const url = root + '/portfolio/post';
  //console.log(data)

  try {
    const response = await apiCall('DELETE', url, { "id": id }, {
      'Authorization': `Bearer ${token}`,
    });
    return response;
  } catch (error) {
    throw error;
  }  
}