import 'react-toastify/dist/ReactToastify.css';

// actions.js
import apiCall from "../../utilities/api";
const sourceURL = "server";
let rootURL;
if(sourceURL=="localhost"){
    rootURL = "http://localhost:3001/v3/";
} else {
    rootURL = "https://goskilling-node-wju6y.ondigitalocean.app/v3/";
}


export const skillFetchSuccess = (data) => {
    return {
        type: 'SKILL_FETCH_SUCCESS',
        payload: data,
    };
};

export const skillFetchError = (error) => {
    return {
        type: 'SKILL_FETCH_ERROR',
        payload: error,
    };
};

export const getSkills = () => {
    const token = localStorage.getItem('auth_token');

    return async (dispatch, getState) => {
        try {
            const data = await apiCall('GET', rootURL+'skills/skills',null, {
                'Authorization': `Bearer ${token}`,
            });
            dispatch(skillFetchSuccess(data));
            const state = getState();
            const successMessage = data.message;

        } catch (error) {
            dispatch(skillFetchError(error.response ? error.response.data.message : 'Unknown error'));
            const state = getState();
            const errorMessage = state.skillReducer.error;
            //console.log(errorMessage)
        }
    };
};

export const authError = (error) => {
    localStorage.removeItem('auth_token');
    return {
        type: 'AUTH_ERROR',
        payload: error,
    };
};

