import { useEffect } from "react"
import { logout } from "../redux/actions/loginActions";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";


export default function Logout() {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    useEffect(() => {
        // Simulated loading data, replace with actual API call
        dispatch(logout());
        navigate("/");
      }, []);

    return <>Logging out.</>
}