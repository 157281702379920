import React from 'react';
import './components.css';
import logo from './../assets/images/brand/goskilling_general.png'
const SplashScreen = ({ hasError }) => {
  return (
    <div className="splash-screen w-full">
      <img src={logo} alt="GoSkilling logo" />
      {hasError ? (
        <p>There was a problem connecting. Please try again.</p>
      ) : (
        <p className='text-2xl mt-5 font-bold'>Loading...</p>
      )}
    </div>
  );
};

export default SplashScreen;