import { InboxIcon, TrashIcon, UsersIcon } from '@heroicons/react/24/outline';
import { Diversity3Rounded, GroupAdd, Insights } from '@mui/icons-material';
import SEO from '../utilities/SEO';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";

export default function HomepageV2() {
    return <>
        <SEO />
        <HeroV2 />
        <Jobs />
        <Features />
        <CTA Heading1={`Elevate Your Talent Assessment.`} Heading2={'Empower Your Hiring with AI-Driven Precision.'} Lead={'Ready to transform how you assess and identify talent? Sign up with GoSkilling now and leverage the power of AI for accurate, unbiased skill evaluations. Make your next hiring decision the best one yet!'} RefURL={'/about'} />
        <FAQ />
    </>
}

function HeroV2() {

    return <div className="relative isolate px-6 pt-4 lg:px-8">
        <div
            className="absolute inset-x-0 -top-10 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
            aria-hidden="true"
        >
        </div>
        <div className="mx-auto max-w-2xl py-32 sm:py-28 lg:py-26">
            {/* <div className="hidden sm:mb-8 sm:flex sm:justify-center">
                <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                    Announcing our next round of funding.{' '}
                    <a href="#" className="font-semibold text-accent-600">
                        <span className="absolute inset-0" aria-hidden="true" />
                        Read more <span aria-hidden="true">&rarr;</span>
                    </a>
                </div>
            </div> */}
            <div className="text-center">
                <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                    Supercharge Your Career with Data-Driven Assessments
                </h1>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                    Experience career growth like never before. Our data-driven assessments and objective criteria open doors to endless opportunities.
                </p>
                <div className="mt-10 flex items-center justify-center gap-x-6">
                    <Link
                        to="/register"
                        className="rounded-md bg-brand-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-brand-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600"
                    >
                        Get started
                    </Link>
                    <Link to="about" className="text-sm font-semibold leading-6 text-gray-900">
                        Learn more <span aria-hidden="true">→</span>
                    </Link>
                </div>
            </div>
        </div>

    </div>
}

const posts = [


    {
        id: 1,
        title: 'Boost your conversion rate',
        href: '#',
        description:
            'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
        date: 'Mar 16, 2020',
        datetime: '2020-03-16',
        category: { title: 'Marketing', href: '#' },
        author: {
            name: 'Michael Foster',
            role: 'Co-Founder / CTO',
            href: '#',
            imageUrl:
                'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
    },
    // More posts...
]

function Jobs() {
    const jobs = useSelector((state) => state.authReducer.jobs);

    const jobsLength = jobs.length;
    let randomIndexes = [];
    let randomJobs = []
    const limit = 3;
    let y = 0;
    while (y < limit) {
        var value = Math.floor(Math.random() * jobsLength);
        if (!randomIndexes.includes(value)) {
            randomIndexes.push(value);
            randomJobs.push(jobs[value]);
            y++;
        }
    }

    return (
        <div className="bg-secondary-500 text-white py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:mx-0">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-50 sm:text-4xl">Jobs with Score qualifications</h2>
                    <p className="mt-2 text-lg leading-8 text-gray-200">
                        We have evaluated many jobs and companies to provide guidance on minimum qualifications based on scores. Know instantly if you are qualified or not.
                    </p>
                </div>
                <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                    {randomJobs.map((opening) => (
                        <div key={opening.id} className="mb-4 py-2">
                            <h2 className="text-3xl font-bold tracking-tight text-gray-100 sm:text-4xl">
                                <Link to={`/jobs/${opening.id}`}>{opening.dta.title}</Link>
                            </h2>
                            <h2 className="text-2xl tracking-tight text-gray-300 sm:text-2xl">
                                {opening.dta.company}
                            </h2>
                            <h2 className="text-lg tracking-tight text-gray-400 sm:text-xl">
                                {opening.dta.location.name}
                            </h2>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

const features = [
    {
        name: 'Accurate Talent Insights',
        description:
            `GoSkilling's AI-driven platform provides precise skill ratings, offering a deeper understanding of candidate capabilities. Enhance your recruitment with data-backed talent insights.`,
        href: 'information/accurateinsights',
        icon: Insights,
    },
    {
        name: 'Objective Assessments from Peers',
        description:
            `Utilize the power of quantitative peer reviews for a more objective and comprehensive view of candidates’ skills. GoSkilling’s innovative approach brings fairness and accuracy to the forefront of talent assessment.`,
        href: 'information/peerassessments',
        icon: GroupAdd,
    },
    {
        name: 'Promote Diversity in Hiring',
        description:
            `GoSkilling is committed to fostering diversity and inclusion in the workplace. Our platform helps you identify diverse talent, ensuring your hiring practices align with DEI goals.`,
        href: 'information/diversity',
        icon: Diversity3Rounded,
    },
]

function Features() {
    return (
        <div className="bg-gray-900 py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:mx-0">
                    <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Unleash the Potential of Talent</h2>
                    <p className="mt-6 text-lg leading-8 text-gray-300">
                        Explore GoSkilling’s cutting-edge features: AI-powered skill assessments, quantitative peer reviews, and a robust commitment to DEI. Elevate your recruitment with precision and fairness.
                    </p>
                </div>
                <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
                    <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
                        {features.map((feature) => (
                            <div key={feature.name} className="flex flex-col">
                                <dt className="text-base font-semibold leading-7 text-white">
                                    <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-brand-500">
                                        <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </div>
                                    {feature.name}
                                </dt>
                                <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-300">
                                    <p className="flex-auto">{feature.description}</p>
                                    <p className="mt-6">
                                        <Link to={feature.href} className="text-sm font-semibold leading-6 text-accent-400">
                                            Learn more <span aria-hidden="true">→</span>
                                        </Link>
                                    </p>
                                </dd>
                            </div>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    )
}

const faqs = [
    {
        question: 'How does GoSkilling help me in my career?',
        answer:
            `GoSkilling aids your career by providing a clear, objective assessment of your skills through quantitative peer reviews. This approach offers a comprehensive view of your abilities, beyond what traditional resumes or subjective evaluations can provide. With GoSkilling, you gain a valuable tool to showcase your strengths to potential employers, enhancing your visibility and employability in the job market.`,
    },
    {
        question: 'How do you guarantee unbiased scores?',
        answer:
            `We ensure unbiased scores by employing a sophisticated AI algorithm that analyzes peer reviews without human intervention or preconceived notions. This algorithm is designed to evaluate skills based on data-driven criteria, removing the subjectivity typically associated with manual assessments. Additionally, our continuous algorithm updates and refinements help in maintaining the integrity and fairness of the scoring process.`,
    },
    {
        question: `What's the difference to coding challenges and annual reviews in my company?`,
        answer:
            `GoSkilling's approach differs significantly from coding challenges and annual reviews. While coding challenges test specific technical skills under timed conditions, GoSkilling provides a broader, more holistic view of your skills through peer evaluations. Unlike annual reviews, which are often subjective and influenced by internal company dynamics, GoSkilling offers a more objective and continuous assessment method, enabling real-time feedback and growth opportunities.`,
    },
    {
        question: `What does it cost?`,
        answer:
            `GoSkilling is free of charge for individuals looking to get fair, real-time assessments.`,
    },
    // More questions...
]

function FAQ() {
    return (
        <div className="bg-white">
            <div className="mx-auto max-w-7xl px-6 py-24 sm:pt-32 lg:px-8 lg:py-40">
                <div className="lg:grid lg:grid-cols-12 lg:gap-8">
                    <div className="lg:col-span-5">
                        <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>
                        <p className="mt-4 text-base leading-7 text-gray-600">
                            Can’t find the answer you’re looking for?{' '}
                            <Link to="/contact" className="font-semibold text-brand-600 hover:text-brand-500">
                                Contact us!
                            </Link>{' '}

                        </p>
                    </div>
                    <div className="mt-10 lg:col-span-7 lg:mt-0">
                        <dl className="space-y-10">
                            {faqs.map((faq) => (
                                <div key={faq.question}>
                                    <dt className="text-base font-semibold leading-7 text-gray-900">{faq.question}</dt>
                                    <dd className="mt-2 text-base leading-7 text-gray-600">{faq.answer}</dd>
                                </div>
                            ))}
                        </dl>
                    </div>
                </div>
            </div>
        </div>
    )
}


export function CTA({ Heading1, Heading2, Lead, RefURL = false }) {
    return (
        <div className="bg-white">
            <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
                <div className="mx-auto max-w-2xl text-center">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                        {Heading1}
                        <br />
                        {Heading2}
                    </h2>
                    <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
                        {Lead}
                    </p>
                    <div className="mt-10 flex items-center justify-center gap-x-6">
                        <Link
                            to={"/register"}
                            className="rounded-md bg-brand-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-brand-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600"
                        >
                            Get started
                        </Link>
                        {
                            RefURL &&
                            <Link to={RefURL} className="text-sm font-semibold leading-6 text-gray-900">
                                Learn more <span aria-hidden="true">→</span>
                            </Link>
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

