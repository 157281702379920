import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
// actions.js
import apiCall from "../../utilities/api";
const sourceURL = "server";
let rootURL;
if (sourceURL == "localhost") {
    rootURL = "http://localhost:3001/v3/";
} else {
    rootURL = "https://goskilling-node-wju6y.ondigitalocean.app/v3/";
}

const DefineCategory = (y) => {
    const [category, setCategory] = useState(null);
  
    useEffect(() => {
      let userId = parseInt(y);
      if (!userId) {
        return;
      }
  
      if (userId % 2 === 0) {
        setCategory('A');
      } else {
        setCategory('B');
      }
    }, []);
  
    return category;
  };
  
  

// ✅ 
export const loginSuccess = (data, way) => {
    if (way == "login") {
        localStorage.setItem('auth_token', data.token); // Set token in localStorage
        console.log(data.userProfile.id)
    } 
    return {
        type: 'LOGIN_SUCCESS',
        payload: data,
    };
};

// ✅ 
export const loginError = (error) => {
    return {
        type: 'LOGIN_ERROR',
        payload: error,
    };
};

// ✅ 
export const loginUser = (email, password) => {
    return async (dispatch, getState) => {
        try {
            const data = await apiCall('POST', rootURL + 'auth/login', {
                email,
                password,
            });
            dispatch(loginSuccess(data, "login"));
            const state = getState();
            const successMessage = data.message;

            toast.success(successMessage, { theme: "colored" });
        } catch (error) {
            dispatch(loginError(error.response ? error.response.data.message : 'Unknown error'));
            const state = getState();
            const errorMessage = state.authReducer.error;
            //console.log(errorMessage)
            toast.error(errorMessage, { theme: "colored" });
        }
    };
};

// ✅ 
export const registerSuccess = (data, way) => {
    console.log(data)
    if (way == "register") {
        localStorage.setItem('auth_token', data.token); // Set token in localStorage
        const y = DefineCategory(data.userProfile.id);
        localStorage.setItem('y', y);
    }
    return {
        type: 'REGISTER_SUCCESS',
        payload: data,
    };
};

// ✅ 
export const registerError = (error) => {
    return {
        type: 'REGISTER_ERROR',
        payload: error,
    };
};

export const registerUser = (data) => {
    return async (dispatch, getState) => {
        try {
            const response = await apiCall('POST', rootURL + 'auth/register', {
                data,
            });
            //console.log(response)
            //console.log(response.token)
            // Dispatch an action here if needed
            dispatch(registerSuccess(response, "register"));
            toast.success(response.message, { theme: "colored" });

            return Promise.resolve(response);

        } catch (error) {
            // Dispatch an action here if needed
            dispatch(registerError(error.response ? error.response.data.message : 'Unknown error'));

            //console.log(error);
            toast.error(error.message, { theme: "colored" });

            return Promise.reject(error);
        }
    };
};




// ✅
export const logout = () => {
    localStorage.removeItem('auth_token'); // Remove token from localStorage
    localStorage.removeItem('y');
    return {
        type: 'LOGOUT',
    };
};

// ✅ 
export const authError = (error) => {
    localStorage.removeItem('auth_token');
    localStorage.removeItem('y');
    return {
        type: 'AUTH_ERROR',
        payload: error,
    };
};

// ✅ 
export const checkAuth = () => async (dispatch) => {
    const token = localStorage.getItem('auth_token');

    // Proceed only if a token is found
    if (token) {
        try {
            const response = await apiCall('get', `${rootURL}auth/check-auth`, null, {
                'Authorization': `Bearer ${token}`,
            });

            dispatch(loginSuccess(response, "check"));
        } catch (error) {
            // Only remove the token and dispatch authError if it's an invalid/expired token
            if (errorIndicatesInvalidToken(error)) {
                localStorage.removeItem('auth_token');
                localStorage.removeItem('y');
                dispatch(authError('Invalid or expired token'));
            }
        }
    } else {
        // Dispatch a different action or handle differently if no token is found
        dispatch({ type: 'NO_TOKEN_FOUND', payload: 'No token in local storage' });
    }
};

function errorIndicatesInvalidToken(error) {
    // Implement logic to determine if the error is due to an invalid or expired token
    // For example, check the error code or message
    return error.response && error.response.status === 401; // Example condition
}


// ✅ 
export const addNewExperience = (newExperience) => {
    return {
        type: 'ADD_NEW_EXPERIENCE',
        payload: newExperience,
    };
};

//  ✅ 
export const saveNewExperience = (newExperienceData, workEmails) => async (dispatch) => {
    const mailData = workEmails;
    //console.log(workEmails)
    //console.log(newExperienceData)
    const token = localStorage.getItem('auth_token');
    if (!token) {
        dispatch(authError('No token found'));
        toast.error("No Authentication Token found", { theme: "colored" });
        return;
    }
    try {
        const response = await apiCall('POST', rootURL + 'user/users/me/jobhistory', newExperienceData, {
            'Authorization': `Bearer ${token}`,
        });
        //const response = await apiCall('POST', '/api/new-experience', newExperienceData);

        if (response.status === 'success') {

            const newExperienceObject = {
                "name": newExperienceData.company.name,
                "role": newExperienceData.role,
                "logo": newExperienceData.company.logo ? newExperienceData.company.logo : "https://goskilling-storage-bucket.s3.us-east-2.amazonaws.com/brand/goskilling_blank_circle.png",
                "urlname": newExperienceData.company.url,
                "email": newExperienceData.email,
                "is_active": 0
            }

            dispatch(addNewExperience(newExperienceObject));


            const foundEmail = mailData.find((emailObj) => emailObj.email === newExperienceData.email);
            if (!foundEmail) {
                dispatch(addWorkEmail(newExperienceObject.email));
            }

            const successMessage = response.message;
            toast.success(response.message, { theme: "colored" });
        } else {
            toast.error(response.message, { theme: "colored" });

            // Handle error
        }
    } catch (error) {
        // Handle error
        toast.error(error.message, { theme: "colored" });

    }
};


export const newPrimaryPosition = (id) => {
    return {
        type: 'UPDATE_PRIMARY_POSITION',
        payload: id
    }
}

export const updatePrimaryPosition = (id) => async (dispatch) => {
    const token = localStorage.getItem('auth_token');
    if (!token) {
        dispatch(authError('No token found'));
        return;
    }
    try {
        const response = await apiCall('put', rootURL + 'user/users/me/updateprimary', { experienceID: id }, {
            'Authorization': `Bearer ${token}`,
        });
        dispatch(newPrimaryPosition(id));
        toast[response.status](response.message, { theme: "colored" });
    } catch (error) {
        dispatch(authError(error.response ? error.response.data.message : 'Unknown error'));
        toast.error(error.response ? error.response.data.message : 'Unknown error', { theme: "colored" });
    }
}

// ✅
export const deleteExperience = (id) => {
    return {
        type: 'REMOVE_EXPERIENCE',
        payload: id,
    };
};

// ✅  
export const removeExperience = (experienceId) => async (dispatch) => {
    const token = localStorage.getItem('auth_token');
    if (!token) {
        dispatch(authError('No token found'));
        return;
    }

    try {
        const response = await apiCall('delete', rootURL + 'user/users/me/workhistory/' + experienceId, null, {
            'Authorization': `Bearer ${token}`,
        });
        dispatch(deleteExperience(experienceId));
        toast[response.status](response.message, { theme: "colored" });
    } catch (error) {
        dispatch(authError(error.response ? error.response.data.message : 'Unknown error'));
        toast.error(error.response ? error.response.data.message : 'Unknown error', { theme: "colored" });
    }
};


// SKILLS
// SKILLS
// SKILLS


// ✅
export const addNewSkill = (id) => {
    return {
        type: 'ADD_NEW_SKILL',
        payload: id,
    };
};

// ✅
export const saveNewSkill = (newSkill) => async (dispatch) => {
    const token = localStorage.getItem('auth_token');
    if (!token) {
        dispatch(authError('No token found'));
        return;
    }

    try {
        const response = await apiCall('post', rootURL + 'user/users/me', newSkill, {
            'Authorization': `Bearer ${token}`,
        });
        // console.log(response.newId)
        // console.log(newSkill);
        if (response.status != "error") {
            dispatch(addNewSkill(newSkill, response.newId));
        }
        toast[response.status](response.message, { theme: "colored" });
    } catch (error) {
        toast.error(error.response ? error.response.data.message : 'Unknown error', { theme: "colored" });
    }
};

// ✅
export const deleteSkill = (id) => {
    return {
        type: 'REMOVE_SKILL',
        payload: id,
    };
};

// ✅
export const removeSkill = (id) => async (dispatch) => {
    const token = localStorage.getItem('auth_token');
    if (!token) {
        dispatch(authError('No token found'));
        return;
    }

    try {
        const response = await apiCall('delete', rootURL + 'user/users/me/' + id, null, {
            'Authorization': `Bearer ${token}`,
        });

        dispatch(deleteSkill(id));
        toast[response.status](response.message, { theme: "colored" });
    } catch (error) {
        toast.error(error.response ? error.response.data.message : 'Unknown error', { theme: "colored" });
    }
};


// PROFILE
// PROFILE
// PROFILE
// ✅
export const updateName = (firstName, lastName) => {
    return {
        type: 'UPDATE_NAME',
        payload: { firstName, lastName },
    };
};

// ✅
export const changeName = (firstName, lastName) => async (dispatch) => {
    const token = localStorage.getItem('auth_token');
    if (!token) {
        dispatch(authError('No token found'));
        return;
    }

    try {
        const response = await apiCall('put', rootURL + 'user/users/me/', { first_name: firstName, last_name: lastName }, {
            'Authorization': `Bearer ${token}`,
        });

        dispatch(updateName(firstName, lastName));
        toast[response.status](response.message, { theme: "colored" });
    } catch (error) {
        toast.error(error.response ? error.response.data.message : 'Unknown error', { theme: "colored" });
    }
};

// 🟡
export const updateProfile = (newProfileData) => {
    return {
        type: 'UPDATE_PROFILE',
        payload: newProfileData,
    };
};

export const changeProfile = (newProfileData) => async (dispatch) => {
    const token = localStorage.getItem('auth_token');
    if (!token) {
        dispatch(authError('No token found'));
        return;
    }

    try {
        const response = await apiCall('put', rootURL + 'user/users/me/', newProfileData, {
            'Authorization': `Bearer ${token}`,
        });

        dispatch(updateProfile(newProfileData));
        toast[response.status](response.message, { theme: "colored" });
    } catch (error) {
        toast.error(error.response ? error.response.data.message : 'Unknown error', { theme: "colored" });
    }
};

export const addWorkEmail = (newEmail) => async (dispatch) => {
    const token = localStorage.getItem('auth_token');
    if (!token) {
        dispatch(authError('No token found'));
        return;
    }
    dispatch({
        type: 'ADD_WORK_EMAIL', payload: {
            user_id: 4,
            company_id: 10344,
            email: newEmail,
            is_verified: 'unverified',
            is_active: 0,
            allow_email: 1
        }
    });
};

export const userCardSuccess = (data, companyId) => {
    return {
        type: 'USERCARD_SUCCESS',
        payload: { companyId, data },
    };
};

export const userCardError = (data) => {
    return {
        type: 'USERCARD_ERROR',
        payload: data,
    };
};

export const fetchUserCardData = (username) => {

};

export const useFetchUserData = (username) => {
    const [userData, setUserData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    //console.log(username)
    useEffect(() => {
        //console.log("hello")
        const fetchUserData = async () => {
            setIsLoading(true);
            setError(null);

            try {
                const token = localStorage.getItem('auth_token');
                //console.log(username)
                const response = await apiCall('get', `${rootURL}reviews/getuserdata/${username}`, null, {
                    'Authorization': `Bearer ${token}`,
                });
                // const response = await fetch(`${rootURL}reviews/getuserdata${username}`, {
                //     method: 'GET',
                //     headers: {
                //         'Authorization': `Bearer ${token}`,
                //     },
                // });
                const data = await response.json();
                //console.log(data)
                setUserData(data);
            } catch (err) {
                setError(err.message || 'An error occurred');
            } finally {
                setIsLoading(false);
            }
        };

        if (username) {
            fetchUserData();
        }
    }, [username]);

    return { userData, isLoading, error };
};


export const updateJobStatus = (experienceId) => {
    //console.log(experienceId)
    return {
        type: 'CHANGE_EXPERIENCE_IS_ACTIVE',
        payload: experienceId,
    };
};

export const changeJobStatus = (experienceId) => async (dispatch) => {
    const token = localStorage.getItem('auth_token');
    if (!token) {
        dispatch(authError('No token found'));
        return;
    }
    try {
        const response = await apiCall('put', rootURL + 'user/users/me/jobhistory', { experienceId: experienceId }, {
            'Authorization': `Bearer ${token}`,
        });

        dispatch(updateJobStatus(experienceId));
        toast[response.status](response.message, { theme: "colored" });
    } catch (error) {
        toast.error(error.response ? error.response.data.message : 'Unknown error', { theme: "colored" });
    }
}

export const updateMailStatus = (email) => {
    return {
        type: 'CHANGE_ALLOW_EMAIL',
        payload: email,
    };
};

export const changeMailReceiver = (email) => async (dispatch) => {
    const token = localStorage.getItem('auth_token');
    if (!token) {
        dispatch(authError('No token found'));
        return;
    }
    try {
        const response = await apiCall('put', rootURL + 'user/users/me/unsubscribe', { email: email }, {
            'Authorization': `Bearer ${token}`,
        });

        dispatch(updateMailStatus(email));
        toast[response.status](response.message, { theme: "colored" });
    } catch (error) {
        toast.error(error.response ? error.response.data.message : 'Unknown error', { theme: "colored" });
    }
}


export const updateRecruiterSubscriptionStatus = (status) => {
    return {
        type: 'CHANGE_RECRUITER_SUBSCRIPTION',
        payload: status,
    };
}

export const recruiterSubscribe = (status) => async (dispatch) => {
    const token = localStorage.getItem('auth_token');
    if (!token) {
        dispatch(authError('No token found'));
        return;
    }
    dispatch(updateRecruiterSubscriptionStatus(status));
}


//EXPERIMENTAL
export const jobsSuccess = (data) => {
    return {
        type: 'JOB_SUCCESS',
        payload: data,
    };
};

// ✅ 
export const jobsError = (error) => {
    return {
        type: 'JOB_ERROR',
        payload: error,
    };
};

// ✅ 
export const getJobs = () => {
    return async (dispatch, getState) => {
        try {
            const token = localStorage.getItem('auth_token');
            const data = await apiCall('GET', rootURL + 'jobs/fetchjobs',null, {
                'Authorization': `Bearer ${token}`,
              });
            console.log(data)
            dispatch(jobsSuccess(data));
            const state = getState();
            const successMessage = data.message;

            toast.success(successMessage, { theme: "colored" });
        } catch (error) {
            dispatch(jobsError(error.response ? error.response.data.message : 'Unknown error'));
            const state = getState();
            const errorMessage = state.authReducer.error;
            //console.log(errorMessage)
            toast.error(errorMessage, { theme: "colored" });
        }
    };
};