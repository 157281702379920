import React, { useEffect, useState } from 'react';
import { fetchJobById, fetchJobs } from '../services/apiCalls';
import { useSelector } from 'react-redux';
import { useParams, Link, useNavigate } from "react-router-dom";

import './jobs.css';

export function Jobs() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [jobData, setJobData] = useState(null); // Assuming jobData is async-loaded
  const [inputText, setInputText] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const fetchUserData = async () => {
      setIsLoading(true);
      setError(null);

      try {
        // Assuming fetchJobs is a function that fetches your jobs data
        const data = await fetchJobs(); // Ensure fetchJobs is implemented
        console.log(data);
        setJobData(data || []);
      } catch (err) {
        setError(err.message || 'An error occurred');
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    if (jobData) { // Ensures jobData is not null or undefined before filtering
      const filtered = jobData.filter(opening =>
        opening.dta.title.toLowerCase().includes(inputText.toLowerCase()) ||
        opening.dta.company.toLowerCase().includes(inputText.toLowerCase()) ||
        opening.dta.location.name.toLowerCase().includes(inputText.toLowerCase())
      );
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
    }
  }, [inputText, jobData]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="bg-white py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="jobsList mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
          <input
            type="text"
            className="py-2 px-4 border bg-slate-100 w-full"
            placeholder="Search jobs..."
            onChange={(e) => setInputText(e.target.value)}
          />
          {filteredData.length > 0 ? (
            filteredData.map((opening) => (
              <div key={opening.id} className="mb-4 py-2">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  <Link to={`/jobs/${opening.id}`}>{opening.dta.title}</Link>
                </h2>
                <h2 className="text-2xl tracking-tight text-gray-700 sm:text-2xl">
                  {opening.dta.company}
                </h2>
                <h2 className="text-lg tracking-tight text-gray-500 sm:text-xl">
                  {opening.dta.location.name}
                </h2>
              </div>
            ))
          ) : (
            <div>No jobs found</div>
          )}
        </div>
      </div>
    </div>
  );
}


export function JobView({ jobdata }) {
  // Correctly destructure the state and setter from useState
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null); // Use null for the initial state of error
  const [jobData, setJobData] = useState(null); // Assuming jobData should initially be null or an empty array
  let { jobid } = useParams();
  const profileSKills = useSelector((state) => state.authReducer.userProfile?.userSkills);
  const isAuthenticated = useSelector((state) => state.authReducer.isAuthenticated);
  var jobsite_counter = 10;
  const userSkillsMap = (profileSKills || []).reduce((acc, cur) => {
    acc[cur.skill_id] = cur.latest_score;
    return acc;
  }, {});


  useEffect(() => {
    const fetchUserData = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const token = localStorage.getItem('auth_token'); // Ensure you're using this token in your fetchJobs call if needed
        const data = await fetchJobById(jobid);

        setJobData(data);
      } catch (err) {
        setError(err.message || 'An error occurred');
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  function countMatchingSkillIDsWithComparison(opening, userSkillsMap) {
    // Check if there are any skills to process
    if (!opening.dta.skills || opening.dta.skills.length === 0) {
      return 0; // Early return if no skills are present
    }

    let matchCount = 0;

    // Iterate over each skill in opening.dta.skills
    for (const skill of opening.dta.skills) {
      // Check if the skill ID exists in userSkillsMap
      if (skill.id in userSkillsMap) {
        // Check if userSkillsMap score is equal to or greater than skill.adjustedScore
        if (userSkillsMap[skill.id] >= skill.adjustedScore) {
          matchCount++;
        }
      }
    }

    const percentageValue = matchCount / opening.dta.skills.length * 100;
    return percentageValue.toFixed(1);
  }


  return <div className=''>
    <div className="bg-white py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
          {jobData ? (
            <div>
              {jobData.slice(0, jobsite_counter).map((opening, index) => (
                <div key={opening.id}>
                  <Link className='text-brand-700' to={'/jobs'}>Return to jobs</Link>
                  <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    {opening.dta.title}
                  </h2>
                  <h2 className="text-2xl tracking-tight text-gray-700 sm:text-2xl">
                    {opening.dta.company}
                  </h2>
                  <h2 className="text-lg tracking-tight text-gray-500 sm:text-xl">
                    {opening.dta.location.name}
                  </h2>
                  <div className="mt-6 lg:flex lg:gap-x-8 lg:gap-y-10">
                    <div className="lg:flex-initial lg:max-w-2xl"> {/* Description container */}
                      <p dangerouslySetInnerHTML={{ __html: opening.dta.description }} className="text-sm leading-8 text-gray-600 dangerous-html" />
                    </div>

                    <div className="lg:flex-1"> {/* Skills list container */}
                      <span className='text-accent-600 text-sm pb-2'>The following minimum requirement <b>scores have been computed</b>. With more employees from the advertising company joining GoSkilling, scores will be more and more relate to current employee's average skill scores in a comparable position.</span>

                      {
                        isAuthenticated
                          ? <div className="flex flex-col-reverse gap-y-2 py-6">
                            <dt className="text-base mx-auto leading-7 text-gray-600">Overall Qualification</dt>
                            <dd className="text-5xl mx-auto font-semibold tracking-tight text-gray-900">
                              {
                                countMatchingSkillIDsWithComparison(opening, userSkillsMap)
                              }%
                            </dd>
                          </div>
                          : null
                      }

                      <dl className="grid grid-cols-2 gap-4 items-start">
                        {opening.dta.skills && opening.dta.skills.length > 0 ? (
                          opening.dta.skills.map((skill) => (

                            <div key={skill.id} className="flex flex-col-reverse gap-y-2">
                              <dt className="text-base leading-7 text-gray-600">{skill.name}</dt>
                              <dd className="text-5xl font-semibold tracking-tight text-gray-900">
                                {skill.adjustedScore}
                                {/* Access the latest score from userSkillsMap */}
                                <span className='px-2 text-xl'>{userSkillsMap[skill.id] ? skill.adjustedScore > userSkillsMap[skill.id] ? ">" : skill.adjustedScore < userSkillsMap[skill.id] ? "<" : "" : ""}{userSkillsMap[skill.id] ? Math.round(userSkillsMap[skill.id] * 100) / 100 : ""}</span>
                              </dd>
                            </div>
                          ))
                        ) : null}



                      </dl>
                    </div>
                  </div>
                  <JobApplicationButton jobDetails={opening} userSkills={profileSKills} authStatus={isAuthenticated} />
                </div>

              ))}
            </div>
          ) : "There are currently no jobs available."}
        </div>
      </div>
    </div>
  </div>

}


// Assuming you have these props passed to your component

// Assuming JobApplicationButton is defined in the same or another file
export function JobApplicationButton({ jobDetails, userSkills, authStatus }) {
  const as = authStatus;
  if (!authStatus) {
    // This block should now reliably catch cases where authStatus is false (e.g., user is not authenticated)
    return <p>Please log in or register to apply for the job.</p>;
  }
  if (!userSkills || userSkills.length === 0) {
    // Consider displaying a message or handling this scenario appropriately
    return null; // Early exit if there are no user skills
  }

  // Convert userSkills array to an object for easier comparison
  const userSkillsMap = (userSkills || []).reduce((acc, cur) => {
    acc[cur.skill_id] = cur.latest_score;
    return acc;
  }, {});

  // Check if the user meets all skill requirements for the job
  const meetsRequirements = jobDetails.dta.skills.every(skill => {
    const userScore = userSkillsMap[skill.id] || 0;
    return userScore >= skill.adjustedScore;
  });

  return (
    <div className='mt-5'>
      {meetsRequirements ? (
        <a href={jobDetails.dta.absolute_url} target="_blank" className='py-2 px-4 bg-brand-500 hover:bg-brand-600 text-white'>Apply Now</a>
      ) : (
        <div>
          <a type="button" disabled className='py-2 px-4 my-4 bg-brand-200 text-gray-400'>Apply Now</a>
          <p className='mt-4'>You do not meet the skill requirements for this job.</p>
          <p className="text-gray-500">
            This is your dream job? Compare your skills with the requirements and work on improving them.
          </p>
        </div>
      )}
    </div>
  );
}