import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDocumentTitle } from "../services/titleChanger";
import SEO from "../utilities/SEO";
export default function VerifyUser() {
    useDocumentTitle('Verify User | GoSkilling');
    const { register, handleSubmit, setValue } = useForm();

    const { register: registerReview, handleSubmit: registerHandleSubmit, setValue: registerSetValue } = useForm();

    const codeSubmitter = () => {
        console.log("VerifyUser")
    }
    return <div className="relative flex flex-col gap-y-6 p-4 bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
        <SEO title={`Verify Profile | GoSkilling`} description="" name="" type=""/>
        <form onSubmit={handleSubmit(codeSubmitter)}>
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm lg:col-span-7 lg:mt-0">
                <h1 as="h3" className="text-base font-semibold leading-6 text-gray-900">
                    New Account Verification
                </h1>
                <p className="text-sm text-gray-500">
                    Please enter your verification code and email to verify your account.
                </p>

                <div className="sm:col-span-4">
                    <label htmlFor={"code"} className="block text-sm font-medium leading-6 text-gray-700 mb-2">
                        Code
                    </label>
                    <input
                        type="text"
                        id="code"
                        name="code"
                        className="w-full"
                        {...register("code", {
                            required: "This is required."
                        })}
                    />
                </div>
                <div className="sm:col-span-4">
                    <label htmlFor={"email"} className="block text-sm font-medium leading-6 text-gray-700 mb-2">
                        Email
                    </label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        className="w-full"
                        {...register("email", {
                            required: "This is required.",
                        })}
                    />
                </div>
                <div className="sm:col-span-3">
                    <button type="submit" className="flex w-full mx-auto mt-4 mb-2 items-center justify-center rounded-md disabled:bg-brand-100 disabled:text-gray-800 bg-secondary-100 border-gray-700  px-3 py-2 text-sm font-semibold text-gray-50 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-secondary-200 hover:text-white focus-visible:outline-offset-0">Submit</button>
                </div>
            </div>
        </form>
    </div>
}