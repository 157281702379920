import data from './../assets/data/publicdata.json';
import { useDocumentTitle } from '../services/titleChanger';
import SEO from '../utilities/SEO';

export default function TermsOfService() {
    useDocumentTitle('Terms of Service | GoSkilling');
    const section = data[0]["termsofservice"][0]["content"];
    return <div className='w-1/2 mx-auto'>
        <SEO title="Terms of Service | GoSkilling" />
        <h1 className="text-gray-700 text-xl mt-3 font-semibold uppercase">Terms of Service</h1>
                {
            section.map((element, index) => {
                return <div className="py-2" key={index}>
                    <h3 className="text-gray-700 mt-3 font-semibold uppercase">{element.title}</h3>
                    <p className="mb-4 mt-2 dark:text-gray-700" dangerouslySetInnerHTML={{ __html: element.text }}></p>
                </div>
            })

        }
    </div>

}


