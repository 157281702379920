import { useState } from "react";
export default function SoftSkillsSelector({ selectedSkills, setSelectedSkills }) {
    const softSkills = [
        'Active Learning', 'Communication', 'Teamwork', 'Problem Solving',
        'Creativity', 'Work Ethic', 'Interpersonal Skills', 'Time Management',
        'Adaptability', 'Leadership'
    ];

    const toggleSkill = (skill) => {
        if (selectedSkills.includes(skill)) {
            setSelectedSkills(selectedSkills.filter(s => s !== skill));
        } else if (selectedSkills.length < 5) {
            setSelectedSkills([...selectedSkills, skill]);
        }
    };

    return (
        <div className=" mx-auto bg-white rounded-lg shadow-sm p-6">
            <h2 className="text-xl font-light text-gray-800">Select up to 5 Soft Skills: {selectedSkills.length}</h2>
            <div className="flex flex-wrap gap-2 mt-4">
                {softSkills.map(skill => (
                    <button
                        key={skill}
                        className={`px-4 py-2 text-sm rounded-full border ${selectedSkills.includes(skill)
                            ? 'bg-blue-100 border-blue-200 text-blue-800'
                            : 'bg-white border-gray-200 text-gray-800'
                            } hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-200`}
                        onClick={() => toggleSkill(skill)}
                    >
                        {skill}
                    </button>
                ))}
            </div>
        </div>
    );
};