import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Structure } from "./CentralUnit";
import UserPublicHeader from "./user_public_scorecard/UserPublicHead";
import PublicUserSkillSummary from "./user_public_scorecard/UserPublicSkills";
import PublicUserSoftSkillsSummary from "./user_public_scorecard/UserPublicSoft";
import apiCall from "../utilities/api";
import SEO from "../utilities/SEO";

const sourceURL = "server";
let rootURL;
if (sourceURL == "localhost") {
    rootURL = "http://localhost:3001/v3/";
} else {
    rootURL = "https://goskilling-node-wju6y.ondigitalocean.app/v3/";
}

export default function PublicScorecard() {
    const [userData, setUserData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    let { username: routeUsername } = useParams();

    useEffect(() => {
        const fetchUserData = async () => {
            setIsLoading(true);
            setError(null);

            try {
                const token = localStorage.getItem('auth_token');
                const data = await apiCall('get', `${rootURL}reviews/scoreboard/${routeUsername}`, null, {
                    'Authorization': `Bearer ${token}`,
                });

                console.log(data)
                setUserData(data);
                setIsLoading(false)
            } catch (err) {
                setError(err.message || 'An error occurred');
            } finally {
                setIsLoading(false);
            }
        };

        if (routeUsername) {
            fetchUserData();
        }
    }, [routeUsername]);

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    if (!userData) return <div>No user data available</div>;

    return <div className="min-h-screen bg-gray-100">
        <SEO title={`${userData["data"].name}'s Scoreboard | GoSkilling`}/>
        <main className="mx-auto max-w-3xl">
            <Structure>
                <UserPublicHeader user={userData["data"]}/>
            </Structure>
            <Structure>
                <PublicUserSoftSkillsSummary softSkills={userData["data"]["softSkills"]} />
            </Structure>
            <Structure>
                <PublicUserSkillSummary skills={userData["data"]["skills"]} />
            </Structure>

        </main>
    </div>
}