import React from 'react';
import { Helmet } from 'react-helmet-async';
export default function SEO(
    {title="GoSkilling: Advanced AI Skill Assessment for the Modern Workforce", 
    description="Discover the precision of skill assessment with GoSkilling, an innovative AI-driven platform transforming talent evaluation. Our unique approach utilizes quantitative peer reviews to provide objective and unbiased skill ratings, empowering organizations to make informed hiring decisions. Embrace a new era of talent assessment that champions diversity, equity, and inclusivity.", 
    name="GoSkilling - AI-Enhanced Talent Assessment Tool", 
    type="AI-Based Professional Skills Assessment Service"
    }
) {
return (
<Helmet>
{ /* Standard metadata tags */ }
<title>{title}</title>
<meta name='description' content={description} />
{ /* End standard metadata tags */ }
{ /* Facebook tags */ }
<meta property="og:type" content={type} />
<meta property="og:title" content={title} />
<meta property="og:description" content={description} />
{ /* End Facebook tags */ }
{ /* Twitter tags */ }
<meta name="twitter:creator" content={name} />
<meta name="twitter:card" content={type} />
<meta name="twitter:title" content={title} />
<meta name="twitter:description" content={description} />
{ /* End Twitter tags */ }
</Helmet>
)
}