import { useState, useEffect, useContext, createContext } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import apiCall from "../utilities/api";
import { useFetchUserData } from "../redux/actions/loginActions";
import SoftSkillsSelector from "./userview_scorecard/SoftSkillSelector";
import SkillAssessment from "./userview_scorecard/SkillAssessment";
import { useSelector, useDispatch } from "react-redux";
import WorkHistoryConfirmation from "./userview_scorecard/WorkHistoryConfirmation";
import { checkQuickEmail, submitAssessment } from "../services/apiCalls";
import { Structure } from "./CentralUnit";
import { toast } from 'react-toastify';
import Login from "./Login";
import Register from "./Register";
import { useForm } from "react-hook-form";
import { ToastContainer } from "react-toastify";
import logo from './../assets/images/brand/goskilling_general.png';
import { registerUser } from "../redux/actions/loginActions";
import { getSkills } from "../redux/actions/skillsActions";
import { loginUser } from "../redux/actions/loginActions";
import SEO from "../utilities/SEO";
const sourceURL = "server";
let rootURL;
if (sourceURL == "localhost") {
    rootURL = "http://localhost:3001/v3/";
} else {
    rootURL = "https://goskilling-node-wju6y.ondigitalocean.app/v3/";
}

const SessionContext = createContext();

export default function ScoreCard() {
    const [userData, setUserData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [progress, setProgress] = useState(0);
    const [looseProfile, setLooseProfile] = useState(false);
    const [authStatus, setAuthStatus] = useState(false);
    let { username: routeUsername } = useParams();
    const [localUsername, setLocalUsername] = useState(routeUsername);
    const isAuthenticated = useSelector((state) => state.authReducer.isAuthenticated);
    const [softSkills, setSoftSkills] = useState([]);
    const [submitted, setSubmited] = useState(false);
    const [submissionStatus, setSubmissionStatus] = useState(false);
    const [submissionLoading, setSubmissionLoading] = useState(false);
    const [lockForm, setLockForm] = useState(false);
    const showTabs = true; // Set this based on your application's logic

    const isConnection = true;
    useEffect(() => {
        const fetchUserData = async () => {
            setIsLoading(true);
            setError(null);

            try {
                const token = localStorage.getItem('auth_token');
                const data = await apiCall('get', `${rootURL}reviews/getuserdata/${routeUsername}`, null, {
                    'Authorization': `Bearer ${token}`,
                });

                //console.log(data)
                setUserData(data);
                setIsLoading(false)
            } catch (err) {
                setError(err.message || 'An error occurred');
            } finally {
                setIsLoading(false);
            }
        };

        if (routeUsername) {
            fetchUserData();
        }
    }, [routeUsername]);
    useEffect(() => {
        if (userData && userData.data && userData.data.skills) {
            const assessedCount = userData.data.skills.reduce((count, skill) => {
                return skill.assessed !== undefined ? count + 1 : count;
            }, 0);
            setProgress(assessedCount / userData.data.skills.length * 100);
        }
    }, [userData]);

    useEffect(() => {
        setAuthStatus(isAuthenticated);
    }, [isAuthenticated])

    // Use the custom hook normally without 'await'
    const updateSkillAssessment = (skillId, assessedValue) => {
        setUserData(prevUserData => {
            const updatedSkills = prevUserData.data.skills.map(skill => {
                if (skill.id === skillId) {
                    return { ...skill, assessed: assessedValue };
                }
                return skill;
            });

            return { ...prevUserData, data: { ...prevUserData.data, skills: updatedSkills } };
        });
    };

    const submitAssessmentForm = async () => {
        setSubmissionLoading(true);
        const submission = {
            skillRatings: userData["data"]["skills"],
            softskills: softSkills,
            username: routeUsername,
            looseProfile: looseProfile
            //invitationid: profileData.invitationId,
        };
        const response = await submitAssessment(submission);
        toast.info(response.message, { theme: "colored" });
        //console.log(submission);
        //console.log(response);
        setAuthStatus(false);
        if(looseProfile) {
            localStorage.removeItem('auth_token'); // Remove token from localStorage            
        }

        setSubmited(true);
        setSubmissionLoading(false);
        setSubmissionStatus("success");

    }

    const quickAuth = async (email) => {
        //const auth_value = api.checkQuickEmail(email)
        ////console.log(auth_value);
    }

    //const SessionContext = createContext();

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    if (!userData) return <div>No user data available</div>;

    return (
        <SessionContext.Provider value={{ loose: [looseProfile, setLooseProfile], lock: [lockForm, setLockForm], username: [localUsername, setLocalUsername], auth: [authStatus, setAuthStatus], reviewee: [userData, setUserData] }}>

            {
                submissionLoading
                    ? <div className="lg:max-w-2xl md:max-w-2xl mx-auto">
                        <h2 className="text-2xl font-bold leading-7 text-brand-500 sm:truncate sm:text-3xl sm:tracking-tight">
                            Submitting...
                        </h2>
                    </div>
                    : !submitted
                        ? <div className="lg:max-w-2xl md:max-w-2xl mx-auto">
                            <SEO title={`Assess ${userData["data"].name}'s Skills | GoSkilling`} description="" name="" type=""/>
                            <ReviewProfileHeader user={userData["data"]} username={routeUsername}/>
                            
                            {
                                !lockForm
                                    ? <>
                                        <Structure>
                                            <SoftSkillsSelector
                                                selectedSkills={softSkills}
                                                setSelectedSkills={setSoftSkills}
                                            />
                                        </Structure>
                                        <Structure>
                                            <SkillAssessment onSkillReview={updateSkillAssessment} progress={progress} user={userData} />
                                        </Structure>
                                        {/* <Structure>
                    <WorkHistoryConfirmation  user={userData}/>
                </Structure> */}
                                        <Structure>
                                            {authStatus
                                                ? <div className="mt-4 flex justify-end">
                                                    <button
                                                        className="bg-blue-500 text-white w-full px-4 py-2 rounded hover:bg-blue-600"
                                                        onClick={submitAssessmentForm}
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                                : <>
                                                    <Authenticator />
                                                </>
                                            }
                                        </Structure>
                                    </>
                                    : <h2 className="text-2xl text-center font-bold leading-7 text-brand-500 sm:truncate sm:text-3xl sm:tracking-tight">
                                        You mustn't rate yourself.
                                    </h2>
                            }

                        </div>
                        : <div className="lg:max-w-2xl md:max-w-2xl mx-auto text-center">
                            {
                                submissionStatus == "error"
                                    ? <h2 className="text-2xl font-bold leading-7 text-accent-500 sm:truncate sm:text-3xl sm:tracking-tight">
                                        Submission failed!
                                    </h2>
                                    : submissionStatus == "success"
                                        ? <h2 className="text-2xl font-bold leading-7 text-brand-500 sm:truncate sm:text-3xl sm:tracking-tight">
                                            <p className="text-9xl my-4">🎉</p>
                                            Submission successful!
                                        </h2>
                                        : <h2 className="text-2xl font-bold leading-7 text-slate-500 sm:truncate sm:text-3xl sm:tracking-tight">
                                            Loading...
                                        </h2>
                            }
                        </div>
            }
        </SessionContext.Provider>
    );
}

function ReviewProfileHeader({ user, isConnected = false, username }) {
    return (
        <div className="bg-white shadow-sm p-6">
            <div className="max-w-xl mx-auto">
                <div className="flex flex-col md:flex-row md:items-center justify-between">
                    <div>
                        <h1 className="text-2xl font-semibold text-gray-800">{user.name}</h1>
                        <p className="text-md text-gray-500">{user.role && user.company && `${user.role} at ${user.company}`}</p>
                        <p><Link className="text-md font-semibold text-blue-500" to={"/portfolio/user/"+username}>Portfolio</Link></p>
                        {user.isConnected !== undefined && (
                            <span className={`text-sm font-semibold ${user.isConnected ? 'text-green-500' : 'text-gray-400'}`}>
                                {user.isConnected ? 'Connected' : 'Not Connected'}
                            </span>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};



const VerificationOrAuth = () => {
    const [email, setEmail] = useState('');
    const [formDisplay, setFormDisplay] = useState(false);

    return (
        <div className="flex flex-col md:flex-row justify-around items-center bg-white p-4 shadow rounded-lg">
            {/* Email Verification Column */}
            <div className="flex flex-col items-center p-4 border-b md:border-b-0 md:border-r">
                <h3 className="text-lg font-semibold mb-2">Submit and Verify</h3>
                <p className="text-sm mb-4">Submit with your email and verify with the submitted verification code.</p>
                <input
                    type="email"
                    placeholder="Enter your email"
                    className="mb-4 p-2 text-gray-700 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-300"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    Send Verification
                </button>
            </div>

            {/* Auth Column */}
            <div className="flex flex-col items-center p-4">

                {
                    formDisplay === 'Login'
                        ? <FormLogin setFormViewDispaly={setFormDisplay} />
                        : formDisplay === 'Register'
                            ? <FormRegister setFormViewDispaly={setFormDisplay} />
                            : <div className="flex flex-col w-full">          <h3 className="text-lg font-semibold mb-2">Have an Account?</h3>
                                <p className="text-sm mb-4">Sign in or register to continue.</p>
                                <button onClick={() => setFormDisplay('Login')} className="mb-2 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                                    Log In
                                </button>
                                <button onClick={() => setFormDisplay('Register')} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
                                    Register
                                </button>
                            </div>
                }
            </div>
        </div>
    );
};


function FormRegister({ setFormViewDisplay }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm({ mode: "onChange" });

    const onSubmit = async (data) => {
        //console.log(data[0])
        try {
            const response = await dispatch(registerUser(data)); // Replace with your registration action
            if (response && response.status === "success") {
                //navigate("/login"); // Adjust this to your needs
            }
        } catch (error) {
            //console.error("An error occurred:", error);
        }
    };

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-2 lg:px-8 bg-white">
            <form className="mt-2 space-y-2 sm:mx-auto sm:w-full sm:max-w-md" onSubmit={handleSubmit(onSubmit)}>
                {/* First Name */}
                <div>
                    <input
                        id="first_name"
                        name="first_name"
                        type="text"
                        placeholder="First Name"
                        autoComplete="given-name"
                        required
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        {...register("first_name", { required: "First name is required" })}
                    />
                    {errors.first_name && <p className="text-red-500 text-xs">{errors.first_name.message}</p>}
                </div>

                {/* Last Name */}
                <div>
                    <input
                        id="last_name"
                        name="last_name"
                        type="text"
                        placeholder="Last Name"
                        autoComplete="family-name"
                        required
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        {...register("last_name", { required: "Last name is required" })}
                    />
                    {errors.last_name && <p className="text-red-500 text-xs">{errors.last_name.message}</p>}
                </div>

                {/* Personal Email */}
                <div>
                    <input
                        id="personal_email"
                        name="personal_email"
                        type="email"
                        placeholder="Personal Email"
                        autoComplete="email"
                        required
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        {...register("personal_email", {
                            required: "Email is required",
                            pattern: {
                                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                message: "Invalid email address"
                            }
                        })}
                    />
                    {errors.personal_email && <p className="text-red-500 text-xs">{errors.personal_email.message}</p>}
                </div>

                {/* Password */}
                <div>
                    <input
                        id="password"
                        name="password"
                        type="password"
                        placeholder="Password"
                        autoComplete="new-password"
                        required
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        {...register("password", {
                            required: "Password is required",
                            minLength: {
                                value: 8,
                                message: "Password must be at least 8 characters"
                            },
                            pattern: {
                                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).*$/,
                                message: "Password must include at least one uppercase letter, one lowercase letter, one number, and one special character"
                            }
                        })}
                    />
                    {errors.password && <p className="text-red-500 text-xs">{errors.password.message}</p>}
                </div>

                {/* Terms and Conditions */}
                <div className="flex items-center">
                    <input
                        id="agreetoterms"
                        name="agreetoterms"
                        type="checkbox"
                        required
                        className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        {...register("agreetoterms", { required: "You must agree to the terms" })}
                    />
                    <label htmlFor="agreetoterms" className="ml-2 block text-sm text-gray-900">
                        I agree to the <Link to="/terms" className="text-indigo-600 hover:text-indigo-500">Terms of Service</Link> and <Link to="/privacy" className="text-indigo-600 hover:text-indigo-500">Privacy Policy</Link>.
                    </label>
                </div>
                {errors.agreetoterms && <p className="text-red-500 text-xs">{errors.agreetoterms.message}</p>}

                {/* Submit Button */}
                <div>
                    <button
                        type="submit"
                        disabled={!isValid}
                        className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
                    >
                        Register
                    </button>
                </div>
            </form>
        </div>
    );
}

function FormLogin({ setFormViewDisplay }) {
    const dispatch = useDispatch();
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = (data) => {
        dispatch(loginUser(data.email, data.password));
        dispatch(getSkills()); // Replace with your actual action if needed
    };

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-2 lg:px-8 bg-white">
            {/* ... existing code for logo and heading ... */}

            <form className="mt-2 space-y-2 sm:mx-auto sm:w-full sm:max-w-md" onSubmit={handleSubmit(onSubmit)}>
                {/* Email Input */}
                <div>
                    <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        placeholder="Email"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        {...register("email", {
                            required: "This is required",
                            pattern: {
                                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                message: "Invalid email address"
                            }
                        })}
                    />
                    {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email.message}</p>}
                </div>

                {/* Password Input */}
                <div>
                    <input
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        placeholder="Password"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        {...register("password", {
                            required: "This is required",
                            minLength: {
                                value: 8,
                                message: "Password must be at least 8 characters"
                            }
                        })}
                    />
                    {errors.password && <p className="text-red-500 text-xs mt-1">{errors.password.message}</p>}
                </div>

                {/* Submit Button */}
                <div>
                    <button
                        type="submit"
                        className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
                    >
                        Sign In
                    </button>
                </div>
            </form>

            {/* Link to Registration */}
            <div className="mt-6">
                <div className="relative">
                    <div className="absolute inset-0 flex items-center">
                        <div className="w-full border-t border-gray-300"></div>
                    </div>
                    <div className="relative flex justify-center text-sm">
                        <span className="px-2 bg-white text-gray-500">
                            Or continue with
                        </span>
                    </div>
                </div>

                <div className="mt-6 grid grid-cols-3 gap-3">
                    {/* Social Media Login Buttons */}
                </div>
            </div>
        </div>
    );
}
const Authenticator = ({ }) => {
    const [activeTab, setActiveTab] = useState('singleEmail');

    return (
        <div className="w-full max-w-md mx-auto">
            <div className="flex border-b">
                <button
                    className={`flex-1 p-2 text-center ${activeTab === 'singleEmail' ? 'border-b-2 border-blue-500 font-medium text-blue-500' : 'text-gray-500'}`}
                    onClick={() => setActiveTab('singleEmail')}
                >
                    Quick Submit
                </button>
                <button
                    className={`flex-1 p-2 text-center ${activeTab === 'login' ? 'border-b-2 border-blue-500 font-medium text-blue-500' : 'text-gray-500'}`}
                    onClick={() => setActiveTab('login')}
                >
                    Sign In
                </button>
                <button
                    className={`flex-1 p-2 text-center ${activeTab === 'register' ? 'border-b-2 border-blue-500 font-medium text-blue-500' : 'text-gray-500'}`}
                    onClick={() => setActiveTab('register')}
                >
                    Register
                </button>
            </div>

            {activeTab === 'singleEmail' && <SingleEmail />}
            {activeTab === 'login' && <FormLogin />}
            {activeTab === 'register' && <FormRegister />}
        </div>
    );
};

const SingleEmail = () => {
    const { loose, lock, username, auth, reviewee } = useContext(SessionContext);

    const [looseProfile, setLooseProfile] = loose;
    const [authStatus, setAuthStatus] = auth;
    const [userData, setUserData] = reviewee;
    const [localUsername, setLocalUsername] = username;
    const [lockForm, setLockForm] = lock;

    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm({ mode: "onChange" });

    const onSubmit = async (data) => {
        ////console.log(data);
        ////console.log(userData["data"][0].username)
        const auth_value = await checkQuickEmail(data.email, localUsername);
        //console.log(auth_value.message)
        setLockForm(auth_value.message==="You mustn't rate yourself.");
        //delete Employee.firstname;
        ////console.log(auth_value);
        setAuthStatus(true);
        setLooseProfile(true);
        localStorage.setItem('auth_token', auth_value.token); // Set token in localStorage

        //dispatch(loginUser(data.email, data.password));
        //dispatch(getSkills()); // Replace with your actual action if needed
    };



    return (
        <form onSubmit={handleSubmit(onSubmit)} className="p-4">
            <div className="mb-2">
                <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    placeholder="Email"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    {...register("email", {
                        required: "This is required",
                        pattern: {
                            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                            message: "Invalid email address"
                        }
                    })}
                />
                {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email.message}</p>}
            </div>
            {/* Terms and Conditions */}
            <div className="flex items-center">
                <input
                    id="agreetoterms"
                    name="agreetoterms"
                    type="checkbox"
                    required
                    className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    {...register("agreetoterms", { required: "You must agree to the terms" })}
                />
                <label htmlFor="agreetoterms" className="ml-2 block text-sm text-gray-900">
                    I agree to the <Link to="/terms" className="text-indigo-600 hover:text-indigo-500">Terms of Service</Link> and <Link to="/privacy" className="text-indigo-600 hover:text-indigo-500">Privacy Policy</Link>.
                </label>
            </div>
            {errors.agreetoterms && <p className="text-red-500 text-xs">{errors.agreetoterms.message}</p>}
            <button type="submit" className="group relative w-full flex justify-center py-2 px-4 mt-2 border border-transparent text-sm font-medium rounded-md text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500">
                Submit
            </button>
        </form>
    );
};

