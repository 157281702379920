import Login from "../Login";
import Register from "../Register";
import Homepage from "../HomePage";
import WorkEmails from "../WorkEmails";
import Dashboard from "../Dashboard";
import Skills from "../Skills";
import Review from "../ReviewCard";
import VerifyEmail from "../VerifyEmail";
import VerifyUser from "../VerifyUser";
import NotFound from "../404";
import Settings from "../Settings";
import Profile from "../Profile";
import ConfirmEmail from "../ConfirmEmail";
import ConfirmAccount from "../ConfirmAccount";
import Logout from "../Logout";
import RecruiterDashboard from "../RecruiterDashboard";
import {RecruiterJobs,RecruiterViewJob} from "../RecruiterJobs";
import RecruiterSubscribe from "../RecruiterSubscribe";
import About from "../About";
import Contact from "../Contact";
import TermsOfService from "../TermsOfSerivce";
import PrivacyPolicy from "../PrivacyPolicy";
import {
    Bars3Icon,
    BellIcon,
    BriefcaseIcon,
    CalendarIcon,
    ChartPieIcon,
    Cog6ToothIcon,
    DocumentDuplicateIcon,
    FolderIcon,
    HomeIcon,
    UsersIcon,
    UserIcon,
    XMarkIcon,
    EnvelopeIcon,
    WrenchScrewdriverIcon,

} from '@heroicons/react/24/outline'


export const routes = [
    {
        path: '/',
        component: Dashboard,
        meta: {
          requiresAuth: true,
          permissions: ['core', 'recruiter'],
          sidebar: true,
          index: false,
          icon: HomeIcon,
          // ... other meta data
        }
      },
    ]

// routesConfig.js
export const publicRoutes = {
    home: {
        path: '/',
        component: Homepage,
        name: "Homepage",
        sidebar: true,
        index: true,
        icon: HomeIcon
    },
    login: {
        path: '/login',
        component: Login,
        name: "Login",
        sidebar: false,
        index: false,
        icon: false
    },
    workEmail: {
        path: '/workemails',
        component: Login,
        name: "Work Emails",
        sidebar: true,
        index: false,
        icon: EnvelopeIcon
    },
    dashboard: {
        path: '/dashboard',
        component: Login,
        name: "Dashboard",
        sidebar: true,
        index: false,
        icon: HomeIcon
    },
    skills: {
        path: '/skills',
        component: Login,
        name: "Skills",
        sidebar: true,
        index: false,
        icon: WrenchScrewdriverIcon
    }, user: {
        path: '/user',
        component: Login,
        name: "Profile",
        sidebar: true,
        index: false,
        icon: UserIcon
    }, settings: {
        path: '/settings',
        component: Login,
        name: "Settings",
        sidebar: false,
        index: false,
        icon: false
    }, review: {
        path: '/user/review',
        component: Review,
        name: "Review",
        sidebar: false,
        index: false,
        icon: false,
    }, register: {
        path: '/register',
        component: Register,
        name: "Register",
        sidebar: false,
        index: false,
        icon: false,
    }, verifyuser: {
        path: '/verifyuser',
        component: VerifyUser,
        name: "Verify User",
        sidebar: false,
        index: false,
        icon: false,
    }, verifyemail: {
        path: '/verifyemail',
        component: VerifyEmail,
        name: "Verify Email",
        sidebar: false,
        index: false,
        icon: false,
    }, confirmemail: {
        path: '/confirmemail',
        component: ConfirmEmail,
        name: "Confirm New Email",
        sidebar: false,
        index: false,
        icon: false,
    }, confirmaccount: {
        path: '/confirmaccount',
        component: ConfirmAccount,
        name: "Confirm Account",
        sidebar: false,
        index: false,
        icon: false,
    }, logout: {
        path: '/logout',
        component: Logout,
        name: "Logout",
        sidebar: false,
        index: false,
        icon: false,
    }, Contact: {
        path: '/contact',
        component: Contact,
        name: "Manage Jobs",
        sidebar: false,
        index: false,
        icon: BriefcaseIcon
    }, About: {
        path: '/about',
        component: About,
        name: "About",
        sidebar: false,
        index: false,
        icon: BriefcaseIcon
    }, Terms: {
        path: '/terms',
        component: TermsOfService,
        name: "Terms of Service",
        sidebar: false,
        index: false,
        icon: false
    }, Privacy: {
        path: '/privacy',
        component: PrivacyPolicy,
        name: "Privacy Police",
        sidebar: false,
        index: false,
        icon: false
    }, notfound: {
        path: '*',
        component: NotFound,
        name: "Not Found",
        sidebar: false,
        index: false,
        icon: false,
    }
    // ... other public routes
};

export const restrictedRoutes = {
    home: {
        path: '/',
        component: Dashboard,
        name: "Dashboard",
        sidebar: true,
        index: true,
        icon: HomeIcon,
        recruiterOnly: false,
        dataOnly:false,
        profilerOnly:false,
        category:"Core"
    }, login: {
        path: '/login',
        component: Dashboard,
        name: "Dashboard",
        sidebar: false,
        index: false,
        icon: false,
        recruiterOnly: false,
        dataOnly:false,
        profilerOnly:false,
        category:"Core"
    }, workEmail: {
        path: '/workemails',
        component: WorkEmails,
        name: "Work Emails",
        sidebar: true,
        index: false,
        icon: EnvelopeIcon,
        recruiterOnly: false,
        dataOnly:false,
        profilerOnly:false,
        category:"Core"
    }, dashboard: {
        path: '/dashboard',
        component: Dashboard,
        name: "Dashboard",
        sidebar: false,
        index: false,
        icon: HomeIcon,
        recruiterOnly: false,
        dataOnly:false,
        profilerOnly:false,
        category:"Core"
    }, skills: {
        path: '/skills',
        component: Skills,
        name: "Skills",
        sidebar: true,
        index: false,
        icon: WrenchScrewdriverIcon,
        recruiterOnly: false,
        dataOnly:false,
        profilerOnly:false,
        category:"Core"
    }, user: {
        path: '/user',
        component: Profile,
        name: "Profile",
        sidebar: true,
        index: false,
        icon: UserIcon,
        recruiterOnly: false,
        dataOnly:false,
        profilerOnly:false,
        category:"Core"
    }, settings: {
        path: '/settings',
        component: Settings,
        name: "Settings",
        sidebar: false,
        index: false,
        icon: false,
        recruiterOnly: false,
        dataOnly:false,
        profilerOnly:false,
        category:"Core"
    }, review: {
        path: '/user/review',
        component: Review,
        name: "Review",
        sidebar: false,
        index: false,
        icon: false,
        recruiterOnly: false,
        dataOnly:false,
        profilerOnly:false,
        category:"Core"
    }, register: {
        path: '/register',
        component: Dashboard,
        name: "Dashboard",
        sidebar: false,
        index: false,
        icon: false,
        recruiterOnly: false,
        dataOnly:false,
        profilerOnly:false,
        category:"Core"
    }, verifyuser: {
        path: '/verifyuser',
        component: VerifyUser,
        name: "Verify User",
        sidebar: false,
        index: false,
        icon: false,
        recruiterOnly: false,
        dataOnly:false,
        profilerOnly:false,
        category:"Core"
    }, verifyemail: {
        path: '/verifyemail',
        component: VerifyEmail,
        name: "Verify Email",
        sidebar: false,
        index: false,
        icon: false,
        recruiterOnly: false,
        dataOnly:false,
        profilerOnly:false,
        category:"Core"
    }, confirmemail: {
        path: '/confirmemail',
        component: ConfirmEmail,
        name: "Confirm New Email",
        sidebar: false,
        index: false,
        icon: false,
        recruiterOnly: false,
        dataOnly:false,
        profilerOnly:false,
        category:"Core"
    }, confirmaccount: {
        path: '/confirmaccount',
        component: ConfirmAccount,
        name: "Confirm Account",
        sidebar: false,
        index: false,
        icon: false,
        recruiterOnly: false,
        dataOnly:false,
        profilerOnly:false,
        category:"Core"
    }, RecruiterSubscribe: {
        path: '/recruiter',
        component: RecruiterSubscribe,
        name: "Subscription",
        sidebar: false,
        index: false,
        icon: BriefcaseIcon,
        recruiterOnly: false,
        dataOnly:false,
        profilerOnly:false,
        category:"Core"
    }, RecruiterDashboard: {
        path: '/recruiter/dashboard',
        component: RecruiterDashboard,
        name: "Recruiter Dashboard",
        sidebar: true,
        index: true,
        icon: BriefcaseIcon,
        recruiterOnly: true,
        dataOnly:false,
        profilerOnly:false,
        category:"Recruiter"
    }, RecruiterJobs: {
        path: '/recruiter/jobs',
        component: RecruiterJobs,
        name: "Manage Jobs",
        sidebar: true,
        index: false,
        icon: BriefcaseIcon,
        recruiterOnly: true,
        dataOnly:false,
        profilerOnly:false,
        category:"Recruiter"
    }, RecruiterViewJob: {
        path: '/recruiter/jobs/job/:job',
        component: RecruiterViewJob,
        name: "Manage Jobs",
        sidebar: false,
        index: false,
        icon: BriefcaseIcon,
        recruiterOnly: true,
        dataOnly:false,
        profilerOnly:false,
        category:"Recruiter"
    }, Contact: {
        path: '/contact',
        component: Contact,
        name: "Manage Jobs",
        sidebar: false,
        index: false,
        icon: BriefcaseIcon,
        recruiterOnly: false,
        dataOnly:false,
        profilerOnly:false,
        category:"Core"
    }, logout: {
        path: '/logout',
        component: Logout,
        name: "Logout",
        sidebar: false,
        index: false,
        icon: false,
        recruiterOnly: false,
        dataOnly:false,
        profilerOnly:false,
        category:"Core"
    }, confirmaccountb: {
        path: '/confirmaccount',
        component: ConfirmAccount,
        name: "Profiler",
        sidebar: true,
        index: false,
        icon: false,
        recruiterOnly: false,
        dataOnly:false,
        profilerOnly:true,
        category:"Profiler"
    },confirmaccounta: {
        path: '/dataccount',
        component: ConfirmAccount,
        name: "Data",
        sidebar: true,
        index: false,
        icon: false,
        recruiterOnly: false,
        dataOnly:true,
        profilerOnly:false,
        category:"Data"
    },About: {
        path: '/about',
        component: About,
        name: "About",
        sidebar: false,
        index: false,
        icon: BriefcaseIcon
    }, Terms: {
        path: '/terms',
        component: TermsOfService,
        name: "Terms of Service",
        sidebar: false,
        index: false,
        icon: false
    }, Privacy: {
        path: '/privacy',
        component: PrivacyPolicy,
        name: "Privacy Police",
        sidebar: false,
        index: false,
        icon: false
    }, notfound: {
        path: '*',
        component: NotFound,
        name: "Not Found",
        sidebar: false,
        index: false,
        icon: false,
        recruiterOnly: false,
        dataOnly:false,
        profilerOnly:false,
        category:"Core"
    }
    // ... other restricted routes
};




// export function generateDynamicPath(routeKey, dynamicKey, dynamicValue) {
//     const route = routes[routeKey];
//     if (route) {
//         return route.path.replace(`:${dynamicKey}`, dynamicValue);
//     }
//     return null;
// }