export default function UserPublicHeader({ user, isConnected = false }) {
    if(user.length===0) {
        return <div className="container mx-auto  bg-white rounded-lg shadow">loading...</div>
    }
    return (
        <div className="bg-white shadow-sm p-6">
            <div className="max-w-xl mx-auto">
                <div className="flex flex-col md:flex-row md:items-center justify-between">
                    <div>
                        <h1 className="text-2xl font-semibold text-gray-800">{user.name}</h1>
                        <p className="text-md text-gray-500">{user.role && user.company ? `${user.role} at ${user.company}`: "" }</p>
                        
                        {user.isConnected !== undefined && (
                            <span className={`text-sm font-semibold ${user.isConnected ? 'text-green-500' : 'text-gray-400'}`}>
                                {user.isConnected ? 'Connected' : 'Not Connected'}
                            </span>
                        )}
                    </div>
                </div>
            </div>
        </div>
    ); 
}