import { useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { XCircleIcon, CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { set, useForm } from 'react-hook-form';
import { checkEmailEnding, checkCompanyURL, resendVerification } from '../../services/apiCalls';
import { removeExperience, saveNewExperience, changeJobStatus, changeMailReceiver, updatePrimaryPosition } from "../../redux/actions/loginActions";
import { UserXContext } from "../../contexts/userContext";
const defaultValues = {
    email: "",
    url: "",
    name: ""
}

export default function ExperienceList() {
    //const [companies, setCompanies] = useState(companyList); // companyList is the dummy data array
    const [newCompany, setNewCompany] = useState({ name: '', workEmail: '', jobTitle: '' });
    const [showEmailEditor, setShowEmailEditor] = useState(false);
    const [emailExists, setEmailExists] = useState(false); // Dummy state for demonstration
    const [urlExists, setUrlExists] = useState(false);
    const [emailChecked, setEmailChecked] = useState(false);
    const [urlChecked, setUrlChecked] = useState(false);
    const [company, setCompany] = useState(false);
    const [formReady, setFormReady] = useState(false);
    const [openDropdown, setOpenDropdown] = useState(null);
    const dispatch = useDispatch();



    const toggleEmailEditor = () => {
        setShowEmailEditor(!showEmailEditor);
    }

    //const dispatch = useDispatch();
    const { register, handleSubmit, watch, reset, formState: { errors, isValid }, } = useForm({ defaultValues });
    const handleDelete = (companyId) => {
        //setCompanies(companies.filter(company => company.id !== companyId));
    };

    const checkHasNoEmail = watch("hasNoEmail", false); // watch the value of checkbox
    const checkHasNoURL = watch("hasNoURL", false); // watch the value of checkbox
    //var userdata = useContext(UserXContext);
    const userdata = useSelector((state) => state.authReducer.userProfile);

    const userExperiences = useSelector((state) => state.authReducer.userProfile.experiences);
    const storedWorkEmails = useSelector((state) => state.authReducer.userProfile.workEmails);

    function getSecondPart(str) {
        return str.split('@')[1];
    }

    //useDocumentTitle('Work Emails / Experiences | GoSkilling');
    const resetStates = () => {
        setEmailChecked(false);
        setEmailExists(false);
        setUrlChecked(false);
        setUrlExists(false);
        setCompany(false);
        setFormReady(false);
        reset({ defaultValues })
    }

    const validateURL = (url) => {
        const pattern = /^(?:https?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;
        return pattern.test(url) || "Invalid URL format";
    };

    // Mock function to check email in database (replace with real API call)
    // const checkEmailInDatabase = async (data) => {
    //     const { role, email, url, name, hasNoEmail } = data;

    //     const email_ending = getSecondPart(email)

    //     if (!emailChecked && !checkHasNoEmail) {
    //         try {
    //             const response = await checkEmailEnding(email_ending);

    //             if (response.status == "success") {
    //                 setCompany(response.data);
    //                 setEmailExists(true);
    //                 setEmailChecked(true);
    //                 setFormReady(true);
    //             } else {
    //                 setEmailExists(false);
    //                 setCompany(false);
    //                 setEmailChecked(true);
    //                 setFormReady(false);
    //             }

    //             const obj = {
    //                 role: role,
    //                 email: email,
    //                 company: company
    //             }
    //         } catch (error) {

    //             setCompany(false);
    //             setEmailExists(false);
    //             setEmailChecked(false);
    //             setFormReady(false);
    //         }
    //     } else if ((emailChecked && !emailExists && !urlChecked) || (checkHasNoEmail && !checkHasNoURL)) {
    //         const responseCompanyURL = await checkCompanyURL(url);
    //         if (responseCompanyURL.status == "success") {

    //             setCompany(responseCompanyURL.data);
    //             setUrlExists(true);
    //             setUrlChecked(true);
    //             setFormReady(true);
    //             const obj = {
    //                 role: role,
    //                 email: email,
    //                 company: company
    //             }

    //             console.log(obj);
    //         } else {
    //             setUrlExists(false);
    //             setCompany(false);
    //             setUrlChecked(true);
    //             setFormReady(false);
    //         }
    //     } else if ((emailChecked && !emailExists && urlChecked && !urlExists) || (checkHasNoEmail && checkHasNoURL)) {
    //         if (name.length > 0) {
    //             setFormReady(true);

    //             const obj = {
    //                 role: role,
    //                 email: email,
    //                 company: {
    //                     name: name,
    //                     url: checkHasNoURL ? "" : url,
    //                     email_ending: checkHasNoEmail ? "" : email_ending
    //                 },
    //             };

    //             dispatch(saveNewExperience(obj,storedWorkEmails));
    //             resetStates();
    //             toggleEmailEditor();
    //         } else {
    //             setFormReady(false);
    //         }
    //     } else if ((emailChecked && emailExists) || (urlChecked && urlExists)) {

    //         setFormReady(true);
    //         const obj = {
    //             role: role,
    //             email: email,
    //             company: company,
    //         };

    //         dispatch(saveNewExperience(obj,storedWorkEmails));
    //         resetStates();
    //         toggleEmailEditor();
    //     }
    // }

    const checkEmail = async (email_ending, role, email) => {
        const response = await checkEmailEnding(email_ending);
        let emailExists = false;
        let company = false;

        if (response.status == "success") {
            emailExists = true;
            company = response.data;
        }

        return { emailExists, company };
    };

    const checkURL = async (url, role, email) => {
        const response = await checkCompanyURL(url);
        let urlExists = false;
        let company = false;

        if (response.status == "success") {
            urlExists = true;
            company = response.data;
        }

        return { urlExists, company };
    };

    const createObject = (role, email, company, hasNoEmail, hasNoURL) => {
        return {
            role,
            email,
            company,
            hasNoEmail,
            hasNoURL
        };
    };

    const checkEmailInDatabase = async (data) => {
        const { role, email, url, name, hasNoEmail } = data;
        const email_ending = getSecondPart(email);

        try {
            if (!emailChecked && !checkHasNoEmail) {
                const { emailExists, company } = await checkEmail(email_ending, role, email);
                setCompany(company);
                setEmailExists(emailExists);
                setEmailChecked(true);
                setFormReady(emailExists);
            }

            if ((!emailExists && !urlChecked && emailChecked) || (checkHasNoEmail && !checkHasNoURL)) {
                const { urlExists, company } = await checkURL(url, role, email);
                setCompany(company);
                setUrlExists(urlExists);
                setUrlChecked(true);
                setFormReady(urlExists);
            }

            if ((!emailExists && urlChecked && !urlExists) || (checkHasNoEmail && checkHasNoURL)) {
                setFormReady(name.length > 0);
                if (name.length > 0) {
                    dispatch(saveNewExperience(createObject(role, email, { name, url: checkHasNoURL ? "" : url, email_ending: checkHasNoEmail ? "" : email_ending }, checkHasNoEmail, checkHasNoURL), storedWorkEmails));
                    resetStates();
                    toggleEmailEditor();
                }
            }

            if ((emailExists && emailChecked) || (urlChecked && urlExists)) {
                dispatch(saveNewExperience(createObject(role, email, company, checkHasNoEmail, checkHasNoURL), storedWorkEmails));
                resetStates();
                toggleEmailEditor();
            }

        } catch (error) {
            //console.error(error);
            // Handle the error appropriately
            setFormReady(false);
        }
    };


    const removeSelectedExperience = async (experienceId) => {
        dispatch(removeExperience(experienceId))
    }

    const deactivateEmails = async (email) => {
        //console.log(email);
        dispatch(changeMailReceiver(email));
    }

    const deactivateJob = async (experienceId) => {
        //console.log(experienceId);
        //dispatch(resendVerification(experienceId));
    }

    const makePrimary = async (experienceId) => {
        //console.log(experienceId);
        dispatch(updatePrimaryPosition(experienceId))

    }

    const resendEmailVerificationCode = async (experienceId) => {
        const response = await resendVerification(experienceId);

        if (response.status == "success") {
            //console.log("success")
        } else {
            //console.log("failure");

        }
    }

    return (
        <div className="bg-white">
            <div className="max-w-4xl mx-auto">
            <div className="border-b border-gray-200 bg-white py-2">
                <h1 className="text-2xl font-light text-gray-800 px-6">Work Experience and Emails</h1>
            </div>
                <div className="bg-white rounded-lg shadow">
                    {showEmailEditor
                        ? <div className="mt-6 bg-white rounded-lg shadow-lg overflow-hidden px-6 py-4">
                            <form onSubmit={handleSubmit(checkEmailInDatabase)}>
                                <div>
                                    <label htmlFor="role">Role</label>
                                    <div className='flex  space-x-4'>
                                        <input
                                            id="role"
                                            name="role"
                                            type="text"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6"
                                            {...register("role", {

                                                required: "This is required",
                                            })}
                                        />
                                    </div>
                                </div>

                                <div>

                                    <div className='flex space-x-4'>
                                        <input
                                            name="hasNoEmail"
                                            value={true}
                                            type="checkbox"
                                            onClick={() => { setEmailChecked(false); setUrlChecked(false); setCompany(false); setFormReady(false); }}
                                            {...register("hasNoEmail")}
                                        /><label htmlFor="hasNoEmail">I have <b>no Work Email</b> for this job</label>
                                    </div>
                                </div>


                                {checkHasNoEmail ? "" :
                                    <div >
                                        <label htmlFor="email">Work Email</label>
                                        <div className='flex  space-x-4'>
                                            <input
                                                id="email"
                                                name="email"
                                                type="email"
                                                autoComplete="email"
                                                onClick={() => { setEmailChecked(false); setUrlChecked(false); setCompany(false); setFormReady(false); }}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6"
                                                {...register("email", {
                                                    required: checkHasNoEmail ? false : "This field is required",
                                                    pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                                })}
                                            />
                                            {!emailExists && emailChecked ? <ExclamationCircleIcon className="text-accent-500 w-8 h-8" /> : emailExists && emailChecked ? <CheckCircleIcon className="text-brand-500 w-8 h-8" /> : ""}
                                        </div>
                                        <div className="flex space-x-4 pb-6 text-accent-700">
                                            {!emailExists && emailChecked ? <div className="flex space-x-4">Email domain not yet associated with an entity. Please enter a URL in the field below.</div> : ""}
                                        </div>
                                    </div>
                                }

                                {!checkHasNoURL && checkHasNoEmail || (!checkHasNoURL && !emailExists && emailChecked) ? (
                                    <div className='flex space-x-4'>
                                        <input
                                            name="hasNoURL"
                                            value={true}
                                            type="checkbox"
                                            onClick={() => { setEmailChecked(false); setUrlChecked(false); setCompany(false); setFormReady(false); }}
                                            {...register("hasNoURL")}
                                        /><label htmlFor="hasNoURL">Company/School/Project has <b>no homepage.</b></label>
                                    </div>
                                ) : ""}

                                {!checkHasNoURL && checkHasNoEmail || (!checkHasNoURL && !emailExists && emailChecked) ? (
                                    <div>
                                        <label htmlFor="url">URL</label>
                                        <div className='flex space-x-4'>
                                            <input
                                                id="url"
                                                name="url"
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6"
                                                placeholder="www.goskilling.com"
                                                type="text"
                                                {...register("url", { required: emailChecked && !emailExists, maxLength: 60, validate: validateURL })}
                                            />
                                            {!urlExists && urlChecked ? <XCircleIcon className="text-accent-500 w-8 h-8" /> : urlExists && urlChecked ? <CheckCircleIcon className="text-brand-500 w-8 h-8" /> : ""}
                                        </div>
                                    </div>
                                ) : ""}

                                {checkHasNoURL || (!urlExists && urlChecked) ? (
                                    <div>
                                        <label htmlFor="name">Comapny Name</label>
                                        <div className='flex space-x-4'>
                                            <input
                                                id="name"
                                                name="name"
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6"
                                                placeholder="GoSkilling"
                                                type="text"
                                                {...register("name", { required: checkHasNoURL || (emailChecked && !emailExists && urlChecked && !urlExists), maxLength: 60 })}
                                            />
                                            {!urlExists && urlChecked ? <XCircleIcon className="text-accent-500 w-8 h-8" /> : urlExists && urlChecked ? <CheckCircleIcon className="text-brand-500 w-8 h-8" /> : ""}
                                        </div>

                                    </div>
                                ) : ""}

                                {
                                    company ?
                                        <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 my-2">
                                            <div
                                                key={company.urlname}
                                                className="relative flex items-center space-x-3 rounded-sm border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
                                            >
                                                <div className="flex-shrink-0">
                                                    <img className="h-10 w-10 rounded-full" src={company.logo} alt="" />
                                                </div>
                                                <div className="min-w-0 flex-1">
                                                    <a href="#" className="focus:outline-none">
                                                        <span className="absolute inset-0" aria-hidden="true" />
                                                        <p className="text-sm font-medium text-gray-900">{company.name}</p>
                                                        <p className="truncate text-sm text-gray-500">{company.url}</p>
                                                    </a>
                                                </div>
                                            </div>

                                        </div>
                                        : ""
                                }

                                <button type="submit" disabled={!isValid} className="flex w-full mx-auto mt-4 mb-2 items-center justify-center rounded-md disabled:bg-brand-100 disabled:text-gray-800 bg-secondary-100 border-gray-700  px-3 py-2 text-sm font-semibold text-gray-50 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-secondary-200 hover:text-white focus-visible:outline-offset-0">{formReady ? "Submit" : "Check"}</button>


                                <button className="flex w-full mx-auto mt-4 mb-2 items-center justify-center rounded-md bg-white border-gray-700  px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                                    onClick={() => { toggleEmailEditor(); resetStates(); }}
                                >Return</button>

                            </form>
                        </div>
                        : <div>
                            <ul className="divide-y divide-gray-200">
                                {userdata.experiences.map((company, index) => (
                                    <li key={company.experience_id} className="p-4 flex flex-row items-center justify-between">
                                        <div className="flex items-center space-x-4 flex-grow">
                                            <img src={company.logo} alt={`${company.name} logo`} className="h-12 w-12 rounded-full" />
                                            <div className="flex flex-col">
                                                <span className="font-medium text-gray-800">
                                                    {company.name}
                                                    {company.primary_position === 1 && (
                                                        <span className={`mt-2 ml-2 inline-block text-xs font-semibold py-1 px-2.5 rounded-full w-30 text-center ${company.primary_position === 1 ? 'text-brand-600 bg-brand-100' : ''}`}>
                                                            Primary position
                                                        </span>
                                                    )}
                                                </span>
                                                <span className="text-sm text-gray-500">{company.email}</span>
                                                <span className="text-sm text-gray-500">{company.role}</span>
                                                {company.email && (
                                                    <span className={`mt-2 inline-block text-xs font-semibold py-1 px-2.5 rounded-full w-24 text-center ${company.is_verified === "verified" ? 'text-green-600 bg-green-100' : 'text-red-600 bg-red-100'}`}>
                                                        {company.is_verified === "verified" ? 'Verified' : 'Unverified'}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="relative flex-shrink-0">
                                            <button className="text-gray-700 hover:text-gray-900 focus:outline-none" onClick={() => setOpenDropdown(openDropdown === index ? null : index)}>
                                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path></svg>
                                            </button>
                                            {openDropdown === index && (
                                                <div className="absolute right-0 z-10 mt-2 w-48 bg-white rounded-md shadow-lg py-1 ring-1 ring-black ring-opacity-5">
                                                    {!company.primary_position && (
                                                        <button className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left" onClick={() => makePrimary(company.experience_id)}>Make Primary</button>
                                                    )}
                                                    {company.email && company.is_verified !== "verified" && (
                                                        <button className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left" onClick={() => resendEmailVerificationCode(company.experience_id)}>Resend Verification</button>
                                                    )}
                                                    <button className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left" onClick={() => removeSelectedExperience(company.experience_id)}>Remove</button>
                                                </div>
                                            )}
                                        </div>
                                    </li>
                                ))}
                            </ul>






                            <button type="button" onClick={() => toggleEmailEditor()} className="bg-brand-500 w-full text-gray-100 py-2 px-3 hover:bg-blue-700">Add Work Email</button>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};
