import SEO from "../utilities/SEO"
export default function About() {
    return <div className="relative flex flex-col gap-y-6 p-4 bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl w-1/2 mx-auto">
        <h1 className="text-gray-700 text-xl mt-3 font-semibold uppercase">About us</h1>
        <SEO title="About GoSkilling" />
        <p className="text-base leading-7 text-gray-700 mt-8">
            GoSkilling is a pioneering platform designed to transform the way organizations assess, evaluate, and identify talent. With a commitment to revolutionizing talent acquisition and development, GoSkilling leverages cutting-edge technology to provide businesses and jobseekers with powerful tools and insights.
        </p>
        <h2 className='mt-16 text-2xl font-bold tracking-tight text-gray-900'>Our Mission</h2>
        <p className="text-md mb-4 mt-2 text-gray-700">
            GoSkilling aims to improve recruiting for both, jobseekers and recruiters. Through peer assessments we aim to overcome the
        </p>
        <p className="text-md mb-4 mt-2 text-gray-700">
            Through peer assessments and an algorithm we are computing scores to better demonstrate a candidate's skills and strengths. Comparing a candidate's scores with requirement profiles helps to determine suitability and saves time for both the recruiter and the jobseeker.
        </p>
        <p className="text-md mb-4 mt-2 text-gray-700">
            Our goal is to make  recruiting processes faster and more efficient. We also want to eliminate  biases in recruiting and reduce the skills gap. We achieve this with a skill-focussed candidate profile that gives equal chances to all candidates regardless of demographic attributes.
        </p>
        <p className="text-md mb-4 mt-2 text-gray-700">
            GoSkilling is founded by Christian Kramp with a team of EMBA alumni from Quantic School of Business and Technology, in Washington D.C.
        </p>
    </div>

}