import { useContext, useState } from "react";
import { UserXContext } from "../../contexts/userContext";
import { useSelector, useDispatch } from "react-redux";
import Select from 'react-select';
import { saveNewSkill, removeSkill } from "../../redux/actions/loginActions";
export default function PublicUserSkillSummary({ skills }) {
    //const userdata = useSelector((state) => state.authReducer.userProfile);

    if (skills.length === 0) {
        return <div className="container mx-auto  py-3 px-2 bg-white rounded-lg shadow">The user has no skills yet.</div>
    }

    return (
        <div className="container mx-auto  bg-white rounded-lg shadow">
            <h1 className="text-2xl font-light text-gray-800 p-6">Skill Summary</h1>
            <ul className="divide-y divide-gray-200">
                {skills.map(skill => (
                    <li key={skill.skill_id} className="flex flex-row items-center justify-between py-2 px-4">
                        <span className="flex-1 text-gray-700 text-xs">{`${skill.skill_name} (${skill.skill_category})`}</span>
                        <div className="flex-1 flex items-center">
                            <div className="w-3/5 h-3 bg-gray-200 rounded-full">
                                <div className="h-3 bg-green-400 rounded-full" style={{ width: `${Number(skill.latest_score).toFixed(2)}%` }}></div>
                            </div>
                            <span className="text-xs font-medium text-gray-700 ml-2">{`${Number(skill.latest_score).toFixed(2)}%`}</span>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};