import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { EmptyStateSimple } from "./ui/emptyState";
import { removeSkill, saveNewSkill } from "../redux/actions/loginActions";
import Select from 'react-select'
import { useDocumentTitle } from "../services/titleChanger";
export default function Skills() {
    useDocumentTitle('Skills | GoSkilling');
    const userSkills = useSelector((state) => state.authReducer.userProfile.userSkills);
    const appSkills = useSelector((state) => state.skillReducer.skills);
    const [selectedSkills, setSelectedSkills] = useState([]);
    const dispatch = useDispatch();

    const transformedSkillsArray = appSkills.map(skill => ({
        value: skill.id,  // or skill.urlname, whatever you prefer to use as the value
        label: skill.name,
        category: skill.category
    }));


    const handleChange = (selectedOption) => {
        setSelectedSkills(selectedOption);
        const newSkill = {
            skill_id: selectedOption.value,
            skill_name: selectedOption.label,
            skill_category: selectedOption.category,
            skill_urlname: 'Some URL',
            latest_score: 0,
            second_latest_score: null,
            scoring_change: null
        };
        dispatch(saveNewSkill(newSkill));
    };

    return (<div className="relative flex flex-col gap-y-6 p-4 bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
        <Select
            name="skills"
            options={transformedSkillsArray}
            className="basic-single"
            classNamePrefix="select"
            onChange={handleChange}
            value={selectedSkills}
        />
        <ul role="list" className="divide-y divide-gray-100">

            {userSkills.length > 0 ?
                <table className="w-full mt-4 text-left">
                    <thead className="border-b border-secondary-300">
                        <tr>
                            <th className="p-2 text-secondary-500">Skill</th>
                            <th className="p-2 text-secondary-500">Score</th>
                            <th className="p-2 text-secondary-500">Change</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userSkills.map(skill => (
                            <ListComponent
                            key={skill.skill_id}
                                data={skill}
                            //deleteSkill={deleteUserSkill}
                            />
                        ))}
                    </tbody>
                </table>
                : <EmptyStateSimple />}
        </ul>
    </div>
    )



}

export const ListComponent = ({ data }) => {
    //dispatch(saveNewExperience(obj));
    const dispatch = useDispatch();

    const { skill_id, skill_name, skill_category, latest_score, score_change } = data;

    const deleteSkill = async () => {
        //console.log("removeSkill")
        dispatch(removeSkill(skill_id));

    }

    return (
        <tr className="border-b border-gray-200 hover:bg-blue-50">
            <td className="p-2">
                <div className="font-bold text-primary-500">{skill_name}</div>
                <div className="text-xs text-secondary-500 mt-1 flex items-center">
                    <span>{skill_category}</span>
                    <span className="mx-1">•</span>
                    <button className="text-red-500" type="button" onClick={deleteSkill}>Remove</button>
                </div>
            </td>
            <td className="p-2">
                <div className="font-bold">{typeof latest_score === 'string' ? parseFloat(latest_score).toFixed(2) : (typeof latest_score === 'number' ? latest_score.toFixed(2) : '-')}
                </div>
                <div className="text-xs text-secondary-500 mt-1">Score</div>
            </td>
            <td className="p-2">
                <div className="font-bold">{score_change ? score_change >= 0 ? '+' : '' : '<>'}{typeof score_change === 'string' ? parseFloat(score_change).toFixed(2) : (typeof score_change === 'number' ? score_change.toFixed(2) : '-')}</div>
                <div className="text-xs text-secondary-500 mt-1">Change</div>
            </td>
        </tr>
    );
};