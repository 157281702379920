const initialState = {
    inviteStatus: null,
  };
  
  export default function invitationReducer(state = initialState, action) {
    switch (action.type) {
      case "INVITE_SUCCESS":
        return {
          ...state,
          inviteStatus: "success",
        };
      case "INVITE_FAIL":
        return {
          ...state,
          inviteStatus: "failed",
        };
      default:
        return state;
    }
  }