import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
export default function ProfileJobs() {
    const jobs = useSelector((state) => state.authReducer.jobs);

    const jobsLength = jobs.length;
    let randomIndexes = [];
    let randomJobs = []
    const limit = 3;
    let y = 0;
    while (y < limit) {
        var value = Math.floor(Math.random() * jobsLength);
        if (!randomIndexes.includes(value)) {
            randomIndexes.push(value);
            randomJobs.push(jobs[value]);
            y++;
        }
    }

    return (
        <div className="bg-white shadow rounded-lg ">
            <div className="border-b border-gray-200 bg-white py-2">
                <h1 className="text-2xl font-light text-gray-800 px-6">Job Postings</h1>

                <p className="text-sm text-gray-500  px-6">
                    A couple of jobs we have analyzed and completed with scores. For more, please <Link className="text-brand-500" to="/jobs">visit our job page</Link>.
                </p>
            </div>
            <ul className="divide-y divide-gray-200 sm:px-6 px-8">
                {randomJobs.map((opening) => (
                    <li key={opening.id} className="py-4 flex flex-row items-center justify-between">
                        <div className="flex items-center space-x-4 flex-grow">
                            <div className="flex flex-col">
                                <span className="font-medium text-gray-800">
                                    <Link to={`/jobs/${opening.id}`}>{opening.dta.title}</Link>
                                </span>
                                <span className="text-sm text-gray-500">
                                    {opening.dta.company}
                                </span>
                                <span className="text-sm text-gray-500">
                                    {opening.dta.location.name}
                                </span>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>

    )
}
