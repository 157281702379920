import { useContext, useState } from "react";
import { UserXContext } from "../../contexts/userContext";
import { useSelector, useDispatch } from "react-redux";
import Select from 'react-select';
import { saveNewSkill,removeSkill } from "../../redux/actions/loginActions";
export default function UserSkillSummary() {
    const appSkills = useSelector((state) => state.skillReducer.skills);

    const [selectedSkills, setSelectedSkills] = useState([]);
    const [availableSkills, setAvailableSkills] = useState([
        // Skills that can be added

        { id: 11, name: 'Project Management' },
        { id: 12, name: 'Analytical Thinking' },
        { id: 13, name: 'Data Analysis' },
        { id: 14, name: 'Technical Writing' },
        { id: 15, name: 'Public Speaking' },
        { id: 16, name: 'Digital Marketing' },
        { id: 17, name: 'UX Design' },
        { id: 18, name: 'Foreign Languages' },
        { id: 19, name: 'SEO/SEM' },
        { id: 20, name: 'Database Management' },
    ]);
    const transformedSkillsArray = appSkills.map(skill => ({
        value: skill.id,  // or skill.urlname, whatever you prefer to use as the value
        label: skill.name,
        category: skill.category
    }));

    const dispatch = useDispatch();

    //var userdata = useContext(UserXContext);
    const userdata = useSelector((state) => state.authReducer.userProfile);

    const handleChange = (selectedOption) => {
        setSelectedSkills(selectedOption);
        const newSkill = {
            skill_id: selectedOption.value,
            skill_name: selectedOption.label,
            skill_category: selectedOption.category,
            skill_urlname: 'Some URL',
            latest_score: 0,
            second_latest_score: null,
            scoring_change: null
        };
        dispatch(saveNewSkill(newSkill));
    };

    const deleteSkill = async (skillid) => {
        dispatch(removeSkill(skillid));
    }

    return (
        <div className="container mx-auto  bg-white rounded-lg shadow">
            <h1 className="text-2xl font-light text-gray-800 p-6">Skill Summary</h1>

            <ul className="divide-y divide-gray-200">
                {userdata.userSkills.map(skill => (
                    <li key={skill.skill_id} className="flex flex-row items-center justify-between py-2 px-4">
                        <span className="flex-1 text-gray-700 text-xs">{skill.skill_name+" ("+skill.skill_category+")"}</span>
                        <div className="flex-1 flex items-center">
                            <div className="w-3/5 h-3 bg-gray-200 rounded-full">
                                <div className="h-3 bg-green-400 rounded-full" style={{ width: `${skill.latest_score ? skill.latest_score : 0}%` }}></div>
                            </div>
                            <span className="text-xs font-medium text-gray-700 ml-2">{Math.round(skill.latest_score*100)/100}%</span>
                        </div>
                        <button
                            onClick={() => deleteSkill(skill.skill_id)}
                            className="w-8 h-8 flex items-center justify-center text-red-600 hover:text-red-800"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </li>
                ))}
            </ul>

            {availableSkills && availableSkills.length > 0 && (
                <div className="p-6">
                            <Select
            name="skills"
            options={transformedSkillsArray}
            className="basic-single"
            classNamePrefix="select"
            onChange={handleChange}
            value={selectedSkills}
        />

                </div>
            )}
        </div>
    );
};