import { useState } from "react";
import { useSelector,useDispatch} from "react-redux";
import { updateRecruiterSubscriptionStatus } from "../redux/actions/loginActions";
export default function RecruiterSubscribe() {
    const userProfile = useSelector((state) => state.authReducer.userProfile);
    const [subscribed,setSubscribed] = useState(userProfile.recruiting);
    
    const dispatch = useDispatch();
    const onSubmit = async () => {
        try {
            const response = await dispatch(updateRecruiterSubscriptionStatus(!subscribed));
            setSubscribed(!subscribed);
        } catch (error) {
            console.log("An error occurred:", error);
        }
    };
    return <div>
        <h1 className="text-lg font-bold">Recruiter Subscribe</h1>
        <div>
            Recruiter Subscription Status: {userProfile.recruiting ? "Active" : "Inactive"}
        </div>
        <button type="button" onClick={()=>onSubmit()}>{subscribed ? "Unsubscribed" : "Subscribed"}</button>
        </div>
}