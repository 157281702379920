import { useContext, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { changeProfile, changeName } from "../../redux/actions/loginActions";
import { UserXContext } from "../../contexts/userContext";
import React from 'react';
import { useForm } from 'react-hook-form';

export default function UserDemographics() {
    const { register, handleSubmit, setValue } = useForm();
    const dispatch = useDispatch();
    const userdata = useSelector((state) => state.authReducer.userProfile);
    const onProfileSubmit = data => {
        const { firstName, lastName, ...restOfData } = data;
        dispatch(changeProfile(restOfData));
    }

    useEffect(() => {
        if (userdata) {
            setValue('firstName', userdata.firstName);
            setValue('lastName', userdata.lastName);
            setValue('race', userdata.race);
            setValue('religion', userdata.religion);
            setValue('gender', userdata.gender);
            setValue('veteran_status', userdata.veteran_status);
            setValue('sexuality', userdata.sexuality);
            setValue('age', userdata.age);
        }
    }, [userdata, setValue]);
    return (
        <form onSubmit={handleSubmit(onProfileSubmit)} className="bg-white shadow rounded-lg p-4">
            <h2 className="text-lg font-semibold mb-4">Demographics</h2>
            <h5 className="text-sm text-gray-600 mb-10">
                This is voluntary. We are using these data to prevent biased assessments impacting your scores.
                We will never let anybody access your demographic data.
            </h5>

            {/* Gender */}
            <div className="mb-3">
                <label htmlFor="gender" className="block text-sm font-medium text-gray-700 mb-1">Gender</label>
                <select
                    id="gender"
                    name="gender"
                    className="w-full border-gray-300 rounded-md shadow-sm"
                    {...register("gender")}
                >
                    <option value="f">Female</option>
                    <option value="m">Male</option>
                    <option value="d">Other</option>
                    <option value="dnm">Don't disclose</option>
                </select>
            </div>

            {/* Race */}
            <div className="mb-3">
                <label htmlFor="race" className="block text-sm font-medium text-gray-700 mb-1">Ethnicity</label>
                <select
                    id="race"
                    name="race"
                    className="w-full border-gray-300 rounded-md shadow-sm"
                    {...register("race")}
                >
                    <option value="ba">Black/African (Caribbean etc.)</option>
                    <option value="as">Asian</option>
                    <option value="pi">Pacific Islander</option>
                    <option value="w">White/Caucasian</option>
                    <option value="l">Latinx</option>
                    <option value="m">Mixed</option>
                    <option value="dnm">Don't disclose</option>
                </select>
            </div>

            {/* Religion */}
            <div className="mb-3">
                <label htmlFor="religion" className="block text-sm font-medium text-gray-700 mb-1">Religion</label>
                <select
                    id="religion"
                    name="religion"
                    className="w-full border-gray-300 rounded-md shadow-sm"
                    {...register("religion")}
                >
                    <option value="c">Christian</option>
                    <option value="i">Muslim</option>
                    <option value="b">Buddhist</option>
                    <option value="h">Hindhu</option>
                    <option value="j">Jewish</option>
                    <option value="a">Atheist</option>
                    <option value="s">Sikh</option>
                    <option value="o">Other</option>
                    <option value="dnm">Don't disclose</option>
                </select>
            </div>

            {/* Disabilities */}
            <div className="mb-3">
                <label htmlFor="disabilities" className="block text-sm font-medium text-gray-700 mb-1">Disabilities</label>
                <select
                    id="disabilities"
                    name="disabilities"
                    className="w-full border-gray-300 rounded-md shadow-sm"
                    {...register("disabilities")}
                >
                    <option value="y">I have disabilities</option>
                    <option value="n">No disabilities</option>
                    <option value="dnm">Don't disclose</option>
                </select>
            </div>

            {/* Veteran Status */}
            <div className="mb-3">
                <label htmlFor="veteran_status" className="block text-sm font-medium text-gray-700 mb-1">Veteran Status</label>
                <select
                    id="veteran_status"
                    name="veteran_status"
                    className="w-full border-gray-300 rounded-md shadow-sm"
                    {...register("veteran_status")}
                >
                    <option value="y">Yes, Protected Veteran</option>
                    <option value="n">Not a veteran</option>
                    <option value="dnm">Don't disclose</option>
                </select>
            </div>

            {/* Sexuality */}
            <div className="mb-3">
                <label htmlFor="sexuality" className="block text-sm font-medium text-gray-700 mb-1">Sexuality</label>
                <select
                    id="sexuality"
                    name="sexuality"
                    className="w-full border-gray-300 rounded-md shadow-sm"
                    {...register("sexuality")}
                >
                    <option value="hetero">Heterosexual</option>
                    <option value="homo">Homosexual</option>
                    <option value="bisexual">Bisexual</option>
                    <option value="other">Other</option>
                    <option value="dnm">Don't disclose</option>
                </select>
            </div>

            {/* Age */}
            <div className="mb-3">
                <label htmlFor="age" className="block text-sm font-medium text-gray-700 mb-1">Age</label>
                <input
                    id="age"
                    name="age"
                    type="date"
                    className="w-full border-gray-300 rounded-md shadow-sm"
                    {...register("age")}
                />
            </div>

            <button type="submit" className="flex w-full mx-auto mt-4 mb-2 items-center justify-center rounded-md disabled:bg-brand-100 disabled:text-gray-800 bg-secondary-100 border-gray-700 px-3 py-2 text-sm font-semibold text-gray-50 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-secondary-200 hover:text-white focus-visible:outline-offset-0">
                Save
            </button>
        </form>
    );
}
