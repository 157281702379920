import { createContext, useContext, useState } from 'react';

// Create Context Object
export const SidebarContext = createContext();

// Create a provider for components to consume and subscribe to changes
export const SidebarProvider = ({ children }) => {
  const [navItems, setNavItems] = useState([]); // you can set initial navigation items here if needed
  
  return (
    <SidebarContext.Provider value={{ navItems, setNavItems }}>
      {children}
    </SidebarContext.Provider>
  );
};
