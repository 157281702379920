import { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { brand_logo } from '../arrayImages/brand.logo';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
const navigation = [
  // { name: 'Product', href: '#' },
  // { name: 'Features', href: '#' },
  // { name: 'Marketplace', href: '#' },
  // { name: 'Company', href: '#' },
]

export function PublicHeader() {
  const isAuthenticated = useSelector((state) => state.authReducer.isAuthenticated);
  const ln = useSelector((state) => state.authReducer.userProfile?.lastName);
  const fn = useSelector((state) => state.authReducer.userProfile?.firstName);
  const username = useSelector((state) => state.authReducer.userProfile?.urlname);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <header className="bg-white">
      <nav className="mx-auto flex max-w-7xl items-center justify-between gap-x-6 p-6 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1">
          <Link to={brand_logo.home} className="-m-1.5 p-1.5">
            <span className="sr-only">{brand_logo.name}</span>
            <img className="h-8 w-auto" src={brand_logo.logo} alt="" />
          </Link>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          {navigation.map((item) => (
            <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-gray-900">
              {item.name}
            </a>
          ))}
        </div>
        <div className="flex flex-1 items-center justify-end gap-x-6">

          {!isAuthenticated
            ?
            <>
              <Link to={"/login"} className="hidden lg:block lg:text-sm lg:font-semibold lg:leading-6 lg:text-gray-900">
                Log in
              </Link>
              <Link
                to={"/register"}
                className="rounded-md bg-brand-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600"
              >
                Sign up
              </Link>
            </>
            : <Link to={`/user/${username}`} className="hidden lg:block lg:text-sm lg:font-semibold lg:leading-6 lg:text-gray-900">
              {`${fn} ${ln}`}
            </Link>
          }

        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </nav>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center gap-x-6">
            <Link to={brand_logo.source} className="-m-1.5 p-1.5">
              <span className="sr-only">{brand_logo.name}</span>
              <img
                className="h-8 w-auto"
                src={brand_logo.logo}
                alt={brand_logo.name}
              />
            </Link>

            {
              !isAuthenticated && <Link
                to={"/register"}
                className="rounded-md bg-brand-600 px-3 py-2 text-sm font-semibold text-brand-50 shadow-sm hover:bg-brand-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600"
              >
                Sign up
              </Link>}

            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
              <div className="py-6">
                {
                  !isAuthenticated
                    ?
                    <Link
                      to={"/login"}
                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-brand-500 hover:bg-gray-50"
                    >
                      Log in
                    </Link>
                    : <Link to={`/user/${username}`} className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-brand-500 hover:bg-gray-50"
                    >
                      {`${fn} ${ln}`}
                    </Link>}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  )
}
