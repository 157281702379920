import { useSelector } from "react-redux";
import { useContext, useState } from "react";
import { UserXContext } from "../../contexts/userContext";
import { EmptyStateSimple } from "../ui/emptyState";
export default function ConnectionsList() {
    const userdata = useSelector((state) => state.authReducer.userProfile);

    //var userdata = useContext(UserXContext);
    return (
        <div className="bg-white shadow rounded-lg p-6">
            <h2 className="text-2xl font-light text-gray-800 ">Your Contacts</h2>
            <ul>
                {userdata.connections.length>0 ? userdata.connections.map(contact => (
                    <li key={contact.id} className="py-2 border-b border-gray-200 flex items-center justify-between">
                        <div className="flex items-center">
                            <div className="mr-4">
                                <img src={`https://via.placeholder.com/48?text=${contact.first_name[0]}${contact.last_name[0]}`} alt={`${contact.first_name} ${contact.last_name}`} className="rounded-full" />
                            </div>
                            <div>
                                <p className="text-gray-800 font-medium">{contact.first_name} {contact.last_name}</p>
                                <p className="text-gray-600 text-sm">{contact.name}</p>
                            </div>
                        </div>
                        <a href={contact.user_urlname} className="text-blue-600 hover:text-blue-800 transition duration-300 ease-in-out">View Profile</a>
                    </li>
                )) : <EmptyStateSimple description="You don't have any connections yet"/> }
            </ul>
        </div>
    );
};