import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { getPortfolioPostsByUser } from "../../services/apiCalls";
import { useSelector } from "react-redux";

function getFirstTextPreview(contentArray) {
  // Find the first text-type content block
  const firstTextBlock = contentArray ? contentArray.find(content => content.type === 'text') : null;

  if (firstTextBlock && firstTextBlock.content && firstTextBlock.content.text) {
    // Return the first 200 characters of the text block
    // Use .trim() to remove any leading/trailing whitespace and then slice
    return firstTextBlock.content.text.trim().slice(0, 200);
  }
  // Return a default message or empty string if no suitable text block is found
  return 'No preview available';
}


export default function PortfolioUserList() {
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { username } = useParams();
  const user_url = useSelector((state) => state.authReducer.userProfile?.urlname);
  const editable = username === user_url;
  const isReady = username !== undefined;

  useEffect(() => {
    const fetchArticles = async () => {
      if (isReady) {
        setIsLoading(true);
        setError(null);
        try {
          const fetchedArticles = await getPortfolioPostsByUser(username);
          setPosts(fetchedArticles);
          setIsLoading(false);
        } catch (error) {
          setError(error.message || 'An error occurred');
          //console.error('Failed to fetch articles', error);
          // Handle error (e.g., navigate to an error page or display a message)
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchArticles();
  }, [isReady]);

  return (
    <div className="bg-white py-12 sm:py-12">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Portfolio</h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            Projects, experiences, achievements, and anything that relates to a user can be found here.
          </p>
          <div className="mt-10 space-y-8 border-t border-gray-200 pt-10 sm:mt-8 sm:pt-8">
            {editable ?
              <Link to="/portfolio/editor/" className="group flex justify-center items-center space-x-4 border border-gray-300 shadow-sm mx-auto p-4 w-full text-sm font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50">
                <span>Add a Portfolio Entry</span>
              </Link>
              : null}

            {posts.length ? posts.map((post) => (
              <article key={post.id} className="group flex flex-col items-start pt-6">
                <div className="flex items-center justify-between w-full">
                  <time dateTime={new Date(post.updated_at).toLocaleDateString('en-US')} className="text-xs text-gray-500">
                    {new Date(post.updated_at).toLocaleDateString('en-US')}
                  </time>
                  {editable ? <Link to={"/portfolio/editor/" + post.id} className={`text-xs font-semibold ${editable ? 'text-indigo-600 hover:text-indigo-500' : 'text-gray-400'}`}>
                    Edit Article
                  </Link> : null}

                </div>
                <div className="mt-2">
                  <h3 className="text-xl font-semibold text-gray-900 hover:text-indigo-600">
                    <Link to={"/portfolio/read/" + post.id}>
                      {post.title}
                    </Link>
                  </h3>
                  <p className="mt-1 text-sm text-gray-600"><div dangerouslySetInnerHTML={{ __html: getFirstTextPreview(post.content) }} /></p>
                </div>
                <div className="mt-4 flex items-center gap-4">
                  {/* Ensure you handle the case where `post.author.imageUrl` might be null or undefined */}
                  {/* <img src={post.author.imageUrl} alt="" className="h-10 w-10 rounded-full bg-gray-50" /> */}
                  <div className="text-sm">
                    <p className="font-medium text-gray-900">
                      <Link to={"../user/" + post.user_urlname}>
                        {post.first_name + " " + post.last_name}
                      </Link>
                    </p>
                  </div>
                </div>
              </article>
            ) ): <p className="text-gray-600">No Portfolio Entries</p>}
          </div>
        </div>
      </div>
    </div>
  );
}  