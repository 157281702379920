import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
// actions.js
import apiCall from "../../utilities/api";
const sourceURL = "server";
let rootURL;
if (sourceURL == "localhost") {
    rootURL = "http://localhost:3001/v3/";
} else {
    rootURL = "https://goskilling-node-wju6y.ondigitalocean.app/v3/";
}

// ✅ 
export const communitiesSuccess = (data, way) => {
    if (way == "login") {
        localStorage.setItem('auth_token', data.token); // Set token in localStorage
    }
    return {
        type: 'COMMUNITIES_SUCCESS',
        payload: data,
    };
};

export const communitiesError = (data, way) => {
    return {
        type: 'COMMUNITIES_ERROR',
        payload: data,
    };
};



export const getCommunities = () => {
    const token = localStorage.getItem('auth_token');
    return async (dispatch, getState) => {
        try {
            const data = await apiCall('GET', `${rootURL}communities/community`, null, {
                'Authorization': `Bearer ${token}`,
            });
            const currentState = getState().communityReducer;
            // Check if communities is defined
            if (currentState.communities) {
                const currentData = currentState.communities.find(c => c.company_id === data.company_id);
                // Only dispatch if data has changed
                if (JSON.stringify(currentData) !== JSON.stringify(data)) {
                    dispatch(communitiesSuccess(data));
                }
            } else {
                // If communities is undefined, dispatch the action anyway
                dispatch(communitiesSuccess(data));
            }

            const successMessage = data.message;
            toast.success(successMessage, { theme: "colored" });
        } catch (error) {
            dispatch(communitiesError(error.response ? error.response.data.message : 'Unknown error'));
            const errorMessage = getState().authReducer.error;
            //console.log(errorMessage);
            toast.error(errorMessage, { theme: "colored" });
        }
    };
};


export const communitySuccess = (data, companyId) => {
    return {
        type: 'COMMUNITY_SUCCESS',
        payload: { companyId, data },
    };
};

export const communityError = (data) => {
    return {
        type: 'COMMUNITIES_ERROR',
        payload: data,
    };
};

export const getCommunityPosts = (companyId) => {
    const token = localStorage.getItem('auth_token');
    return async (dispatch, getState) => {
        try {
            const response = await apiCall('GET', rootURL + 'communities/community/' + companyId, null, {
                'Authorization': `Bearer ${token}`,
            });
            if (response.status === "success") {
                // Pass only the 'data' part of the response along with companyId
                dispatch(communitySuccess(response.data, companyId));
                const successMessage = response.message;
                toast.success(successMessage, { theme: "colored" });
            } else {
                throw new Error(response.message);
            }
        } catch (error) {
            dispatch(communityError(error.response ? error.response.data.message : 'Unknown error'));
            const errorMessage = getState().authReducer.error;
            //console.log(errorMessage)
            toast.error(errorMessage, { theme: "colored" });
        }
    };
};

export const communityPosting = ({companyId,post}) => {

    return async (dispatch, getState) => {
        const token = localStorage.getItem('auth_token');
        const content  =post.content;
        try {
            const response = await apiCall('POST', rootURL + 'communities/community', {company_id:companyId,content:content}, {
                'Authorization': `Bearer ${token}`,
            });

            if (response.status === "success") {
                // Pass only the 'data' part of the response along with companyId
                
                dispatch({
                    type: 'ADD_POST',
                    payload: { companyId, content }
                });
                const successMessage = response.message;
                dispatch(getCommunities());
                toast.success(successMessage, { theme: "colored" });
            } else {
                throw new Error(response.message);
            }
        } catch (error) {
            toast.error('Unable to submit post.', { theme: "colored" });
        }
    };
}