import { useForm } from "react-hook-form"
import { updatePassword, submitNewEmail } from "../services/apiCalls";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDocumentTitle } from "../services/titleChanger";
export default function Settings() {
    useDocumentTitle('Settings | GoSkilling');
    return <div className="relative flex flex-col gap-y-6 p-4 bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
        <PasswordForm/>
        <EmailForm/>
    </div>
}
const passwordDefaultValues = {
    oldPassword: "",
    newPassword: "",
    email: ""
}



const PasswordForm = () => {
    //const { register, handleSubmit, reset, formState: { errors, isValid } } = useForm();
    const { register: registerPassword, handleSubmit: passwordHandleSubmit, reset: resetPassword, formState: { errors: passwordError, isValid: passwordIsValid } } = useForm({defaultValues: passwordDefaultValues});

    const changePassword = async (data) => {
        try {
          const response = await updatePassword(data);
          
          if (response.status === "success") {
            //console.log("Password updated successfully!");
            resetPassword(passwordDefaultValues);
            toast[response.status](response.message, { theme: "colored" });
            // You can add additional logic here
          } else if (response.status === "error") {
            //console.log("Resource not found");
            toast[response.status](response.message, { theme: "colored" });
            // Handle 404 error
          } else {
            //console.log("Something went wrong");
            toast.error("Something went wrong.", { theme: "colored" });
            // Handle other types of negative responses
          }
      
        } catch (error) {
          //console.error("An error occurred:", error);
          // Handle unexpected errors here
        }
      };
    return <form onSubmit={passwordHandleSubmit(changePassword)}>
    New Password
    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
        <div className="sm:col-span-5">
            <label htmlFor="oldPassword" className="block text-sm font-medium leading-6 text-gray-700">
                Current Password
            </label>
            <input
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6"
                type="password"
                id="oldPassword"
                name="oldPassword"
                {...registerPassword("oldPassword")}
            />

        </div>
        <div className="sm:col-span-5">
            <label htmlFor="newPassword" className="block text-sm font-medium leading-6 text-gray-700">
                New Password
            </label>
            <input
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6"
                type="password"
                id="newPassword"
                name="newPassword"
                {...registerPassword("newPassword", {
                    required: "This is required",
                    minLength: {
                        value: 8,
                        message: "Password must have at least 8 characters.",
                    },
                    pattern: {
                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).*$/,
                        message: "Password must contain one capital letter, one number, and one symbol",
                    },
                })}
            />
            {passwordError.newPassword && <p>{passwordError.newPassword.message}</p>}

        </div>
        <div className="sm:col-span-5">
            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-700">
                Confirm Email
            </label>
            <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6"
                {...registerPassword("email", {
                    required: "This is required",
                    pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                })}
            />

        </div>
        <div className="sm:col-span-3">
            <button type="submit" disabled={!passwordIsValid}  className="flex w-full mx-auto mt-4 mb-2 items-center justify-center rounded-md disabled:bg-brand-100 disabled:text-gray-800 bg-secondary-100 border-gray-700  px-3 py-2 text-sm font-semibold text-gray-50 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-secondary-200 hover:text-white focus-visible:outline-offset-0">Change Password</button>
        </div>

    </div>
</form>
}
const emailDefaultValues = {
    newEmail: ""
}
const EmailForm = () => {
    const { register: registerEmail, handleSubmit: emailHandleSubmit, reset: resetEmail, formState: { errors: emailError, isValid: emailIsValid } } = useForm({ defaultValues: emailDefaultValues});

    const changeEmail = async (data) => {
        //const { firstName, lastName, ...restOfData } = data;
        //dispatch(changeProfile(restOfData));
        try {
            const response = await submitNewEmail(data);
            
            if (response.status === "success") {
              //console.log("Email updated successfully!");
              resetEmail( emailDefaultValues )
              toast[response.status](response.message, { theme: "colored" });
              // You can add additional logic here
            } else if (response.status === "error") {
              //console.log("Resource not found");
              toast[response.status](response.message, { theme: "colored" });
              // Handle 404 error
            } else {
              //console.log("Something went wrong");
              toast.error("Something went wrong", { theme: "colored" });
              // Handle other types of negative responses
            }
        
          } catch (error) {
            //console.error("An error occurred:", error);
            // Handle unexpected errors here
          }
        //console.log(data)
    }
    return <form onSubmit={emailHandleSubmit(changeEmail)}>
    Email
    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
        <div className="sm:col-span-5">
            <label htmlFor="newEmail" className="block text-sm font-medium leading-6 text-gray-700">
                Change Email
            </label>
            <input
                id="newEmail"
                name="newEmail"
                type="email"
                autoComplete="email"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6"
                {...registerEmail("newEmail", {
                    required: "This is required",
                    pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                })}
            />

        </div>

        <div className="sm:col-span-3">
            <button type="submit" disabled={!emailIsValid} className="flex w-full mx-auto mt-4 mb-2 items-center justify-center rounded-md disabled:bg-brand-100 disabled:text-gray-800 bg-secondary-100 border-gray-700  px-3 py-2 text-sm font-semibold text-gray-50 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-secondary-200 hover:text-white focus-visible:outline-offset-0">Update</button>
        </div>

    </div>
</form>
}