// api.js
import axios from 'axios';

const apiCall = async (method, url, data, headers = {}) => {
  try {
    const response = await axios({ method, url, data, headers });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export default apiCall;
