import { useContext } from "react";
import { UserXContext } from "../../contexts/userContext";

const values = [1, 2, 3, 4, 5, 0]

export default function SkillAssessment({ user, onSkillAssessment, onSkillReview, progress }) {
    return (
        <div className=" ">
            <div className="container mx-auto p-6 bg-white rounded-lg shadow">
                <h1 className="text-2xl font-light text-gray-800">Skill Assessment</h1>
                <p className="text-gray-600 mt-2">Does the user have a higher skill quality than you in the following?</p>

                {/* Progress Bar */}
                <div className="w-full bg-gray-200 rounded-full h-2.5 mt-4">
                    <div className="bg-green-400 h-2.5 rounded-full" style={{ width: `${progress}%` }}></div>
                </div>

                <div className="mt-6">
                    {user.data.skills.map((skill, index) => (
                        <div key={index} className="flex items-center mb-4 p-4 rounded-lg border border-gray-200">
                            <span className="flex-grow text-gray-700">{skill.name + " - " + skill.category}</span>
                            {/* IO */}
                            {/* <button
                                className={`btn ${skill.assessed === true ? 'bg-green-300' : 'bg-green-100'} text-green-800 px-4 py-2 rounded-full hover:bg-green-200 mr-2 focus:outline-none focus:ring-2 focus:ring-green-200 `}
                                onClick={() => onSkillReview(skill.id, true)}
                            >
                                Yes
                            </button>
                            <button
                                className={`btn ${skill.assessed === false ? 'bg-red-300' : 'bg-red-100'} text-red-800 px-4 py-2 rounded-full hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-200`}
                                onClick={() => onSkillReview(skill.id, false)}
                            >
                                No
                            </button> */}


                            {/* 1-5 + Skip */}

                            {values.map(function (data) {
                                return <button
                                    className={`btn ${skill.assessed === data ? 'bg-brand-600 text-white' : 'bg-brand-200 text-brand-800'} text-white px-4 py-2 rounded-full hover:bg-green-200 mr-2 focus:outline-none focus:ring-2 focus:ring-green-200 `}
                                    onClick={() => onSkillReview(skill.id, data)}
                                >
                                    {data != 0 ? data : "Skip"}
                                </button>
                            })
                            }
                           
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
