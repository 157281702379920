import { useEffect, useState, useReducer, useContext } from 'react';
import './App.css';
import SplashScreen from './components/SplashScreen';
import { useDispatch, useSelector } from 'react-redux';
import PublicLayout from './components/layouts/PublicLayout';
import SplashScreenLayout from './components/layouts/SplashScreenLayout';
import RestrictedLayout from './components/layouts/RestrictedLayout';
import { ToastContainer } from 'react-toastify';
import { HelmetProvider } from 'react-helmet-async';
import { SidebarProvider } from './services/sidebarcontext';
import { checkAuth, getJobs } from './redux/actions/loginActions';
import { UserXContext, UserDispatchContext, UserProvider } from './contexts/userContext';
import { getSkills } from './redux/actions/skillsActions';
import { Routing } from './components/routes/Routing';
import { PageDataDispatchContext, PageDataProvider } from './contexts/dataContext';
import { getPageSkills } from './contexts/dataActions';
import ScrollToTop from './services/scrollToTop';
const AuthenticatedLayoutWrapper = ({ children }) => {
  const isAuthenticated = useSelector((state) => state.authReducer.isAuthenticated);

  return (
    isAuthenticated ?
      <RestrictedLayout>
        {children}
      </RestrictedLayout>
      :
      <PublicLayout>
        {children}
      </PublicLayout>
  );
};


function App() {

  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.authReducer.isAuthenticated);

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        await Promise.all([
          dispatch(getJobs()),
          dispatch(getSkills()),
          dispatch(checkAuth()),

        ]);
        setIsLoading(false);
      } catch (error) {
        setHasError(true);
        setIsLoading(false);
      }
    };

    loadInitialData();
  }, []);

  if (isLoading || hasError) {
    return <SplashScreenLayout><SplashScreen hasError={hasError} /></SplashScreenLayout>;
  }

  return (
    <HelmetProvider>
      <SidebarProvider>
        <ToastContainer />
        <ScrollToTop />
        {/* <AuthenticatedLayoutWrapper> */}
        <Routing />
        {/* </AuthenticatedLayoutWrapper> */}
      </SidebarProvider>
    </HelmetProvider>

  )
}

export default App;
