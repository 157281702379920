import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { registerUser } from "../redux/actions/loginActions";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useForm } from "react-hook-form";
import { useDocumentTitle } from "../services/titleChanger";

import logo from './../assets/images/brand/goskilling_general.png';
import SEO from "../utilities/SEO";
export default function Register() {
    useDocumentTitle('Register | GoSkilling');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm();


    const onSubmit = async (data) => {
        try {
            const response = await dispatch(registerUser(data));
            //console.log(response);

            if (response && response.status === "success") {
                navigate("/");
            }
        } catch (error) {
            //console.log("An error occurred:", error);
        }
    };



    return (
        <>
            <SEO title="Register now | GoSkilling" />
            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 bg-white">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <Link to="/">

                        <img
                            className="mx-auto h-10 w-auto"
                            src={logo}
                            alt="GoSkilling"
                        />
                    </Link>
                    <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        Register account
                    </h2>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                        <div>
                            <label htmlFor="first_name" className="block text-sm font-medium leading-6 text-gray-900">
                                First Name
                            </label>
                            <div className="mt-2">
                                <input
                                    id="first_name"
                                    name="first_name"
                                    type="text"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6"
                                    {...register("first_name", {
                                        required: "This is required",
                                        minLength: {
                                            value: 1,
                                        }
                                    })}
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="last_name" className="block text-sm font-medium leading-6 text-gray-900">
                                Last Name
                            </label>
                            <div className="mt-2">
                                <input
                                    id="last_name"
                                    name="last_name"
                                    type="text"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6"
                                    {...register("last_name", {
                                        required: "This is required",
                                        minLength: {
                                            value: 1,
                                        }
                                    })}
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="personal_email" className="block text-sm font-medium leading-6 text-gray-900">
                                Personal Email address
                            </label>
                            <div className="mt-2">
                                <input
                                    id="personal_email"
                                    name="personal_email"
                                    type="email"
                                    autoComplete="personal_email"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6"
                                    {...register("personal_email", {
                                        required: "This is required",
                                        pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                    })}
                                />
                            </div>
                            {errors.personal_email && <p>{errors.personal_email.message}</p>}

                        </div>

                        <div>
                            <div className="flex items-center justify-between">
                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                    Password
                                </label>
                            </div>
                            <div className="mt-2">
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="current-password"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand-600 sm:text-sm sm:leading-6"
                                    {...register("password", {
                                        required: "This is required",
                                        minLength: {
                                            value: 8,
                                            message: "Password must have at least 8 characters.",
                                        },
                                        pattern: {
                                            value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).*$/,
                                            message: "Password must contain one capital letter, one number, and one symbol",
                                        },
                                    })}
                                />
                                <p className="text-sm text-gray-600">
                                    Password must be at least 8 characters and include a lowercase letter, an uppercase letter, a number, and a special character.
                                </p>
                                {errors.password && <p>{errors.password.message}</p>}
                            </div>
                        </div>

                        {/* <div>
                            <label htmlFor="work_email" className="block text-sm font-medium leading-6 text-gray-900">
                                Optional: Work Email address
                            </label>
                            <div className="mt-2">
                                <input
                                    id="work_email"
                                    name="work_email"
                                    type="email"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6"
                                    {...register("work_email", {
                                        pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                    })}
                                />
                            </div>
                            {errors.work_email && <p>{errors.work_email.message}</p>}

                        </div> */}
                        <div className='flex space-x-4'>
                            <input

                                name="agreetoterms"
                                value={true}
                                type="checkbox"
                                //onClick={() => { setEmailChecked(false); setUrlChecked(false); setCompany(false); setFormReady(false); }}
                                {...register("agreetoterms", {
                                    required: "This is required"
                                })}
                            /><label htmlFor="agreetoterms">I agree to the <Link className="text-bold text-secondary-600" to="/terms">Terms of Service</Link> and <Link className="text-bold text-secondary-600" to="/privacy">Privacy Policy.</Link></label>
                        </div>
                        <p className="text-secondary-500">Note: after registration you need to confirm and verify your email within 48 hours. Check your email for the code.</p>
                        <div>
                            <button
                                type="submit"
                                disabled={!isValid}
                                className={!isValid ? "flex w-full justify-center rounded-md bg-brand-400 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600" : "flex w-full justify-center rounded-md bg-brand-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-brand-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600"}
                            >
                                Register
                            </button>
                        </div>
                    </form>

                    <p className="mt-10 text-center text-sm text-gray-500">
                        Already registered?{' '}
                        <Link to="/login" className="font-semibold leading-6 text-secondary-600 hover:text-secondary-500">
                            Log in here!
                        </Link>
                    </p>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}
