import React, { Fragment, useState, useEffect, useContext,createContext } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { NavLink, Link, useMatch } from 'react-router-dom'
import {
  Bars3Icon,
  BellIcon,
  CalendarIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  EnvelopeIcon,
  UserGroupIcon,
  HomeIcon,
  UsersIcon,
  WrenchIcon,
  XMarkIcon,

} from '@heroicons/react/24/outline'
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { brand_logo } from '../../arrayImages/brand.logo'
import { useSelector } from 'react-redux'
import { searchBarItems } from '../../services/apiCalls'
import { SidebarContext } from '../../services/sidebarcontext'
import { restrictedRoutes } from '../routes/RouteLedger'
const teams = [
  { id: 0, name: 'Core', href: '/', initial: 'C', current: false },
  { id: 1, name: 'Recruiter', href: '/recruiter', initial: 'R', current: false },
  { id: 2, name: 'Profiler', href: '#', initial: 'P', current: false },
  { id: 3, name: 'Data', href: '#', initial: 'D', current: false },
  { id: 4, name: 'Blue', href: '#', initial: 'B', current: false },
]
const userNavigation = [
  { name: 'Your profile', href: 'user' },
  { name: 'Sign out', href: 'logout' },
]

const sidebarNavigation = [
  { id: 0, name: 'Dashboard', href: '/', icon: HomeIcon },
  { id: 1, name: 'Work Emails', href: '/workemails', icon: EnvelopeIcon },
  { id: 2, name: 'Skills', href: '/skills', icon: WrenchIcon },
  { id: 3, name: 'Profile', href: '/user', icon: UsersIcon },
  { id: 4, name: 'Community', href: '/communities', icon: UserGroupIcon},
  { id: 5, name: 'Userview', href: '/userview', icon: UserGroupIcon},
  { id: 6, name: 'New', href: '/uv/ck', icon: UserGroupIcon},

]
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function TeamsMobile() {
  const { navItems, setNavItems } = useContext(SidebarContext);

  return <li>
    <div className="text-xs font-semibold leading-6 text-gray-400">Tools</div>
    <ul role="list" className="-mx-2 mt-2 space-y-1">
      {teams.map((team) => (
        <li key={team.name}>
          <Link
            to={team.href}
            onClick={() => { setNavItems(team.name); }}
            className={classNames(
              team.current
                ? 'bg-gray-800 text-white'
                : 'text-gray-400 hover:text-white hover:bg-gray-800',
              'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
            )}
          >
            <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
              {team.initial}
            </span>
            <span className="truncate">{team.name}</span>
          </Link>
        </li>
      ))}
    </ul>
  </li>

}

function TeamsDesktop() {
  const { navItems, setNavItems } = useContext(SidebarContext);

  return <li>
    <div className="text-xs font-semibold leading-6 text-gray-400">Tools</div>
    <ul role="list" className="-mx-2 mt-2 space-y-1">
      {teams.map((team) => (
        <li key={team.name}>
          <Link
            to={team.href}
            onClick={() => { setNavItems(team.name); }}
            className={classNames(
              team.current
                ? 'bg-gray-800 text-white'
                : 'text-gray-400 hover:text-white hover:bg-gray-800',
              'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
            )}
          >
            <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
              {team.initial}
            </span>
            <span className="truncate">{team.name}</span>
          </Link>
        </li>
      ))}
    </ul>
  </li>
}

const SidebarMobileItem = ({ route }) => {
  const match = useMatch(route.path);

  return (
    <li key={route.name}>
      <NavLink
        to={route.path}
        className={classNames(
          route.current
            ? 'bg-gray-800 text-white'
            : 'text-gray-400 hover:text-white hover:bg-gray-800',
          'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
        )}
      >
        {route.icon && <route.icon className="h-6 w-6 shrink-0" aria-hidden="true" />}
        {route.name}
      </NavLink>
    </li>
  );
};

function SidebarMobile() {
  const isRecruiter = useSelector((state) => state.authReducer?.userProfile?.recruiting);
  const isAuthenticated = useSelector((state) => state.authReducer?.isAuthenticated);
  const { navItems, setNavItems } = useContext(SidebarContext);
  const activeDataOnly = false;
  const activeProfilerOnly = true;

  let activeRecruiter = true;

  // Prepare the restricted route items
  const restrictedItems = Object.keys(restrictedRoutes)
    .filter((key) => {
      const route = restrictedRoutes[key];
      return (
        (route.category === navItems) && (
          (route.recruiterOnly && activeRecruiter) ||
          (route.dataOnly && activeDataOnly) ||
          (route.profilerOnly && activeProfilerOnly) ||
          (route.category === 'Core' && isAuthenticated)
        )
      );
    })
    .map((key) => {
      const route = restrictedRoutes[key];
      if (route && route.component && route.sidebar) {
        return <SidebarMobileItem key={key} route={route} />;
      }
      return null;
    })
    .filter(Boolean);

  // Prepare Core items (fallback)
  const coreItems = Object.keys(restrictedRoutes)
    .filter((key) => restrictedRoutes[key].category === 'Core' && isAuthenticated)
    .map((key) => {
      const route = restrictedRoutes[key];
      if (route && route.component && route.sidebar) {
        return <SidebarMobileItem key={key} route={route} />;
      }
      return null;
    })
    .filter(Boolean);

  // Decide which items to render
  const itemsToRender = restrictedItems.length > 0 ? restrictedItems : coreItems;

  return (
    <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-secondary-800 px-6 pb-4 ring-1 ring-white/10">
      <div className="flex h-16 shrink-0 items-center">
        <img
          className="h-8 w-auto"
          src={brand_logo.logo}
          alt={brand_logo.name}
        />
      </div>
      <nav className="flex flex-1 flex-col">
        <ul role="list" className="flex flex-1 flex-col gap-y-7">
          <li>
            <ul role="list" className="-mx-2 space-y-1">
            {sidebarNavigation.map((route,index) => (
                    <li key={route.name}>
                    <NavLink
                      to={route.href}
                      className={classNames(
                        route.current
                          ? 'bg-gray-800 text-white'
                          : 'text-gray-400 hover:text-white hover:bg-gray-800',
                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                      )}
                    >
                      {route.icon && <route.icon className="h-6 w-6 shrink-0" aria-hidden="true" />}
                      {route.name}
                    </NavLink>
                  </li>
                //itemsToRender
            ))
            }
              {

              }

            </ul>
          </li>
          {/* <TeamsMobile /> */}
          <li className="mt-auto">
            <Link
              to="settings"
              className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
            >
              <Cog6ToothIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
              Settings
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
}


const SidebarDesktopItem = ({ route }) => {
  const match = useMatch(route.path);

  return (
    <li>
      <NavLink
        to={route.path}
        className={classNames(
          match
            ? 'bg-gray-800 text-accent-500'
            : 'text-gray-400 hover:text-white hover:bg-gray-800',
          'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
        )}
      >
        {/* <route.icon className="h-6 w-6 shrink-0" aria-hidden="true" /> */}
        {route.icon && <route.icon className="h-6 w-6 shrink-0" aria-hidden="true" />}
        {route.name}
      </NavLink>
    </li>
  );
};

// function SidebarDesktop() {
//   const isRecruiter = useSelector((state) => state.authReducer?.userProfile?.recruiting);
//   const isAuthenticated = useSelector((state) => state.authReducer?.isAuthenticated);
//   const { navItems, setNavItems } = useContext(SidebarContext);
//   const activeDataOnly = false;
//   const activeProfilerOnly = true;
//   console.log(navItems)
//   //let activeRecruiter = isRecruiter === 1;
//   let activeRecruiter = true;
//   console.log(activeRecruiter+" "+navItems)
//   {/* Sidebar component, swap this element with another sidebar if you like */ }
//   return <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-secondary-800 px-6 pb-4">
//     <div className="flex h-16 shrink-0 items-center">
//       <img
//         className="h-8 w-auto"
//         src={brand_logo.logo}
//         alt={brand_logo.name}
//       />{isAuthenticated}
//     </div>
//     <nav className="flex flex-1 flex-col">
//       <ul role="list" className="flex flex-1 flex-col gap-y-7">
//         <li>
//           <ul role="list" className="-mx-2 space-y-1">
//           {
//   Object.keys(restrictedRoutes)
//     .filter((key) => {
//       const route = restrictedRoutes[key];
//       return (
//         (route.category === navItems) && (
//           (route.recruiterOnly && activeRecruiter) ||
//           (route.dataOnly && activeDataOnly) ||
//           (route.profilerOnly && activeProfilerOnly) ||
//           (route.category === 'Core' && isAuthenticated)
//         )
//       );
//     })
//     .map((key) => {
//       const route = restrictedRoutes[key];
//       if (route && route.component && route.sidebar) {
//         return <SidebarDesktopItem key={key} route={route} />;
//       }

//       // If no conditions are met, return null
//       return null;
//     })
// }



//             {/* {
//               Object.keys(isRecruiter ? recruiterRoutes : recruiterRoutes).map((key) => {
//                 const route = isRecruiter ? recruiterRoutes[key] : recruiterRoutes[key];
//                 if (route.sidebar) {
//                   return <SidebarDesktopItem key={key} route={route} />
//                 }
//               })
//             } */}
//           </ul>
//         </li>
//         <TeamsDesktop />
//         <li className="mt-auto">
//           <Link
//             to="settings"
//             className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
//           >
//             <Cog6ToothIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
//             Settings
//           </Link>
//         </li>
//       </ul>
//     </nav>
//   </div>
// }

function SidebarDesktop() {
  const isRecruiter = useSelector((state) => state.authReducer?.userProfile?.recruiting);
  const isAuthenticated = useSelector((state) => state.authReducer?.isAuthenticated);
  const { navItems, setNavItems } = useContext(SidebarContext);
  const activeDataOnly = false;
  const activeProfilerOnly = true;

  let activeRecruiter = true;

  // Prepare the restricted route items
  const restrictedItems = Object.keys(restrictedRoutes)
    .filter((key) => {
      const route = restrictedRoutes[key];
      return (
        (route.category === navItems) && (
          (route.recruiterOnly && activeRecruiter) ||
          (route.dataOnly && activeDataOnly) ||
          (route.profilerOnly && activeProfilerOnly) ||
          (route.category === 'Core' && isAuthenticated)
        )
      );
    })
    .map((key) => {
      const route = restrictedRoutes[key];
      if (route && route.component && route.sidebar) {
        return <SidebarDesktopItem key={key} route={route} />;
      }
      return null;
    })
    .filter(Boolean);

  // Prepare Core items (fallback)
  const coreItems = Object.keys(restrictedRoutes)
    .filter((key) => restrictedRoutes[key].category === 'Core' && isAuthenticated)
    .map((key) => {
      const route = restrictedRoutes[key];
      if (route && route.component && route.sidebar) {
        return <SidebarDesktopItem key={key} route={route} />;
      }
      return null;
    })
    .filter(Boolean);

  // Decide which items to render
  const itemsToRender = restrictedItems.length > 0 ? restrictedItems : coreItems;

  return (
    <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-secondary-800 px-6 pb-4">
      <div className="flex h-16 shrink-0 items-center">
        <img
          className="h-8 w-auto"
          src={brand_logo.logo}
          alt={brand_logo.name}
        />
        {isAuthenticated}
      </div>
      <nav className="flex flex-1 flex-col">
        <ul role="list" className="flex flex-1 flex-col gap-y-7">
          <li>
            <ul role="list" className="-mx-2 space-y-1">
              {sidebarNavigation.map((item, index) => (
                //<SidebarDesktopItem key={index} route={item}/>
                <li key={item.name}>
                  <NavLink
                    to={item.href}
                    className={({ isActive }) => isActive ? 'bg-gray-800 text-accent-500 group flex gap-x-3 rounded-md p-3 text-sm leading-6 font-semibold' : 'text-gray-400 hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md p-3 text-sm leading-6 font-semibold'}

                  >
                    <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                    {item.name}
                  </NavLink>
                </li>
              ))}

            </ul>
          </li>
          {/* <TeamsDesktop /> */}
          <li className="mt-auto">
            <Link
              to="settings"
              className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
            >
              <Cog6ToothIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
              Settings
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
}



function Topbar() {
  const userProfile = useSelector((state) => state.authReducer.userProfile);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState(false);


  useEffect(() => {
    if (searchQuery) {
      const fetchData = async () => {
        // Replace with your actual API call
        const response = await searchBarItems(searchQuery);
        if (response.status === "success") {
          setSearchResults(response.data);
        }
      };

      fetchData();
    } else {
      setSearchResults([]);
    }
  }, [searchQuery]);
  return <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
    <form className="relative flex flex-1" action="#" method="GET">
      <label htmlFor="search-field" className="sr-only">
        Search
      </label>
      <MagnifyingGlassIcon
        className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
        aria-hidden="true"
      />
      <input
        id="search-field"
        className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
        placeholder="Search..."
        type="search"
        name="search"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
    </form>
    {
      searchResults.length > 0 &&
      <div className="absolute top-full mt-2 w-full sm:w-4/5 bg-white border border-gray-300 rounded">
        {searchResults.length > 0 && (
          <div className="absolute z-10 mt-2 w-full bg-white border border-gray-300 rounded">
            {searchResults.map((item, index) => (
              <div key={index} className="p-2 hover:bg-gray-200">
                <img src={item.logo} alt={item.name} className="w-6 h-6 inline-block mr-2" />
                <span>{item.name}</span>
                <span className="ml-2 text-sm text-gray-500">({item.company})</span>
              </div>
            ))}
          </div>
        )}
      </div>
    }

    <div className="flex items-center gap-x-4 lg:gap-x-6">
      <button type="button" className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500">
        <span className="sr-only">View notifications</span>
        <BellIcon className="h-6 w-6" aria-hidden="true" />
      </button>

      {/* Separator */}
      <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10" aria-hidden="true" />

      {/* Profile dropdown */}
      <Menu as="div" className="relative">
        <Menu.Button className="-m-1.5 flex items-center p-1.5">
          <span className="sr-only">Open user menu</span>
          <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-secondary-500">
            <span className="font-medium leading-none text-white">{userProfile ? userProfile.firstName.charAt(0) + userProfile.lastName.charAt(0) : ""}</span>
          </span>
          <span className="hidden lg:flex lg:items-center">
            <span className="ml-4 text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">
              {userProfile ? userProfile.firstName + " " + userProfile.lastName : ""}
            </span>
            <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
            {userNavigation.map((item) => (
              <Menu.Item key={item.name}>
                {({ active }) => (
                  <Link
                    to={item.href}
                    className={classNames(
                      active ? 'bg-gray-50' : '',
                      'block px-3 py-1 text-sm leading-6 text-gray-900'
                    )}
                  >
                    {item.name}
                  </Link>
                )}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  </div>
}


const UserContext = createContext();
export default function RestrictedLayout({ children }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [profileView, setProfileView] = useState("profile");
  const [viewMode,setViewMode] = useState("visitor"); //visitor //owner //registered
  const isAuthenticated = useSelector((state) => state.authReducer.isAuthenticated);

  return (
    <>
      <div>
        {/* Static sidebar for mobile */}
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <SidebarMobile />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <SidebarDesktop />
        </div>

        <div className="lg:pl-72 bg-slate-100 min-h-full">
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>

            {/* Separator */}
            <div className="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true" />

            {/* Top Navigation */}
            <Topbar />
          </div>

          <main className="py-10 min-h-full">
          <UserContext.Provider value={{profileControl: [profileView,setProfileView], viewingMode: [viewMode, setViewMode] }}>

            <div className="px-4 sm:px-6 lg:px-8 min-h-full">{children}</div>
            </UserContext.Provider>
          </main>
        </div>
      </div>

    </>
  )
}
