// SplashScreenLayout.js
import React from 'react';

const SplashScreenLayout = ({ children }) => {
  return (
    <div className="min-h-screen w-full flex items-center justify-center bg-gray-100">
      {children}
    </div>
  );
};

export default SplashScreenLayout;