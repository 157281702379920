import React from 'react';
import Footer from '../Footer';
import {PublicHeader} from '../Header';

const PublicLayout = ({ children }) => {
  return (
    <div className="min-h-screen ">
        <PublicHeader/>
      {/* Content */}
      <div className="mx-auto ">
        {children}
      </div>
      <Footer/>
    </div>
  );
};

// const PublicLayout = ({ children }) => {
//   return (
//     <div className="min-h-screen ">
//         <PublicHeader/>
//       {/* Content */}
//       <div className="container mx-auto ">
//         {children}
//       </div>
//       <Footer/>
//     </div>
//   );
// };



export default PublicLayout;