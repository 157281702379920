import {
    Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Tooltip
} from 'recharts';
import { useSelector } from "react-redux";
export default function PublicUserSoftSkillsSummary({softSkills}) {
    // Normalize the skills data for the radar chart
    //var userdata = useContext(UserXContext);
    //const userdata = useSelector((state) => state.authReducer.userProfile);
    if(softSkills.length===0) {
        return <div className="container mx-auto  py-3 px-2 bg-white rounded-lg shadow">The user has no soft skill reviews yet.</div>
    }
    const radarData = softSkills.map(skill => ({
        subject: skill.Skill,
        A: skill.count,
        fullMark: 10, // Assuming 10 is the full mark for each skill
    }));

    return (
        <div className="mx-auto bg-white rounded-lg shadow-sm px-6 pt-6 pb-12">
            <h2 className="text-xl font-light text-gray-800 mb-4">Soft Skills Proficiency</h2>
            <RadarChart outerRadius={90} width={730} height={250} data={radarData}>
                <PolarGrid />
                <PolarAngleAxis dataKey="subject" />
                <PolarRadiusAxis angle={30} domain={[0, 10]} />
                <Radar name="Skills" dataKey="A" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
                <Tooltip />
            </RadarChart>
        </div>
    );
};
