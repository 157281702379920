import logo from './../assets/images/brand/goskilling_general.png';

const brand_logo = {
    name:"GoSkilling",
    logo:logo,
    home:"/",
    founder:"Christian Kramp"

}

export {brand_logo}