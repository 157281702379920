// reducer.js
const initialState = {
    isAuthenticated: !!localStorage.getItem('auth_token'), // Check if token exists in localStorage
    token: null,
    userProfile: null,
    error: null,
    jobs: null,
};

export default function authReducer(state = initialState, action) {
    switch (action.type) {
        case 'LOGIN_SUCCESS':
            return {
                ...state,
                isAuthenticated: true,
                token: action.payload.token,
                userProfile: action.payload.userProfile,
            };
        case 'LOGIN_ERROR':
            return {
                ...state,
                error: action.payload,
            };
        case 'REGISTER_SUCCESS':
            return {
                ...state,
                isAuthenticated: true,
                token: action.payload.token,
                userProfile: action.payload.userProfile,
            };
        case 'REGISTER_ERROR':
            return {
                ...state,
                error: action.payload,
            };
        case 'LOGOUT':
            return {
                ...state,
                isAuthenticated: false,
                token: null,
                userProfile: null,
            };
        case 'AUTH_ERROR':
            return {
                ...state,
                isAuthenticated: false,
                token: null,
                userProfile: null,
                error: action.payload,
            };
        case 'ADD_NEW_EXPERIENCE':
            return {
                ...state,
                isAuthenticated: true,
                userProfile: {
                    ...state.userProfile,
                    experiences: [...state.userProfile.experiences, action.payload],
                },
            };
        case 'REMOVE_EXPERIENCE':
            const newExperiences = state.userProfile.experiences.filter(experience => experience.experience_id !== action.payload);

            return {
                ...state,
                userProfile: {
                    ...state.userProfile,
                    experiences: state.userProfile.experiences.filter(experience => experience.experience_id !== action.payload),
                },
            };
        case 'ADD_NEW_SKILL':
            return {
                ...state,
                userProfile: {
                    ...state.userProfile,
                    userSkills: [...state.userProfile.userSkills, action.payload],
                },
            };
        case 'REMOVE_SKILL':
            //console.log(action.payload)
            return {
                ...state,
                userProfile: {
                    ...state.userProfile,
                    userSkills: state.userProfile.userSkills.filter(skill => skill.skill_id !== action.payload),
                },
            };
        //PROFILE
        case 'UPDATE_NAME':
            return {
                ...state,
                userProfile: {
                    ...state.userProfile,
                    firstName: action.payload.firstName,
                    lastName: action.payload.lastName,
                },
            };

        case 'UPDATE_PROFILE':
            return {
                ...state,
                userProfile: {
                    ...state.userProfile,
                    ...action.payload,
                },
            };
        case 'CHANGE_EXPERIENCE_IS_ACTIVE':
            return {

                ...state,
                userProfile: {
                    ...state.userProfile,
                    experiences: state.userProfile.experiences.map(exp =>
                        exp.experience_id === action.payload ? { ...exp, is_active: 0 } : exp
                    )
                }
            };
            case 'UPDATE_PRIMARY_POSITION':
                return {
    
                    ...state,
                    userProfile: {
                        ...state.userProfile,
                        experiences: state.userProfile.experiences.map(exp =>
                            exp.experience_id === action.payload ? { ...exp, primary_position: 1 } : {...exp, primary_position: 0 }
                        )
                    }
                };
        case 'CHANGE_ALLOW_EMAIL':
            return {
                ...state,
                userProfile: {
                    ...state.userProfile,
                    experiences: state.userProfile.experiences.map(exp =>
                        exp.email === action.payload ? { ...exp, allow_email: 0 } : exp
                    )

                }
            };

        case 'CHANGE_USER_PROFILE_EMAIL':
            return {
                ...state,
                userProfile: {
                    ...state.userProfile,
                    email: action.newEmail
                }

            };

        case 'ADD_WORK_EMAIL':
            return {
                ...state,
                userProfile: {
                    ...state.userProfile,
                    workEmails: [...state.userProfile.workEmails, action.payload]
                }

            };
        case 'CHANGE_RECRUITER_SUBSCRIPTION':
            return {
                ...state,
                userProfile: {
                    ...state.userProfile,
                    recruiting: action.payload
                }
            }
            case 'JOB_SUCCESS':
                return {
                    ...state,
                    jobs: action.payload.data,
                };
            case 'JOB_ERROR':
                return {
                    ...state,
                    error: action.payload,
                };
        default:
            return state;
    }
}

