import { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import SEO from '../../utilities/SEO';
import { getPortfolioEntry } from '../../services/apiCalls';

export default function PortfolioArticle() {

    const [content, setContent] = useState(false);
    const [data,setData] = useState(false);
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [title, setTitle] = useState(false);
    const [selectedSkills, setSelectedSkills] = useState([]);
    // Call useParams here at the top level of your component
    let { articleid } = useParams();

    useEffect(() => {
        const fetchingPost = async () => {
            setIsLoading(true);
            setError(null);

            try {
                // Use articleId directly in your async function
                const data = await getPortfolioEntry(articleid);
                //console.log(data[0]);
                //console.log(data[0].content)
                //setContent(JSON.parse(data[0].content));
                setContent(data[0].content);
                setSelectedSkills(data[0].skills);
                setData(data[0])
                setIsLoading(false);
                setTitle(data[0].title);

            } catch (err) {
                setError(err.message || 'An error occurred');
            } finally {
                setIsLoading(false);
            }
        };
        //console.log(articleid)
        //console.log(content)
        fetchingPost();
    }, [articleid]);

    return (
        <div className="bg-white px-6 py-32 lg:px-8">
                        <SEO title={title+` by `+data.first_name+` `+data.last_name+` | GoSkilling`} description="" name="" type="" />

            {
                isLoading
                    ? "loading..."
                    : error
                        ? "Error."
                        : <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
                            <p className="text-base font-semibold leading-7 text-brand-600">Portfolio Post</p>
                            <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-2">{title}</h1>
                            <p><Link to={"/user/"+data.user_urlname} className="text-base leading-7 text-gray-600">By {data.first_name+" "+data.last_name}</Link> - <Link to={"/portfolio/user/"+data.user_urlname} className="text-base font-semibold leading-7 text-brand-500 font-underline">User Portfolio</Link></p>
                            {content.map(block => (
                                <div key={block.id} style={{ marginBottom: '20px' }}>
                                    {block.type === 'text' && <div dangerouslySetInnerHTML={{ __html: block.content.text }} />}
                                    {block.type === 'image' && <img src={block.content.src} alt={block.content.alt} style={{ maxWidth: '100%' }} />}
                                </div>
                            ))}

                            {Array.isArray(selectedSkills) && selectedSkills.map(skill => (
                                <li key={skill.value} className="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6">

                                    <div className="flex min-w-0 gap-x-4">
                                        <div className="min-w-0 flex-auto">
                                            <p className="text-sm font-semibold leading-6 text-gray-900">
                                                <a href={skill.href}>
                                                    <span className="absolute inset-x-0 -top-px bottom-0" />
                                                    {skill.label}
                                                </a>
                                            </p>
                                            <p className="mt-1 flex text-xs leading-5 text-gray-500">
                                                <a className="relative truncate hover:underline">
                                                    {skill.category}
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                    {/* <div className="flex shrink-0 items-center gap-x-4">
                                        <div className="hidden sm:flex sm:flex-col sm:items-end">
                                            <p className="text-sm leading-6 text-gray-900">{skill.value}</p>
                                        </div>
                                    </div> */}
                                </li>
                            ))}
                        </div>

            }

        </div>
    )
}
