import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { changeName, changeProfile } from "../redux/actions/loginActions";
import { useEffect } from "react";
import { useDocumentTitle } from "../services/titleChanger";
export default function Profile() {
    useDocumentTitle('Profile | GoSkilling');
    const userProfile = useSelector((state) => state.authReducer.userProfile);
    const dispatch = useDispatch();
    const { register, handleSubmit, setValue } = useForm();

    const onProfileSubmit = data => {
        const { firstName, lastName, ...restOfData } = data;
        dispatch(changeProfile(restOfData));
    }

    const onNameSubmit = data => {
        dispatch(changeName(data.firstName,data.lastName));
    }

    useEffect(() => {
        if (userProfile) {
          setValue('firstName', userProfile.firstName);
          setValue('lastName', userProfile.lastName);
          setValue('race', userProfile.race);
          setValue('religion', userProfile.religion);
          setValue('gender', userProfile.gender);
          setValue('veteran_status', userProfile.veteran_status);
        }
      }, [userProfile, setValue]);
    return (<div className="relative flex flex-col gap-y-6 p-4 bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
        <form onSubmit={handleSubmit(onNameSubmit)}>
        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3">
                <label htmlFor="firstName" className="block text-sm font-medium leading-6 text-gray-700">
                    First Name
                </label>
                <input
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6"
                    type="text"
                    id="firstName"
                    name="firstName"
                    {...register("firstName")}
                />

            </div>
            <div className="sm:col-span-3">
                <label htmlFor="first_name" className="block text-sm font-medium leading-6 text-gray-700">
                    Last Name
                </label>
                <input
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6"
                    type="text"
                    id="lastName"
                    name="lastName"
                    {...register("lastName")}
                />

            </div>
            </div>
            <button type="submit" className="flex w-full mx-auto mt-4 mb-2 items-center justify-center rounded-md disabled:bg-brand-100 disabled:text-gray-800 bg-secondary-100 border-gray-700  px-3 py-2 text-sm font-semibold text-gray-50 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-secondary-200 hover:text-white focus-visible:outline-offset-0">Save</button>


        </form>
        <form onSubmit={handleSubmit(onProfileSubmit)}>

            <h1 className="text-lg">Demographics</h1>
            <h5 className="text-sm text-gray-600">This is voluntarily. We are using these data to prevent biased assessments impacting your scores. We will never let anybody access your demographic data.</h5>
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-3">
                    <label htmlFor="gender" className="block text-sm font-medium leading-6 text-gray-700">
                        Gender
                    </label>
                    <select
                        id="gender"
                        name="gender"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6"
                        {...register("gender")}
                    >
                        <option value="f">Female</option>
                        <option value="m">Male</option>
                        <option value="d">Other</option>
                        <option value="dnm">Don't disclose</option>
                    </select>
                </div>
                <div className="sm:col-span-3">
                    <label htmlFor="race" className="block text-sm font-medium leading-6 text-gray-700">
                        Ethnicity
                    </label>
                    <select
                        id="race"
                        name="race"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6"
                        {...register("race")}>
                        <option value="ba">Black/African (Caribbean etc.)</option>
                        <option value="as">Asian</option>
                        <option value="pi">Pacific Islander</option>
                        <option value="w">White/Caucasian</option>
                        <option value="l">Latinx</option>
                        <option value="dnm">Don't disclose</option>
                    </select>
                </div>
                <div className="sm:col-span-3">

                    <label htmlFor="religion" className="block text-sm font-medium leading-6 text-gray-700">
                        Religion
                    </label>
                    <select
                        id="religion"
                        name="religion"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6"
                        {...register("religion")}>
                        <option value="c">Christian</option>
                        <option value="i">Muslim</option>
                        <option value="b">Buddhist</option>
                        <option value="h">Hindhu</option>
                        <option value="j">Jewish</option>
                        <option value="a">Athetist</option>
                        <option value="s">Sikh</option>
                        <option value="o">Other</option>
                        <option value="dnm">Don't disclose</option>
                    </select>

                </div>
                <div className="sm:col-span-3">

                    <label htmlFor="disabilities" className="block text-sm font-medium leading-6 text-gray-700">
                        Disabilities
                    </label>
                    <select
                        id="disabilities"
                        name="disabilities"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6"
                        {...register("disabilities")}
                    >
                        <option value="y">I have disabilities</option>
                        <option value="n">No disabilities</option>
                        <option value="dnm">Don't disclose</option>
                    </select>

                </div>
                <div className="sm:col-span-3">

                    <label htmlFor="veteran_status" className="block text-sm font-medium leading-6 text-gray-700">
                        Veteran Status
                    </label>
                    <select
                        id="veteran_status"
                        name="veteran_status"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6"
                        {...register("veteran_status")}>
                        <option value="y">Yes, Protected Veteran</option>
                        <option value="n">Not a veteran</option>
                        <option value="dnm">Don't disclose</option>
                    </select>
                </div>
                <div className="sm:col-span-3">
                    <label htmlFor="sexuality" className="block text-sm font-medium leading-6 text-gray-700">
                        Sexuality
                    </label>
                    <select
                        id="sexuality"
                        name="sexuality"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6"
                        {...register("sexuality")}
                    >
                        <option value="hetero">Heterosexual</option>
                        <option value="homo">Homosexual</option>
                        <option value="hetero">Bisexual</option>
                        <option value="other">Other</option>
                        <option value="dnm">Don't disclose</option>
                    </select>

                </div>
                <div className="sm:col-span-3">
                    <label htmlFor="age" className="block text-sm font-medium leading-6 text-gray-700">
                        Age
                    </label>
                    <input
                        id="age"
                        name="age"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6"
                        type="date"
                        {...register("age")}
                    />

                </div>
            </div>




            <button type="submit" className="flex w-full mx-auto mt-4 mb-2 items-center justify-center rounded-md disabled:bg-brand-100 disabled:text-gray-800 bg-secondary-100 border-gray-700  px-3 py-2 text-sm font-semibold text-gray-50 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-secondary-200 hover:text-white focus-visible:outline-offset-0">Save</button>


        </form>
    </div>
    )

}