import React from "react"
import { ArrowDownIcon, ArrowUpIcon,LightBulbIcon } from '@heroicons/react/20/solid';
import { EmptyStateSimple } from "./emptyState";
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
export function DataCard({value,name,change,changeType,previousStat}) {
    return <div key={name} className="px-4 py-5 sm:p-6">
        <dt className="text-base font-normal  text-gray-900">{name}</dt>
        <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
            <div className="flex items-baseline text-2xl font-semibold text-accent-600">
                {value}
                {/* <span className="ml-2 text-sm font-medium text-gray-500">from {previousStat}</span> */}
            </div>

            {/* <div
                className={classNames(
                    changeType === 'increase' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
                    'inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0'
                )}
            >
                {changeType === 'increase' ? (
                    <ArrowUpIcon
                        className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
                        aria-hidden="true"
                    />
                ) : (
                    <ArrowDownIcon
                        className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
                        aria-hidden="true"
                    />
                )}

                <span className="sr-only"> {changeType === 'increase' ? 'Increased' : 'Decreased'} by </span>
                {change}
            </div> */}
        </dd>
    </div>
}

export function InfoListCard({title,children}) {
    return <div className="bg-white rounded-lg shadow-md p-6 w-full h-full ">
    <h2 className="text-xl font-bold mb-4">{title}</h2>
    <ul className="divide-y divide-gray-200">
        {children}
    </ul>
  </div>
}