import { useSelector } from "react-redux";
import { Routes, Route, useLocation, Navigate } from "react-router-dom"

//COMPONENTS
import Dashboard from "../Dashboard";
import Login from "../Login";
import Register from "../Register";
import TermsOfService from "../TermsOfSerivce";
import PrivacyPolicy from "../PrivacyPolicy";
import VerifyEmail from "../VerifyEmail";
import VerifyUser from "../VerifyUser";
import ConfirmAccount from "../ConfirmAccount";
import ConfirmEmail from "../ConfirmEmail";
import Contact from "../Contact";
import Logout from "../Logout";
import NotFound from "../404";
import CentralUnit from "../CentralUnit";
import ScoreCard from "../Scorecard";
import RestrictedLayout from "../layouts/RestrictedLayout";
import PublicLayout from "../layouts/PublicLayout";
import VerifyReview from "../VerifyReview";
import PublicScorecard from "../PublicScorecard";
import HomepageV2 from "../Homepage_v2";
import PortfolioForm from "../user_portfolios/portfolioEditor";
import PortfolioArticle from "../user_portfolios/portfolioArticle";
import { Jobs, JobView } from "../Jobs";
import { AccurateInsights, Diversity, PeerAssessments, About } from "../InformationPages";
import { AiInterface } from "../experiments/ai_review/aiTest";
import Chat from "../experiments/chat/Chat";
import PortfolioUserList from "../user_portfolios/portfolioUserList";
const Routing = () => {
    const isAuthenticated = useSelector((state) => state.authReducer.isAuthenticated);
    const user_url = useSelector((state) => state.authReducer.userProfile?.urlname);


    return <Routes>
        <Route path="/" index element={!isAuthenticated ? <PublicLayout><HomepageV2 /></PublicLayout> :  <Navigate to={user_url ? "/user/" + user_url : "/"} />} />
        <Route path="login" element={isAuthenticated ? <Navigate to={user_url ? "/user/" + user_url : "/"} /> : <Login />} />
        <Route path="register" element={isAuthenticated ? <Navigate to={user_url ? "/user/" + user_url : "/"} /> : <Register />} />
        <Route path="information">
            <Route path="accurateinsights" element={<PublicLayout><AccurateInsights /></PublicLayout>} />
            <Route path="peerassessments" element={<PublicLayout><PeerAssessments /></PublicLayout>} />
            <Route path="diversity" element={<PublicLayout><Diversity /></PublicLayout>} />
        </Route>
        <Route path="terms" element={<PublicLayout><TermsOfService /></PublicLayout>} />
        <Route path="privacy" element={<PublicLayout><PrivacyPolicy /></PublicLayout>} />
        <Route path="verifyuser" element={<PublicLayout><VerifyUser /></PublicLayout>} />
        <Route path="verifyemail" element={<PublicLayout><VerifyEmail /></PublicLayout>} />
        <Route path="verifyreview" element={<PublicLayout><VerifyReview /></PublicLayout>} />
        <Route path="confirmaccount" element={<PublicLayout><ConfirmAccount /></PublicLayout>} />
        <Route path="confirmemail" element={<PublicLayout><ConfirmEmail /></PublicLayout>} />
        <Route path="contact" element={<PublicLayout><Contact /></PublicLayout>} />
        <Route path="about" element={<PublicLayout><About /></PublicLayout>} />
        {/* <Route path="ai">
            <Route path=":username" element={<AiInterface/>} />
        </Route>
        <Route path="chat" element={<Chat/>} /> */}
        <Route path="jobs">
            <Route index element={<PublicLayout><Jobs/></PublicLayout>} />
            <Route path=":jobid" element={<PublicLayout><JobView /></PublicLayout>} />
        </Route>
        <Route path="portfolio">
            <Route path="editor/:articleid" element={<PublicLayout><PortfolioForm/></PublicLayout>} />
            <Route path="editor" element={<PublicLayout><PortfolioForm/></PublicLayout>} />
            <Route path="user/:username" element={<PublicLayout><PortfolioUserList/></PublicLayout>} />            
            <Route path="read/:articleid" element={<PublicLayout><PortfolioArticle/></PublicLayout>} />
        </Route>
        <Route path="userview">
            <Route path=":username" element={<PublicLayout><ScoreCard /></PublicLayout>} />
        </Route>
        <Route path="user">
            <Route path=":username" element={<PublicLayout><CentralUnit /></PublicLayout>} />
        </Route>
        <Route path="scorecard">
            <Route path=":username" element={<PublicLayout><PublicScorecard /></PublicLayout>} />
        </Route>
        <Route path="logout" element={isAuthenticated ? <RestrictedLayout><Logout /></RestrictedLayout> : <Navigate to="/" />} />
        <Route path="*" element={<PublicLayout><NotFound /></PublicLayout>} />
    </Routes>
}
export { Routing }