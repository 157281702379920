import React, { createContext, useContext, useState, useEffect } from "react";
import QRCode from "react-qr-code";
import { Link, useParams } from "react-router-dom";
import { useSelector } from 'react-redux';
import { UserXContext } from "../contexts/userContext";
import UserSkillSummary from "./userview_profile/UserSkillSummary";
import ExperienceList from "./userview_profile/UserWorkEmails";
import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";
import { useFetchUserData } from "../redux/actions/loginActions";
import SoftSkillsSelector from "./userview_scorecard/SoftSkillSelector";
import SkillAssessment from "./userview_scorecard/SkillAssessment";
import SoftSkillsProgressDisplay from "./userview_profile/SoftSkillsProgressDisplay";
import SoftSkillsProgressDisplayB from "./userview_profile/SoftSkillsProgressDisplayB";
import ConnectionsList from "./userview_profile/ConnectionsList";
import UserDemographics from "./userview_profile/UserDemographics";
import UserSettings from "./userview_profile/UserSettings";
import ScoreCard from "./Scorecard";
import './../App.css';
import { Helmet } from "react-helmet-async";
import SEO from "../utilities/SEO";
import ABTestingComponent from "../services/ABTesting";
import ProfileJobs from "./userview_profile/UserJobsList";
const UserContext = createContext();

export default function CentralUnit() {
    const [viewMode, setViewMode] = useState("owner");
    const [user, setUser] = useState();
    const [auth, setAuth] = useState({
        firstName: "Reid", lastName: "Hoffman", jobTitle: "General Partner", company: "Greylock Partners", userID: 25, userSkills: [
            // Initially filled with skills and scores
            { id: 1, name: 'Active Learning', score: 78 },
            { id: 2, name: 'Communication', score: 85 },
            { id: 3, name: 'Teamwork', score: 90 },
            { id: 4, name: 'Problem Solving', score: 80 },
            { id: 5, name: 'Creativity', score: 88 },
            { id: 6, name: 'Work Ethic', score: 92 },
            { id: 7, name: 'Interpersonal Skills', score: 75 },
            { id: 8, name: 'Time Management', score: 82 },
            { id: 9, name: 'Adaptability', score: 86 },
            { id: 10, name: 'Leadership', score: 79 },
        ], softSkills: [
            { name: 'Active Learning', count: 0 }, // Update count as necessary
            { name: 'Communication', count: 5 }, // Update count as necessary
            { name: 'Teamwork', count: 7 }, // Update count as necessary

        ]
    });
    const [availableSkills, setAvailableSkills] = useState([
        // Skills that can be added

        { id: 11, name: 'Project Management' },
        { id: 12, name: 'Analytical Thinking' },
        { id: 13, name: 'Data Analysis' },
        { id: 14, name: 'Technical Writing' },
        { id: 15, name: 'Public Speaking' },
        { id: 16, name: 'Digital Marketing' },
        { id: 17, name: 'UX Design' },
        { id: 18, name: 'Foreign Languages' },
        { id: 19, name: 'SEO/SEM' },
        { id: 20, name: 'Database Management' },

    ]);
    const [profileView, setProfileView] = useState("profile");
    const isAuthenticated = useSelector((state) => state.authReducer.isAuthenticated);
    //const tasks = useContext(TasksContext);
    return <UserContext.Provider value={{ profile: [user, setUser], profileControl: [profileView, setProfileView], activeUser: [auth, setAuth], availableSiteSkills: [availableSkills, setAvailableSkills], viewingMode: [viewMode, setViewMode] }}>
        <div className="min-h-screen bg-gray-100">
            <main className="mx-auto max-w-3xl">
                <AllUnits />
            </main>
        </div>
    </UserContext.Provider>
}

const AllUnits = () => {
    const { viewingMode } = useContext(UserContext);
    const [viewMode, setViewMode] = viewingMode;
    const { username: routeUsername } = useParams(); // Using destructuring to get username from the URL parameters
    const user_url = useSelector((state) => state.authReducer.userProfile?.urlname); // Safe access using optional chaining
    const verificationStatus = useSelector((state) => state.authReducer.userProfile?.verified)
    //console.log(user_url)
    // Use useEffect to update the viewMode based on the comparison
    useEffect(() => {
        // If the user_url is defined and equal to the username from the URL parameters, set the ViewMode to "Visitor"
        if (user_url && user_url !== routeUsername || !user_url) {
            setViewMode("visitor");
        } else {
            // Otherwise, set the viewMode to "owner" or any other default mode as per your application's logic
            setViewMode("owner");
        }
    }, [user_url, routeUsername, setViewMode]); // Add setViewMode to the dependency array if it's stable, remove if it's not

    //console.log(routeUsername);

    return (
        <div>
            {/* {viewMode}
        {user_url}
        {routeUsername} */}
            {viewMode === "owner"
                ?
                <div>
                    {
                        !verificationStatus
                            ? <div className="bg-yellow-500 text-white w-full min-h-96 rounded py-6 px-4">
                                <h1 className="font-bold text-lg">Please verify your email!</h1>
                                <p>Your account will be deleted without proper verification. Just use the code in your email.</p>
                                <p>No email in your inbox? Please check your spam folder as well.</p>
                                <p>Already verified? Reload page or <button type="button" className="underline " onClick={() => window.location.reload()}>click here.</button></p>
                            </div>
                            : ""
                    }

                    <PrivateProfile />
                </div>

                :
                <div>
                    <ScoreCard />
                </div>
            }
        </div>
    );
};

const PrivateProfile = () => {
    const { profile, activeUser, availableSiteSkills, profileControl } = useContext(UserContext);
    const [user, setUser] = profile;
    const [auth, setAuth] = activeUser;
    const [availableSkills, setAvailableSkills] = availableSiteSkills;
    const [profileView, setProfileView] = profileControl;
    // Show tabs if on the user's own profile page
    const showTabs = auth.userID == auth.userID; // Set this based on your application's logic

    const isConnection = auth.connection;

    const onImageCownload = () => {
        const svg = document.getElementById("QRCode");
        const svgData = new XMLSerializer().serializeToString(svg);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = new Image();
        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            const pngFile = canvas.toDataURL("image/png");
            const downloadLink = document.createElement("a");
            downloadLink.download = "QRCode";
            downloadLink.href = `${pngFile}`;
            downloadLink.click();
        };
        img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
    };
    const userdata = useSelector((state) => state.authReducer.userProfile);
    if (!userdata) {
        return "Profile loading";
    }
    var experiencesCount = Object.keys(userdata["experiences"]).length / 2;
    var userSkillsCount = Object.keys(userdata["userSkills"]).length / 5;
    var workEmailsCount = Object.keys(userdata["workEmails"]).length / 1;
    var connectionsCount = Object.keys(userdata["connections"]).length / 3;
    var profileCompletion = (experiencesCount + userSkillsCount + workEmailsCount + connectionsCount) / 4;
    var userID = userdata.id;


    return (
        <div className="mb-3">
            <SEO title={`Your profile | GoSkilling`} description="" name="" type="" />
            <ProfileHeader user={userdata} completeness={profileCompletion} showTabs={showTabs} isConnection={isConnection} />
            {/* The rest of the profile page content */}
            {
                profileView === "profile"
                    ? <div>
                        {
                            profileCompletion < 1
                                ? <>
                                    Profile Completion
                                    <ProgressBarComponent progress={profileCompletion * 100} />
                                </>
                                : ""
                        }
                        <Structure>
                            <UserSkillSummary />
                        </Structure>
                        <Structure>
                            <ABTestingComponent ComponentA={SoftSkillsProgressDisplayB} ComponentB={SoftSkillsProgressDisplay} />
                        </Structure>
                        <Structure>
                            <ExperienceList />
                        </Structure>
                        <Structure>
                            <ProfileJobs />
                        </Structure>
                        <Structure>
                            <ConnectionsList />
                        </Structure>
                    </div>
                    : profileView === "demographics"
                        ?
                        <div>
                            <Structure>
                                <UserDemographics />
                            </Structure>
                        </div>
                        : <div>
                            <Structure>
                                <UserSettings />
                            </Structure></div>

            }
            <Structure>


            </Structure>
        </div>
    );
};


export const ProfileHeader = ({ user, completeness, showTabs, isConnection }) => {
    const { profile, activeUser, availableSiteSkills, profileControl } = useContext(UserContext);
    //const [user, setUser] = profile;
    const [auth, setAuth] = activeUser;
    const [availableSkills, setAvailableSkills] = availableSiteSkills;
    const [profileView, setProfileView] = profileControl;
    const [isCollapsed, setIsCollapsed] = useState(true); // State to control the collapse
    const [selectedTab, setSelectedTab] = useState('qr');
    const user_url = useSelector((state) => state.authReducer.userProfile?.urlname); // Safe access using optional chaining

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    const onImageCownload = () => {
        const svg = document.getElementById("QRCode");
        const svgData = new XMLSerializer().serializeToString(svg);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = new Image();
        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            const pngFile = canvas.toDataURL("image/png");
            const downloadLink = document.createElement("a");
            downloadLink.download = "QRCode";
            downloadLink.href = `${pngFile}`;
            downloadLink.click();
        };
        img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
    };
    const callsToAction = [
        { name: 'QR-Code', tab: 'qr', },
        { name: 'URL', tab: 'url', },
        { name: 'Scoreboard', tab: 'board' }
    ]
    return (
        <div className="bg-white  shadow-sm ">
            <div className="max-w-4xl mx-auto">
                <div className="flex flex-col md:flex-row md:items-center justify-between md:p-6 pt-6 pb-2 px-2">
                    <div>
                        <h1 className="text-2xl font-semibold text-gray-800">{user.firstName + " " + user.lastName}</h1>
                        <p className="text-md text-gray-500">{user.role} at {user.company}</p>
                        {isConnection !== undefined && (
                            <span className={`text-sm font-semibold ${isConnection ? 'text-green-500' : 'text-gray-400'}`}>
                                {isConnection ? 'Connected' : 'Not Connected'}
                            </span>
                        )}
                    </div>
                    {showTabs && (
                        <div className="flex mt-4 md:mt-0 space-x-4">
                            <button type="button" onClick={() => setProfileView("profile")} className="text-gray-800 hover:text-gray-600"> Profile {completeness < 1 ? completeness : ""}
                                {user.points
                                    ? <span className="has-tooltip inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
                                        <span class='tooltip rounded shadow-lg p-1 bg-gray-100 text-secondary-500 -mt-8'>Points for your activities</span>
                                        {user.points}  </span>
                                    : ""} </button>
                            <Link to={"/portfolio/user/"+user.urlname} className="text-md font-semibold text-blue-500  hover:text-gray-800">Portfolio</Link>

                            <button type="button" onClick={() => setProfileView("demographics")} className="text-gray-800 hover:text-gray-600">Demographics</button>
                            <button type="button" onClick={() => setProfileView("settings")} className="text-gray-800 hover:text-gray-600">Settings</button>
                        </div>
                    )}
                </div>
                <div className="mt-2">
                    <button type="button" onClick={toggleCollapse} className="text-gray-800 border py-1 px-2 w-full hover:text-gray-600 justify-center">
                        {isCollapsed ? 'Show Shareables' : 'Show Less'}
                    </button>
                    {!isCollapsed && (
                        <div className="mt-0">
                            {/* Replace this div with your custom component */}
                            {/* <YourCustomComponent /> */}
                            <div className="grid grid-cols-3 divide-x divide-gray-300 bg-gray-50">
                                {callsToAction.map((item) => (
                                    <button
                                        key={item.name}
                                        type="button"
                                        className="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-brand-100"
                                        onClick={() => setSelectedTab(item.tab)}
                                    >
                                        {item.name}
                                    </button>
                                ))}
                            </div>
                            {
                                selectedTab === 'qr'
                                    ? <Structure>
                                        <div class="flex flex-wrap px-2">
                                            <div className="w-full sm:w-1/2 mb-4 mx-auto flex flex-col items-center">
                                                <div>
                                                    <QRCode id="QRCode"
                                                        bgColor={'#6ec1f2'}
                                                        fgColor={'#044389'}
                                                        title="Your QR code"
                                                        value={`http://goskilling.com/user/${user.urlname}`} />
                                                </div>
                                                <button type="button" className="w-full bg-brand-500 text-white py-2 px-1 mt-2" onClick={() => onImageCownload()}>Download & share</button>

                                            </div>
                                            <div class="w-full sm:w-1/2 mb-4 ">
                                                <p className="text-lg leading-8 text-gray-800">
                                                    Share this QR code to get assessments from your peers. QR codes are ideal for business cards, but also to share on LinkedIn and other networks where posts with outbound links underperform.
                                                </p>
                                            </div>
                                        </div>
                                    </Structure>
                                    : selectedTab === 'url'
                                        ? <Structure>
                                            <div class="flex flex-wrap px-2">
                                                <div className="w-full sm:w-1/2 mb-4 mx-auto flex flex-col items-center">
                                                    <UrlCopyInput defaultValue={`http://goskilling.com/user/${user.urlname}`} />
                                                </div>
                                                <div class="w-full sm:w-1/2 mb-4 ">
                                                    <p className="text-lg leading-8 text-gray-800">
                                                        An easily shareable link to your profile. Peer, registered and unregistered, can assess your hard and soft skills. An ideal solution for your email signature, Slack, and other tools where you communicate with peers.
                                                    </p>
                                                </div>
                                            </div>
                                        </Structure>
                                        : <Structure>
                                            <div class="flex flex-wrap px-2">
                                                <div className="w-full sm:w-1/2 mb-4 mx-auto flex flex-col items-center">
                                                    <UrlCopyInput defaultValue={`http://goskilling.com/scorecard/${user.urlname}`} />
                                                </div>
                                                <div class="w-full sm:w-1/2 mb-4 ">
                                                    <p className="text-lg leading-8 text-gray-800">
                                                        Share your personal scoreboard with a recruiter or your manager to showcase your skill scores.
                                                    </p>
                                                </div>
                                            </div>
                                        </Structure>
                            }


                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};



const UrlCopyInput = ({ defaultValue }) => {
    const [url] = useState(defaultValue);
    const [copySuccess, setCopySuccess] = useState('');

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(url)
            .then(() => {
                setCopySuccess("Copied!");
                setTimeout(() => setCopySuccess(''), 2000); // Hide confirmation after 2 seconds
            })
            .catch(err => {
                setCopySuccess("Failed to copy");
                //console.error('Could not copy text: ', err);
            });
    };

    return (
        <div className="mt-2 flex rounded-md shadow-sm">
            <div className="relative flex flex-grow items-stretch focus-within:z-10">

                <input
                    type="text"
                    value={url}
                    readOnly
                    disabled
                    className="block w-full rounded-none rounded-l-md border-0 py-1.5  text-gray-500 bg-gray-100 cursor-not-allowed focus:ring-2 focus:ring-indigo-600 sm:text-sm"
                    placeholder="Your URL here"
                />
            </div>
            <button
                onClick={handleCopyToClipboard}
                className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 bg-white ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
                {/* Replace with <BarsArrowUpIcon /> or similar icon */}
                <span className="-ml-0.5 h-5 w-5 text-gray-400"><ClipboardDocumentIcon /></span>
                Copy
            </button>
            {copySuccess && (
                <span className="text-sm text-green-500 pl-4">{copySuccess}</span>
            )}
        </div>
    );
};


export const WorkHistoryConfirmation = ({ onHistoryConfirmed }) => {
    const [hasWorkedTogether, setHasWorkedTogether] = useState(null);
    const [workEmail, setWorkEmail] = useState('');
    const [companyUrl, setCompanyUrl] = useState('');
    const [score, setScore] = useState(0);

    const confirmWorkHistory = () => {
        let newScore = score;
        if (workEmail) newScore += 10; // 10 points for providing work email
        if (companyUrl) newScore += 10; // 10 points for providing company URL

        setScore(newScore);
        onHistoryConfirmed({ workEmail, companyUrl });
    };

    return (
        <div className="  ">
            <div className=" mx-auto bg-white rounded-lg shadow p-6">
                <h2 className="text-xl font-light text-gray-800">Work or Study History</h2>
                <p className="mt-4 text-gray-600">
                    Have you worked or studied with this individual?
                </p>

                {/* Yes/No Buttons */}
                <div className="mt-4">
                    <button
                        className={`px-4 py-2 rounded-full border ${hasWorkedTogether === true ? 'bg-green-100 text-green-800' : 'bg-white border-gray-200 text-gray-800'
                            } hover:bg-green-50 focus:outline-none focus:ring-2 focus:ring-green-200 mr-2`}
                        onClick={() => setHasWorkedTogether(true)}
                    >
                        Yes
                    </button>
                    <button
                        className={`px-4 py-2 rounded-full border ${hasWorkedTogether === false ? 'bg-red-100 text-red-800' : 'bg-white border-gray-200 text-gray-800'
                            } hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-200`}
                        onClick={() => setHasWorkedTogether(false)}
                    >
                        No
                    </button>
                </div>

                {/* Work Email Input */}
                {hasWorkedTogether && (
                    <div className="mt-4">
                        <input
                            className="px-4 py-2 border rounded w-full"
                            type="email"
                            placeholder="Work Email Address"
                            value={workEmail}
                            onChange={(e) => setWorkEmail(e.target.value)}
                        />
                    </div>
                )}

                {/* Company URL Input */}
                {hasWorkedTogether && (
                    <div className="mt-4">
                        <input
                            className="px-4 py-2 border rounded w-full"
                            type="url"
                            placeholder="Company URL"
                            value={companyUrl}
                            onChange={(e) => setCompanyUrl(e.target.value)}
                        />
                    </div>
                )}

                {/* Confirmation Button */}
                {hasWorkedTogether && (
                    <div className="mt-4 flex justify-end">
                        <button
                            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                            onClick={confirmWorkHistory}
                        >
                            Confirm
                        </button>
                    </div>
                )}

                {/* Score Indicator */}
                <div className="mt-4">
                    <p className="text-sm font-semibold text-gray-600">
                        Your score for providing information: <span className="text-green-500">{score}</span>
                    </p>
                </div>
            </div>
        </div>
    );
};


// COMPONENTS
export const Structure = ({ children }) => {
    return (
        <div className="py-4">
            {children}
        </div>
    );
};

// ProgressBarComponent.jsx
const ProgressBarComponent = ({ progress }) => {
    return (
        <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700">
            <div className={`bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full`} style={{ width: `${progress}%` }}> {progress}% </div>
        </div>
    );
};
