const initialState = {
    skills: null,
    error: null,
};

export default function skillReducer(state = initialState, action) {
    switch (action.type) {
        case 'SKILL_FETCH_SUCCESS':
            return {
                ...state,
                skills: action.payload.data,
            };
        case 'SKILL_FETCH_ERROR':
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}
