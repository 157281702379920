import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { confirmNewEmail } from "../services/apiCalls";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const emailDefaultValues = {
    code: "",
    email: ""
}

export default function ConfirmEmail() {
    const { register, handleSubmit, setValue,reset, formState: { errors, isValid } } = useForm({defaultValues:emailDefaultValues});


    
    const codeSubmitter = async (data) => {
        try {
            const response = await confirmNewEmail(data);
            
            if (response.status === "success") {
              console.log("Email updated successfully!");
              toast[response.status](response.message, { theme: "colored" });
              reset(emailDefaultValues);
              // You can add additional logic here
            } else if (response.status === "error") {
              console.log("Unable to update email.");
              toast[response.status](response.message, { theme: "colored" });
              // Handle 404 error
            } else {
              console.log("Something went wrong");
              toast.error("Something went wrong", { theme: "colored" });

              // Handle other types of negative responses
            }
        
          } catch (error) {
            console.error("An error occurred:", error);
            // Handle unexpected errors here
          }
        };
    
    return <div className="relative flex flex-col gap-y-6 p-4 bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
        <form onSubmit={handleSubmit(codeSubmitter)}>
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm lg:col-span-7 lg:mt-0">
                <h1 as="h3" className="text-base font-semibold leading-6 text-gray-900">
                    New Main Email Verification
                </h1>
                <p className="text-sm text-gray-500">
                    Please enter your verification code and email to update your main email.
                </p>

                <div className="sm:col-span-4">
                    <label htmlFor={"code"} className="block text-sm font-medium leading-6 text-gray-700 mb-2">
                        Code
                    </label>
                    <input
                        type="text"
                        id="code"
                        name="code"
                        className="w-full"
                        {...register("code", {
                            required: "This is required.",

                            minLength: {
                                value: 12,
                                message: "Min length is 12"
                            }
                        })}
                    />
                </div>
                <div className="sm:col-span-4">
                    <label htmlFor={"email"} className="block text-sm font-medium leading-6 text-gray-700 mb-2">
                        Email
                    </label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        className="w-full"
                        {...register("email", {
                            required: "This is required",
                            pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        })}
                    />
                </div>
                <div className="sm:col-span-3">
                    <button type="submit" disabled={!isValid} className="flex w-full mx-auto mt-4 mb-2 items-center justify-center rounded-md disabled:bg-brand-100 disabled:text-gray-800 bg-secondary-100 border-gray-700  px-3 py-2 text-sm font-semibold text-gray-50 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-secondary-200 hover:text-white focus-visible:outline-offset-0">Submit</button>
                </div>
            </div>
        </form>
    </div>
}