import { useContext, useState } from "react";
import { UserXContext } from "../../contexts/userContext";
import { RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar, Tooltip, ResponsiveContainer } from 'recharts';
import { useSelector } from "react-redux";

const chartStyle = {
    // Base style (for desktop)
    width: '100%',
    height: 250,
};

const mobileStyle = {
    // Adjusted style for mobile
    '@media (max-width: 640px)': {
        height: '100%', // Adjust this as needed
    },
};

export default function SoftSkillsProgressDisplay() {
    // Normalize the skills data for the radar chart
    //var userdata = useContext(UserXContext);
    const userdata = useSelector((state) => state.authReducer.userProfile);

    const radarData = userdata.userSoftSkills.map(skill => ({
        subject: skill.name,
        A: skill.count,
        fullMark: 10, // Assuming 10 is the full mark for each skill
    }));

    return (
        <div className="mx-auto bg-white rounded-lg shadow-sm px-6 pt-6 pb-12">
            <h2 className="text-xl font-light text-gray-800 mb-4">Soft Skills Proficiency</h2>
            <div style={chartStyle}>
                <ResponsiveContainer style={mobileStyle}>
                    <RadarChart outerRadius="70%" data={radarData}>
                        <PolarGrid />
                        <PolarAngleAxis dataKey="subject" />
                        <PolarRadiusAxis angle={30} domain={[0, 10]} />
                        <Radar name="Skills" dataKey="A" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
                        <Tooltip />
                    </RadarChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};
